import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import * as auth0 from 'auth0-js';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { switchMap, filter, catchError, debounceTime, tap, finalize, takeUntil, take,  map, shareReplay } from 'rxjs/operators';
import * as jwt_decode from "jwt-decode";
import { BaseUrl } from '../return-base-url..service';
import { myConfig } from './auth.conf';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs/observable/of';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { timer } from 'rxjs';

// import { UserSessionService } from '@app/services/user-session.service';

export class UserIdentification {
    user_id: string;
    name: string;
    picture: string;
    email: string;
    email_verified: boolean;
    org: string;
    roles: string[];
    exp: number; //unix timestap
    expirationDate: Date;
}


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private impersonateUser: string = null;
    // private accessToken: string = null;

    private isRefreshingToken = false; //status
    private refreshTokenSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

    //inform Services of a new Access token
    // public accessTokenAsObservable: Observable<any>;
    // private _accessToken = new BehaviorSubject<any>(null);

    private accessTokenExpiresAt!: number; // Expiry time in epoch seconds
    private expiryTimeout: any; // Reference to the timeout to clear if needed
    //idToken

    public userIdentificationAsObservable: Observable<any>; //updates when userIdentification changes. Components can subscribe to this to get the latest userIdentification
    private userIdentification = new BehaviorSubject<UserIdentification>(null);

    auth0 = new auth0.WebAuth({
        clientID: myConfig.clientID,
        domain: myConfig.domain,
        responseType: 'token id_token',
        audience: myConfig.audience,
        redirectUri: window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/callback',
        leeway: 30,
        scope: myConfig.scope,
        title: 'Voxspan - PiPcall',
        theme: {
            logo: '/assets/cyan_logo64.png',
            primaryColor: '#b81b1c'
        }
    });

    constructor(
        private router: Router,
        private baseUrl: BaseUrl,
        private cookieService: CookieService,
        private http: HttpClient
    ) {
        // this._accessToken.next(null);
        // this.accessTokenAsObservable = this._accessToken.asObservable().filter(resp => !!resp);
        this.userIdentification.next(null);
        this.userIdentificationAsObservable = this.userIdentification.asObservable().filter(resp => !!resp);
        this.startAuthSession();
    }

    //legacy login
    // public login(): void {
    //     this.auth0.authorize();
    // }

    get expiresAt() { return this.cookieService.check('expires_at') ? this.cookieService.get('expires_at') : null; }
    get accessToken() { return this.cookieService.check('access_token') ? this.cookieService.get('access_token') : null;  }
    get refreshToken() { return this.cookieService.check('refresh_token') ? this.cookieService.get('refresh_token') : null;  }
    get idToken() { return this.cookieService.check('id_token') ? this.cookieService.get('id_token') : null;  }
    get impersonateUserId() { return sessionStorage.getItem('impersonateUser') ? sessionStorage.getItem('impersonateUser') : null; }


    private startAuthSession() {
        console.log('***AUTH**** session started');
        this.setIdentificationToken();

        if (this.validAccessTokenExists()) {
            this.schedulePreExpiryTask();
            //CHECK IF EXPIRING IN NEXT 15 MINS AND REFRESH IT IF SO.
        } else if (this.validRefreshTokenExists()) {
            this.executeRefreshToken()
                .subscribe(
                    (result) => {
                        console.log('***AUTH**** successfully refreshed token');
                        //consider reloading window (or redirect to current page)
                    },
                    (error) => {
                        console.log('***AUTH**** failed refresh. clearSession()');
                        this.clearSession();
                    }
                )
        } else {
            console.log('***AUTH**** no valid refresh token. clearing session.');
            this.clearSession();
        }
        //check if valid refresh token in session.
        //if true - refresh  the access tokens.

        //Set access token in memory, store refresh and Id token in cookies. Make sure User-id session makes an update to its check. ensure it does not prematurely redirect.
        //if false - (then is there already a valid acess token that can continue for this session) ->
        //else redirect to login page - clear all cookies for access/refresh/id tokens
    }

    // Schedule a task 10 minutes before expiry
    private schedulePreExpiryTask(): void {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in epoch seconds
        const tenMinutesBeforeExpiry = this.accessTokenExpiresAt - 600; // Subtract 10 minutes (600 seconds)
        const delay = (tenMinutesBeforeExpiry - currentTime) * 1000; // Convert to milliseconds

        if (delay > 0) {
            console.log(`Task scheduled to run in ${delay / 1000} seconds`);
            this.expiryTimeout = setTimeout(() => {
                this.runPreExpiryTask();
            }, delay);
        } else {
            console.warn('Cannot schedule task: Expiry time is too soon or already passed!');
        }
    }

    // The task to run 10 minutes before expiry
    private runPreExpiryTask(): void {
        console.log('Running task 10 minutes before token expiry!');
        this.executeRefreshToken()
            .subscribe(
                (result) => {
                    console.log('Token refreshed successfully!');
                },
                (error) => {
                    console.error('Error refreshing token:', error);
                }
            );
    }

    // Clear timeout if necessary (e.g., on new login or logout)
    public clearScheduledTask(): void {
        if (this.expiryTimeout) {
            clearTimeout(this.expiryTimeout);
            console.log('Scheduled task cleared.');
        }
    }


    private setIdentificationToken(id_token?: string) {
        id_token = id_token || this.idToken;
        if (!id_token) {return null; }

        const decoded = this.getDecodedToken(id_token);

        if (decoded) {
            const _user = {
                user_id: decoded?.sub,
                name: decoded?.name,
                picture: decoded?.picture,
                email: decoded?.email,
                email_verified: decoded?.email_is_verified,
                org: decoded['https://pipcall.com/org'],
                roles: decoded['https://pipcall.com/roles'],
                exp: decoded?.exp,
                expirationDate: new Date(decoded?.exp * 1000)
            }
            this.userIdentification.next(_user);
        }
    }

    public setSession(access_token: string, refresh_token: string, id_token: string, expires_in?: number): Observable<boolean> {
        console.log('[**Debug**] setSession()');
        //expires_in is in seconds
        this.clearScheduledTask();
        const decodedACToken = this.returnTokenPayload(access_token);
        const expiresAt = decodedACToken.exp; //epoch time in seconds //EXPIRY DATE SHOULD INSTEAD BE SET WHEN YOU GET THE TOKEN!

        //set date to expire access_token cookie in correct format
        const accessTokenExpiresAt = new Date(0);
        accessTokenExpiresAt.setUTCSeconds(expiresAt);
        console.log('access token expires at (seconds):', accessTokenExpiresAt);

        //session
        this.accessTokenExpiresAt = expiresAt; //epoch time in seconds

        //set ID token
        this.cookieService.set('access_token', access_token, accessTokenExpiresAt, '/'); //human readable date
        this.cookieService.set('expires_at', expiresAt, accessTokenExpiresAt, '/'); //move to session ?
        this.cookieService.set('refresh_token', refresh_token, 30, '/'); //30 days
        this.cookieService.set('id_token', id_token, 30, '/');

        this.setIdentificationToken(id_token);
        this.schedulePreExpiryTask();
        return of(true);
    }

    //rewite ========================================================================================

    // public isValidRolePresent(): boolean {
    //     //maybe not needed.
    //     return false;
    // }

    public is_customer_admin(): boolean {
        //changeName to isCustomerAdmin
        // const id_token = this.cookieService.get('id_token');
        const decodedIdtoken = this.getDecodedToken(this.idToken);
        return  decodedIdtoken ? decodedIdtoken['https://pipcall.com/roles'].includes('customer_admin') ? true : false : false;
    }

    public is_system_admin(): boolean {
        //change to isSystemAdmin
        // const id_token = this.cookieService.get('id_token');
        const decodedIdtoken = this.getDecodedToken(this.idToken);
        return  decodedIdtoken ? decodedIdtoken['https://pipcall.com/roles'].includes('system_admin') ? true : false : false;
    }

    public validAccessTokenExists(): boolean {
        this.accessTokenExpiresAt = parseInt(this.expiresAt);
        const now = Math.floor(new Date().getTime() / 1000); // current time in seconds
        return (this.accessToken && this.accessTokenExpiresAt > now && this.isAudienceValid() ) ? true : false;
    }

    public validRefreshTokenExists(): boolean {
        return this.refreshToken ? true : false;
    }

    public validIdTokenExists(): boolean {
        //certificate check / audience check.
        return this.idToken ? true : false;
    }

    public isSessionValid(): boolean {
        //Either accesstoken + id_token or refresh token.
        const accessToken = this.validAccessTokenExists();
        const refreshToken = this.validRefreshTokenExists();
        return (accessToken && this.idToken || refreshToken) ? true : false;
    }

    //create  observable for authguard?
    public returnAccessToken(): Observable<string | null> {
        return of(this.accessToken); // Wrap the token in an observable
    }

    public returnIdToken(): Observable<string | null> {
        const token = this.idToken; // Retrieve the token
        return of(token); // Wrap the token in an observable
    }

    public getUserId(): string {
        this.impersonateUser = sessionStorage.getItem('impersonateUser') ? sessionStorage.getItem('impersonateUser') : null;
        console.log('***auth*** getUserId : impersonateUser', this.impersonateUser);
        if (this.impersonateUser && this.is_system_admin()) {
            console.log('***auth*** getUserId : return impersonateUser', this.impersonateUser);
            return this.impersonateUser;
        } else if (this.userIdentification.value) {
            console.log('***auth*** getUserId : return actual userid', this.userIdentification.value.user_id);
            return this.userIdentification.value.user_id;
        } else {
            console.log('***auth*** getUserId : return null');
            return null;
        }

    }

    // public returnUserId(): string {
    //     return ''
    // }

    //consider impersonate
    // public returnUsersOrgId(): string {
    //     return
    // }

    public refreshAccesTokenNow(): Observable<boolean> {
        return this.executeRefreshToken();
    }
    //================================================================================================

    //make this private
    public isAuthenticated(): boolean {
        const expiresAt = this.returnAccessTokenValue("exp"); //expiry date in token in seconds
        const now = (new Date().getTime() / 1000); //current datetime in seconds
        const audienceValid = this.isAudienceValid(); //is audience valid for this environment
        return (expiresAt && audienceValid === true) ? now < expiresAt : false;

    }

    private isAudienceValid(): boolean {
        const audience = this.returnAccessTokenValue("aud");
        return audience?.indexOf(environment.auth0_audience) > -1 ? true : false;
    }

    // private refreshIdTokenNow(): void {

    // }

    //================================================================================================


    public logout(): void {
        console.log('***AUTH*** logout()');
        // Remove tokens and expiry time
        this.clearSession(this.router.navigateByUrl('/'));

        // setTimeout(() => {
        //     window.location.reload();
        // }, 200);
    }

    public clearCookieData(cb?: any) {
        return this.clearSession(cb); //probably can remove
    }



    private returnAccessTokenValue(field?: string) {
        if (!field) {return null; }
        //return any parameter from accessToken
        if (this.accessToken) {
            const decodedToken = this.getDecodedToken(this.accessToken);
            const value = decodedToken[field];
            return value;
        } else {
            //no access token, return false.
            return null;
        }
    }


    public isSysAdmin(): boolean {
        const id_token = this.cookieService.get('id_token');
        const decodedToken = this.getDecodedToken(id_token);
        return  decodedToken['https://pipcall.com/roles'].includes('system_admin') ? true : false;
    }

    private getDecodedToken(token?: string): any {
        if (!token) { return null; }
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    private returnTokenPayload(token) {
        return this.getDecodedToken(token);
    }

    private returnScopes() {
        const scopes = this.returnAccessTokenValue('scope')
        const filteredScopes = scopes?.split(' ').filter(function (x) {
            return x.indexOf(':') > 0;
        });
        return filteredScopes;
    }

    public returnPermissions() {
        const permissions = this.returnAccessTokenValue('permissions');
        return permissions;
    }





    public checkCookieItem(value) {
        return this.cookieService.check(value) ? true : false;
    }




    //========TOKEN SERVICE - Refresh Token========================================================================================

    private executeRefreshToken(): Observable<boolean>  {
        // If there's already a refresh operation in progress, wait for its result

        if (this.isRefreshingToken) {
            return this.refreshTokenSubject.pipe(
                take(1), // Wait for the first emission of the result
                switchMap((result) => (result ? [true] : throwError(() => new Error('[**error 032**] Refresh token failed'))))
            );
        }

        // Start the refresh token request
        this.isRefreshingToken = true;
        const token = this.refreshToken;


        if (!token) {
            this.clearSession();
            return throwError(() => new Error('[**error 031**] No refresh token found'));
        }

        const body = {
            grant_type: 'refresh_token',
            client_id: myConfig.clientID,
            refresh_token: token,
        };
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'body' as 'body' };

        return this.http.post<any>(`https://${myConfig.domain}/oauth/token`, body, options).pipe(
            map((response) => {
                const { access_token, refresh_token, id_token } = response;
                this.setSession(access_token, refresh_token, id_token); // Call your setSession method
                this.refreshTokenSubject.next(true); // Notify success
                return true;
            }),
            catchError((error) => {
                this.refreshTokenSubject.next(false); // Notify failure
                this.clearSession(this.router.navigateByUrl('/')); // Handle failure
                return throwError(() => new Error('[**error 034**]] Refresh token failed'));
            }),
            finalize(() => {
                this.isRefreshingToken = false; // Reset the flag
                this.refreshTokenSubject.complete(); // Reset the subject
                this.refreshTokenSubject = new BehaviorSubject<boolean | null>(null); // Create a fresh subject
            }),
            shareReplay(1) // Ensure multiple subscribers share the same request
        );
    }

    public clearSession(cb?: any): void {
        console.log("[auth.service].clearSession()")
        //MUST DELCARE PATH TO DELETE COOKIE. CHECK THIS ACCROSS SITE:
        this.cookieService.delete('access_token', '/');
        this.cookieService.delete('refresh_token', '/');
        this.cookieService.delete('id_token', '/');
        this.clearScheduledTask();
        // localStorage.clear();
        sessionStorage.clear();
        this.accessTokenExpiresAt = null;
        this.userIdentification.next(null);
        return cb; //callback if provided. i,e reload page or navigate.
    }

}








//AuthService using lock window
// looks for the result of authentication in the URL hash. Then, the result is processed with the
// public handleAuthentication(): void {
//     this.auth0.parseHash((err, authResult) => {
//         if (authResult?.accessToken) {
//             console.log("|///////////////////////////////==================================================authResult", authResult)
//             //id token in authResult?.idToken
//             const url: string = sessionStorage.getItem('redirect_url') ? sessionStorage.getItem('redirect_url') : '/redirect' //if redirect url exists, otherwise default to dashboard
//             this.setSession(authResult.accessToken) ? this.navigateToUrl(300, url) : this.navigateToUrl(2000, url);
//         } else if (err) {
//             console.log("error with authentication", err);
//             this.router.navigateByUrl('/');
//         }
//     });
// }
// private navigateToUrl(waittime: number, url: string) {
//     setTimeout(() => {
//         this.router.navigateByUrl(url);
//     }, waittime);
// }

