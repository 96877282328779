//core
import {Component, TemplateRef, ViewChild, Inject, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subscription, Observable, throwError, Subject } from 'rxjs';

//material
import { MatSnackBar } from '@app/material/material-essentials.module';
import { MatMenuTrigger } from '@angular/material/menu';
import {
    MAT_BOTTOM_SHEET_DATA,
    MatBottomSheet,
    MatBottomSheetRef,
    MatBottomSheetConfig
} from '@angular/material/bottom-sheet';

//services
import { NumberService } from '@app/services/pipcall/number.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { PBXService } from '@app/services/pipcall/pbx.service';
import { UserService } from '@app/services/pipcall/user.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { LicenseModel, Licensedata } from '@app/models/license.model';
import { ConfirmUserUnlinkModalService } from '@app/components/modals/confirm-user-unlink-modal/confirm-user-unlink-modal.service';
import { UserSessionService } from '@app/services/user-session.service'

//others
import { ConfirmUserUnlinkModalComponent, ModalResponse, ConfirmRemoveModalData } from '@app/components/modals/confirm-user-unlink-modal/confirm-user-unlink-modal.component';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { DdiNumber } from '@app/models/ddi.model';

@Component({
    selector: 'matmenu-user-admin-controls',
    templateUrl: './user-admin-controls.component.html',
    styleUrls: ['./user-admin-controls.component.scss']
})
export class UserAdminControlsComponent {


    @Input() user_id: string;
    @Input() name: string;
    @Input() organisation_id?: string;
    @Input() owner_user_id?: string;
    @Input() organisation_state?: string;
    @Input() license_id?: string; //current contract type if exists

    @Output() closeModal = new EventEmitter<boolean>();
    @Output() _refreshData = new EventEmitter<boolean>();

    public viewAs: string; //owner,admin or user. set to owner if matches on return of data.
    public currentLicense: LicenseModel;
    public pageStatus = {
        isLoading: true,
        isSubmitting: false
    }
    private _destroy$ = new Subject<void>();


    constructor(
        private numberService: NumberService,
        private router: Router,
        private ref: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private modalService: ModalService,
        private pbxervice: PBXService,
        private licenseDataService: LicenseDataService,
        private userService: UserService,
        private organisationService: OrganisationService,
        private confirmUserUnlinkModalService: ConfirmUserUnlinkModalService,
        private userSessionService: UserSessionService
    ) {

    }

    ngOnInit() {
        // handle missing data
    }


    ngOnChanges(changes: SimpleChanges) {
        changes['license_id']?.currentValue !== changes['license_id']?.previousValue ?   this.setCurrentLicence() : null;
    }

    setCurrentLicence() {
        this.currentLicense = this.license_id ? this.licenseDataService.returnLicense(this.license_id) : null;
        this.ref.detectChanges()
    }

    impersonateUser(user_id: string) {
        this.userSessionService.impersonateUser(user_id);
    }

    propagateUser(id: string) {
        const list = [id]

        this.userService.propagateUser(id).subscribe(
            (resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    this.openSnackBar(`${id} has been sent for propogation`, "dismiss");
                }
            }, (err) => {
                console.log(err);
                this.openSnackBar("There was a problem with this request", "dismiss");
            }
        )
    }

    forceLogout(id: string) {
        this.userService.forceLogoutUser(id).subscribe((resp) => {
            console.log(resp);
            this.openSnackBar("Request to logout user was queued", "dismiss")
        }, (err) => {
            console.log(err);
            this.openSnackBar(`${err.status} : Sorry this request failed`, "dismiss")
        });
    }

    unlinkUserFromOrganisation(user_id: string) {

        if (this.organisation_state === 'Trial') {
            const data = new ModalConfirmData;
            data.title = 'Permanently unlink user';
            data.choice = true;
            data.content = `You are about to unlink ${this.name} from the organisation. They will be moved to detached users and no longer have access to PiPcall. Are you sure ? `;
            data.confirmButtonLabel = 'Unlink';
            data.closeButtonLabel = 'Cancel';
            data.customClass = 'red-button';


            this.modalService.openConfirmModal(data, (answer: boolean) => {
                if (answer) {
                    console.log("remove user", user_id, 'for org:', this.organisation_id);

                    this.organisationService.deleteOrganisationUserTrial( this.organisation_id, user_id)
                        .subscribe((resp) => {

                            if (resp.status === 200 || resp.status === 201) {
                                this.openSnackBar("Saved", "dismiss");
                                // this.triggerUpdate();
                                // this.updateParentModal.emit('close');
                                this.refreshData();
                                return;
                            }
                            this.openSnackBar(`sorry something went wrong with this request [${resp?.status}]`, "dismiss");
                        }, (err) => {
                            console.log(err);
                            this.openSnackBar(`sorry something went wrong with this request [${err?.status}]`, "dismiss");
                        })
                }
            });
        } else {

            const data = new ConfirmRemoveModalData;

            data.contractException =  this.currentLicense?.contract_type === '1y_monthly';

            this.confirmUserUnlinkModalService.openConfirmRemoveSoftModal(data, (response: ModalResponse) => {
                if (response.confirm) {
                    this.organisationService.deleteOrganisationUser(this.organisation_id, user_id, response.ddi_action)
                        .subscribe((resp) => {

                            if (resp.status === 200 || resp.status === 201) {
                                this.openSnackBar("Saved", "dismiss");
                                this.refreshData();
                                this.closeModal.emit(true);
                                return;
                            }
                            this.openSnackBar(`sorry something went wrong with this request [${resp?.status}]`, "dismiss");
                        }, (err) => {
                            console.log(err);
                            this.openSnackBar(`sorry something went wrong with this request [${err?.status}]`, "dismiss");
                        })
                }
            });

        }

    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }


    refreshData(event?) {
        console.log("refresh data EVENT:", event);
        this._refreshData.emit(true);
    }

}

