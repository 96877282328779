<ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">
  
<ng-container *ngIf="user_id || userProfile; else notFound">


    <div [@fadeInOnEnter] class="tab-child-view-container">

      <div class="section-info-bar system fx-row fx-justify-space-between fx-align-center" >
        Editing as system administrator
      </div>


      <div class="section-wrapper">
        <tab-title-lined>Numbers</tab-title-lined>

        <div class="section-content">

          <div class="fx-row fx-col-sm fx-gap-2 fx-width-100">

            <div class="fx-col fx-width-60 fx-width-100-sm">


              <div class="container-box fx-row fx-justify-start fx-align-center fx-gap-08">

                <div style="width:74px;">

                  <div class="s74 item-container-circle activeorg fx-col fx-center">
                    <div style="height:24px;">
                      <mat-icon class="icon-size-24" svgIcon="business"></mat-icon>
                    </div>
                  </div>

                </div>

                <div class="fx-col" style="width: 250px;">
                  <ng-container>
                    <div><strong>Organisation</strong></div>

                    <div class="hover-to-show fx-row fx-justify-start fx-align-center">

                      <div (click)="openOrgDetails(organisationProfile?.name, organisationProfile?.id)"
                        class="url-color clickable">{{organisationProfile?.name}}</div>

                      <span>
                        <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(organisationProfile?.id)"
                          class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200"
                          matTooltip="Copy organisation id">
                        </mat-icon>
                      </span>
                    </div>

                    <div [matTooltip]="organisationProfile?.state" class="fx-row fx-justify-start fx-align-center"
                      *ngIf="organisationProfile?.state"><span
                        class="overflow-ellipsis">Status:&nbsp;{{organisationProfile?.state}}</span>

                      <div [hidden]="organisationProfile?.state !== 'Active'" class="status-icon">
                        <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-accept)'></mat-icon>
                      </div>

                      <div [hidden]="organisationProfile?.state!== 'Trial'" class="status-icon">
                        <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-warn)'></mat-icon>
                      </div>

                      <div [hidden]="organisationProfile?.state !== 'In Arrears'" class="status-icon">
                        <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-error)'></mat-icon>
                      </div>

                    </div>
                  </ng-container>
                </div>
              </div>



              <ng-container  *ngIf="userProfile && userProfile?.organisation_list[0]?.ddi_list && userProfile?.organisation_list[0]?.ddi_list?.length > 0 && userProfile?.organisation_list[0]?.ddi_list[0]?.number != null; else noNumbers">


                <div *ngFor="let nm of userProfile?.organisation_list[0]?.ddi_list">

                  <div class="vertical-line-divider-container">
                    <div class="vertical-line-divider"></div>
                  </div>

                  <div class="container-box fx-row fx-justify-start fx-align-center fx-gap-1">


                    <div style="width: 74px;">
                      <div  class="s74 item-container-circle activenumber fx-col fx-center">
                        <div style="height:24px;">
                          <ng-container *ngIf="nm?.pip_variant === 'pipmobile'">
                            <mat-icon class="icon-size-24" svgIcon="phone"></mat-icon>
                          </ng-container>

                          <ng-container *ngIf="nm?.pip_variant === 'pipxt'">
                            <mat-icon class="icon-size-24" svgIcon="connect"></mat-icon>
                          </ng-container>

                        </div>
                      </div>
                    </div>

                    <div class="fx-col" style="width: 250px;">

                      <ng-container *ngIf="nm?.pip_variant === 'pipmobile'">
                        <div><strong>Number</strong></div>

                        <div class="hover-to-show fx-row fx-justify-start fx-align-center">
                          <div (click)="openNumberProfile(nm?.number)" class="url-color clickable">
                            {{nm?.number | normalizeToInternational}}
                          </div>
                          <span>
                            <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(nm?.number)"
                              class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200"
                              matTooltip="Copy service id">
                            </mat-icon>
                          </span>
                        </div>

                        <div> <button (click)="unlinkNumber(nm?.number)" class="unlink-button"
                            mat-flat-button>Unlink</button></div>
                      </ng-container>

                      <ng-container *ngIf="nm?.pip_variant === 'pipxt'">
                        <div><strong>Extension</strong></div>

                        <div class="hover-to-show fx-row fx-justify-start fx-align-center">
                          <div (click)="openExtensionProfile(nm?.number)" class="url-color clickable">
                            {{nm?.sip_extension_cli | normalizeToInternational}} ({{nm?.sip_extension_number}})</div>

                          <span>
                            <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(nm?.number)"
                              class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200"
                              matTooltip="Copy service id">
                            </mat-icon>
                          </span>
                        </div>
                        <div> <button (click)="unlinkNumber(nm?.number, nm?.sip_extension_number)" class="unlink-button"
                            mat-flat-button>Unlink</button></div>
                      </ng-container>
                    </div>
                  </div>


                </div>

              </ng-container>

              <ng-template #noNumbers>

                <div class="vertical-line-divider-container">
                  <div class="vertical-line-divider"></div>
                </div>

                <div class="container-box fx-row fx-justify-start fx-align-center fx-gap-1">
                  <div style="width: 74px;">

                    <div class="item-container-circle s74 fx-col fx-center">
                      <div style="height:24px;">
                        <mat-icon style="color: var(--c-dividing-lines)" class="icon-size-24" svgIcon="phone">
                        </mat-icon>
                      </div>
                    </div>


                  </div>

                  <div class="fx-col" style="width: 250px;">
                    <ng-container>
                      <div>No numbers linked to this user</div>
                    </ng-container>
                  </div>
                </div>

              </ng-template>

            </div>

            <div class="fx-col fx-width-40 fx-width-100-sm">

              <div>Link a number from the organisation's available numbers, or a random number from our pool that is
                selected by area code.<br>
                <button *ngIf="!showNumberForm" (click)="showNumberForm=true" style="margin-top: 28px;width: 200px;"
                  color="accent" mat-flat-button>Link Number</button>

                <div>
                  <div style="margin-top: 16px;" [hidden]="!showNumberForm" fxLayout="column">

                    <div class="fx-row fx-justify-start fx-align-center">

                      <mat-form-field appearance="outline" style="width: 125px;" class="custom-form-field-v3">
                        <mat-select [(ngModel)]="pipnumberSelectionType"
                          (ngModelChange)="pipnumberAllocationForm?.controls.number.patchValue('')">
                          <mat-option value="existing">Existing</mat-option>
                          <mat-option value="new">New</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <div fxFlex style="margin-left:20px;">
                        <app-ff-org-pipnumber-select *ngIf="pipnumberSelectionType === 'existing'"
                          [organisationId]="organisation_id"
                          (_selectedPipnumber)="pipnumberAllocationForm.get('number').patchValue($event)">
                        </app-ff-org-pipnumber-select>

                        <app-ff-areacode-list-select
                          (_selectedAreacode)="pipnumberAllocationForm.get('number').patchValue($event)"
                          *ngIf="pipnumberSelectionType === 'new'">
                        </app-ff-areacode-list-select>
                      </div>
                    </div>

                    <div class="fx-row fx-justify-end">

                      <button mat-flat-button (click)="showNumberForm=false">Cancel</button>
                      <button (click)="saveNewNumber(pipnumberAllocationForm)"
                        [disabled]="pageStatus.isSubmitting || pipnumberAllocationForm.invalid" mat-flat-button
                        class="save-button">Save</button>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>




      <div class="section-wrapper">
        <tab-title-lined>Notes&nbsp;<mat-icon style="margin-left: 12px;" svgIcon="compose"></mat-icon></tab-title-lined>

        <tab-section-action [isHidden]="userNote?.pristine">
          <button mat-flat-button *ngIf="!userNote?.pristine" style="margin-right: 4px;"
            (click)="cancelForm()">Cancel</button>

          <button mat-flat-button aria-label="Save changes" class="save-button"
            [disabled]="userNote?.pristine ||  pageStatus.isSubmitting || userNote?.invalid"
            (click)="saveUserNote(userNote)">
            <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
          </button>
        </tab-section-action>


        <div class="section-content">

          <form [formGroup]="userNote">

            <div class="fx-row fx-width-100 fx-col-sm fx-gap-2">

              <div class="fx-col fx-width-50 fx-width-100-sm">

                <mat-form-field appearance="outline" class="ff-style-outline fx-width-100">
                  <textarea formControlName="notes" style="min-height: 100px;" matInput placeholder=""></textarea>
                </mat-form-field>

              </div>
            </div>

          </form>

        </div>

      </div> 

      <div class="section-wrapper">
        <tab-title-lined>Email&nbsp;<mat-icon style="margin-left: 12px;" svgIcon="email">
          </mat-icon></tab-title-lined>

        <tab-section-action [isHidden]="userEmailForm?.pristine">

          <button mat-flat-button *ngIf="!userEmailForm?.pristine" style="margin-right: 4px;"
            (click)="cancelForm()">Cancel</button>

          <button mat-flat-button aria-label="Save changes" class="save-button"
            [disabled]="userEmailForm?.pristine ||  pageStatus.isSubmitting || userEmailForm.invalid"
            (click)="saveEmail(userEmailForm)">
            <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
          </button>

        </tab-section-action>


        <div class="section-content">


          <div class="fx-row fx-width-100 fx-col-sm fx-gap-2">


            <form [formGroup]='userEmailForm' class="fx-col fx-width-60 fx-width-100-sm" 
              [ngClass]="{'readonly-block': pageStatus.isSubmitting}">


              <div class="tab-form-field-wrapper">

                <mat-form-field appearance="outline" floatLabel="always" class="ff-style-outline">
                  <input autocomplete="off" type="text" matInput placeholder="" formControlName="email">
                  <mat-label>Email</mat-label>
                  <mat-error
                    *ngIf="userEmailForm?.controls.email?.touched && userEmailForm?.controls.email?.errors?.email">
                    Email is not valid</mat-error>
                  <mat-error *ngIf="userEmailForm.get('email').errors && userEmailForm.get('email').errors.emailTaken">
                    Email already in use.
                  </mat-error>
                  <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(userProfile?.email)"
                    class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200"
                    matTooltip="Copy">
                  </mat-icon>
                </mat-form-field>
              </div>


            </form>


            <div class="fx-col fx-width-40 fx-width-100-sm">

              <span>Confirm this email address is owned by the user before updating.</span>

            </div>

          </div>
        </div>
      </div> 



      <div class="section-wrapper">
        <tab-title-lined>Handset&nbsp;<mat-icon style="margin-left: 12px;" svgIcon="device"></mat-icon>
          <span style="font-size: 0.8em;" *ngIf="userProfile?.os">
            <mat-icon *ngIf="userProfile?.os === 'iOS'"
              style="height: 16px;width:16px;margin-right: 4px;vertical-align: top;color:grey" svgIcon="apple">
            </mat-icon>
            <mat-icon *ngIf="userProfile?.os === 'Android'"
              style="height: 16px;width:16px;margin-right: 4px;vertical-align: top;color:green" svgIcon="android">
            </mat-icon>
            {{userProfile?.os}} {{userProfile?.os_version}}
          </span>
        </tab-title-lined>


        <tab-section-action [isHidden]="userHandsetForm?.pristine">
          <div style="justify-content: flex-end;display:flex;width:100%;">
            <button mat-flat-button *ngIf="!userHandsetForm?.pristine" style="margin-right: 4px;"
              (click)="cancelForm()">Cancel</button>

            <button mat-flat-button aria-label="Save changes" class="save-button"
              [disabled]="userHandsetForm?.pristine ||  pageStatus.isSubmitting || userHandsetForm.invalid"
              (click)="saveMobile(userHandsetForm)">
              <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
            </button>
          </div>

        </tab-section-action>



        <div class="section-content">

          <div class="fx-row fx-width-100 fx-col-sm fx-gap-2">


            <form [formGroup]='userHandsetForm' class="fx-col fx-width-60 fx-width-100-sm"
              [ngClass]="{'readonly-block': pageStatus.isSubmitting}">


              <div class="tab-form-field-wrapper">

                <mat-form-field appearance="outline" floatLabel="always" class="ff-style-outline">
                  <input autocomplete="off" type="text" matInput formControlName="mobile" placeholder="+44">
                  <mat-label>Handset</mat-label>
                  <mat-error
                    *ngIf="userHandsetForm?.controls.mobile?.touched && userHandsetForm?.controls.mobile?.errors?.valid">
                    Number is not a valid UK mobile
                  </mat-error>
                  <mat-error
                    *ngIf="userHandsetForm?.controls.mobile?.touched && userHandsetForm?.controls.mobile?.errors?.required">
                    Mobile is required</mat-error>
                  <mat-error *ngIf="userHandsetForm.get('mobile').status === 'PENDING'">
                    checking ...
                  </mat-error>
                  <mat-error
                    *ngIf="userHandsetForm.get('mobile').touched && userHandsetForm.get('mobile').errors?.mobileTaken">
                    Mobile already registered with an account.
                  </mat-error>
                  <mat-icon matSuffix class="icon_24" svgIcon="info"
                    [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltipShowDelay="200"
                    *ngIf="userProfile?.mobile" mdePopoverTriggerOn="hover" [mdePopoverTriggerFor]="showPopoveHint"
                    #popoverTrigger="mdePopoverTrigger"></mat-icon>


                  <mde-popover #showPopoveHint="mdePopover" [mdePopoverOverlapTrigger]="false">
                    <mat-card style="max-width: 360px;min-width:300px;">
                      <mat-card-content>
                        <div class="fx-col">
                          <div class="fx-row fx-justify-start fx-align-center">
                            <mat-icon svgIcon="info"></mat-icon> Existing Device
                          </div>
                          <div [style.margin-top.px]="10">
                            <ul class="list-unstyled">
                              <li><strong>Number:</strong>:&nbsp;{{userProfile?.mobile}}</li>
                              <li><strong>OS:</strong>:&nbsp;{{userProfile?.os}} {{userProfile?.os_version}}</li>
                              <li><strong>Carrier:</strong>:&nbsp;{{userProfile?.carrier}}</li>
                              <li><strong>PiPall ver.:</strong>:&nbsp; {{userProfile?.pipcall_version}}
                                {{userProfile?.pipcall_build}}</li>
                              <li>
                                <strong>Updated:</strong>:&nbsp;{{userProfile?.device_modified_at | date: 'dd/MM/yyyy HH:mm'}}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </mde-popover>


                </mat-form-field>
              </div>


            </form>



            <div class="fx-col fx-width-40 fx-width-100-sm">

              <span>Update the users handset with a new number in the +44 format. You should confirm that this number
                is owned by the end user before updating it.</span>

            </div>

          </div>
        </div>
        
      </div>  



    </div>


  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div style="margin-top:2px;margin-bottom: 2px;" class="loader-spinner"></div>
  </div>
</ng-template>