//core
import { Component, OnInit, Inject, isDevMode, HostListener, ViewChild, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef, MatDialog } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { trigger, state, transition, animate, style, useAnimation } from '@angular/animations'
import { forkJoin, of } from 'rxjs';
import { map, tap, filter, catchError, mergeMap, finalize, distinctUntilChanged } from 'rxjs/operators';
import { PaymentsService } from '@app/services/pipcall/payments.service';
import { LicenseModel, UnlicensedUser, AddonPackageModel, Licensedata, SelectedLicenceModel } from '@app/models/license.model';
import 'rxjs/add/operator/switchMap';
import { CurrencyPipe } from '@angular/common'
import { CustomAnimations } from '@app/helpers/animations';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { shakeAnimation, tadaAnimation, tadaOnEnterAnimation, bounceOutAnimation } from 'angular-animations';
import { slideInUpOnEnterAnimation, slideInUpAnimation, slideInDownAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, heartBeatAnimation } from 'angular-animations';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { OrganisationModel } from '@app/models/organisation.model';
import { PricingModalComponent } from '@app/components/modals/pricing-modal/pricing-modal.component';
import { TermsAndConditionsComponent } from '@app/components/modals/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { PaymentTermsModalComponent } from '@app/components/modals/payment-terms-modal/payment-terms-modal.component';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { Subject, EMPTY } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { GA4Service } from "@app/services/google-analytics-service";
import { CheckoutResponse } from '@app/components/stripe-payment/stripe-payment.component';
import { OrgSessionService } from '@app/services/organisation-session.service';

//services

export interface PricingList {
    type: string;
    displayName: string;
    licenseType: string;
    matchingLicenses: [];
    annualCost: { total: string; pounds: string; pence: string };
    monthlyCost: { total: string; pounds: string; pence: string };
    rollingCost: { total: string; pounds: string; pence: string };
}
//this is only applicable to this view
export interface InvoiceItem {
    id: string;
    quantity: number;
    billingCycle: string;
    displayString: string;
    type: string;
    total: number;
}

export interface CartItem {
    type: string;
    id: string;
    license_id: string;
    applyToAll?: boolean;
    isMobile?: boolean;
    licence?: SelectedLicenceModel;
}


@Component({
    templateUrl: './first-payment-modal.component.html',
    styleUrls: ['./first-payment-modal.component.scss'],
    animations: [
        CustomAnimations,
        shakeAnimation(),
        heartBeatAnimation(),
        tadaOnEnterAnimation(),
        tadaAnimation(),
        bounceOutAnimation(),
        slideInUpOnEnterAnimation(),
        slideInUpAnimation(),
        slideInDownAnimation(),
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class FirstPaymentModal {

    public licenseList: LicenseModel[];
    public pricingListStandard: PricingList[];
    public pricingListBasic: PricingList[];
    public addOnPackageList: AddonPackageModel[];

    // public organisationProfile: OrganisationModel;
    //  { id: string; name: string; state: string; };
    public unlicensedList: UnlicensedUser[];
    public termsconditions: boolean; //checkbox
    public cartTotalPrice: { total: string; pounds: string; pence: string };
    public subscriptionTotalPrice: { total: string; pounds: string; pence: string };
    public _subsciption: Subscription;
    public cart: CartItem[];
    public invoice: InvoiceItem[];

    // isLoadingLicense = true;
    // isLoadingUsers = true;

    disableForm = false;
    clientSecret = '';
    checkoutResponse: CheckoutResponse;
    screen: 'mobile' | 'desktop'; //screen size  lt-lg vs gt-lg
    formState: 'cart' | 'pay' | 'error' | 'success';
    checkoutIsLoading = false;
    shakeState = false;
    licenseToggle = 'license';
    applyToAllEvent: SelectedLicenceModel;


    //new
    pageStatus = {
        isLoading: true,
        isSubmitting: false
    }

    getDataStatus = {
        licenses: false,
        users: false,
        addOns: false
    }

    @ViewChild('paymentMobileView', { static: false }) paymentMobileView: ElementRef;
    @ViewChild('paymentDesktopView', { static: false }) paymentDesktopView: ElementRef;
    @ViewChild('paymentContainer', { static: false }) paymentContainer: ElementRef;


    includeAddOns = false;
    // @ViewChild(StripePaymentComponent) stripePaymentComp: StripePaymentComponent;


    constructor(
        private dialogRef: MatDialogRef<FirstPaymentModal>,
        @Inject(MAT_DIALOG_DATA)
        public data: {organisation_id: string}, //contains data injected into modal.
        public dialog: MatDialog,
        private paymentsService: PaymentsService,
        private modalService: ModalService,
        private snackBar: MatSnackBar,
        public breakpointObserver: BreakpointObserver,
        private currencyPipe: CurrencyPipe,
        private licenseDataService: LicenseDataService,
        private appInsightService: AppInsightService,
        private renderer: Renderer2,
        private ref: ChangeDetectorRef,
        private gA4Service: GA4Service,
        private orgSessionService: OrgSessionService
    ) {
        // dialogRef.disableClose = true; //disable closing of modal

        this.formState = 'cart';

        //handle if no org
        this.checkoutResponse = {
            invoice_total: 0,
            stripe_client_secret: ''
        }

        this.unlicensedList = new Array<UnlicensedUser>();
        this.cart = new Array<CartItem>();
        this.pricingListStandard = new Array<PricingList>();
        this.pricingListBasic = new Array<PricingList>();
        this.invoice = new Array<InvoiceItem>();
        this.addOnPackageList = new Array<AddonPackageModel>();

        this.cartTotalPrice = {
            total: '0.00',
            pounds: '0',
            pence: '00'
        }
        this.subscriptionTotalPrice = {
            total: '0.00',
            pounds: '0',
            pence: '00'
        }
    }

    ngAfterViewChecked() {

        // this.scroll.reset()
        this.breakpointObserver
            .observe(['(max-width: 1279px)'])
            .pipe(
                distinctUntilChanged()
            )
            .subscribe((_state: BreakpointState) => {
                if (_state.matches) {
                    //mobile screen.
                    this.screen !== 'mobile' ? this.renderer.appendChild(this.paymentMobileView.nativeElement, this.paymentContainer.nativeElement) : '';
                    this.screen = "mobile";
                } else {
                    //larger screen
                    this.screen !== 'desktop' ? this.renderer.appendChild(this.paymentDesktopView.nativeElement, this.paymentContainer.nativeElement, ) : '';
                    this.screen = "desktop";
                }
            });
    }
    ngOnInit() {
        this.appInsightService.logEvent('Payment Modal Open', { 'organisation_id': this.data?.organisation_id})
        this.data?.organisation_id ?  this.getCheckOutData() : this.alertClose('No organisation ID was found. Please try again later.');
    }

    ngAfterViewInit() {
        // this.getCheckOutData();
    }

    getCheckOutData() {
        return forkJoin({
            unlicensedList: this.getUnlicensedList()
                .pipe(
                    catchError((error) => {
                        console.log('Error in unlicensed list:', error);
                        this.alertClose('No unlicensed users were found for this organisation');
                        return EMPTY; // Returning an empty observable to continue the forkJoin
                    })
                ),
            addOnPackageList: this.getActiveAddOnPackageList()
                .pipe(
                    catchError((error) => {
                        console.log('Error in add-on package list:', error);
                        return of(null); // Returning a null value to continue the forkJoin
                    })
                ),
            licenseList: this.getActiveLicensesList()
                .pipe(
                    catchError((error) => {
                        console.log('Error in add-on package list:', error);
                        this.alertClose('Could not get licenses at this time. Please try again later');
                        return of(null); // Returning a null value to continue the forkJoin
                    })
                ),
        }).subscribe({
            next: (result) => {
                console.log('All API calls completed');
                console.log("trigger no user alert, result.unlicensedList============", result.unlicensedList);
                result.unlicensedList !== null ?  this.unlicensedList = result.unlicensedList : this.alertClose('Before you can upgrade and make your license choice, we need you to have at least one PiPuser.', 'Please add a PiPuser first...');
                result.addOnPackageList != null ? this.addOnPackageList = result.addOnPackageList : null;
                result.licenseList != null ?  this.licenseList = result.licenseList : this.alertClose('Could not get licenses at this time. Please try again later');
                this.pageStatus.isLoading = false;
                // Do something with the results
            },
            error: (err) => {
                console.log('Error:', err);
                // Handle error if any of the observables encounters an error
            }
        });
    }

    getUnlicensedList(): Observable<any> {
        console.log("get unlicensed list");
        return this.paymentsService.getUnlicensedList(this.data.organisation_id)
            .pipe(
                take(1),
                map((value) => { return value.body?.unlicensed_list })
            );
    }

    getActiveAddOnPackageList(): Observable<any> {
        console.log('get active add on package list');
        return this.licenseDataService.activeAddOnPackageListAsObservable
            .pipe(
                filter(value => value !== null && value !== undefined && value.length > 0),
                take(1)
            );
    }

    getActiveLicensesList(): Observable<any> {
        console.log("get Active License List");
        return this.licenseDataService.activeLicenseListAsObservable
            .pipe(
                filter(value => value !== null && value !== undefined && value.length > 0),
                take(1)
            );
    }

    alertClose(message: string, title?: string) {

        const titlecopy = title ? title : 'something went wrong';
        const data: ModalConfirmData = {
            title: titlecopy,
            content: message,
            confirmButtonLabel: "Close",
            closeButtonLabel: "",
            choice: false,
            disableClose: true
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.closeModal(false);
                return;
            }
            console.log('answer was No');
        });

    }

    checkIfLoaded(source?: string) {
        console.log('check if loaded:', source, '  ->',  this.getDataStatus.users, this.getDataStatus.licenses, this.getDataStatus.addOns );
        if (this.getDataStatus.users && this.getDataStatus.licenses && this.getDataStatus.addOns) {
            console.log('loaded');
            this.pageStatus.isLoading = false;
            this.ref.detectChanges();
        } else {
            console.log('still loading');
        }
    }



    updateCart(cartItem: CartItem) {
        const index = this.getCartItemIndex(cartItem.id);
        index > -1 ? this.removeCartItem(index) : null; //if entry already exists, remove it
        cartItem.license_id ? this.addCartItem(cartItem) : null; //if we have a license ID then add it to the cart
        this.totalUpCart();    //recalculate total price

        if (cartItem?.applyToAll && cartItem?.licence) {
            this.applyToAllEvent = cartItem?.licence;
        }
    }

    findCartItem(id) { return this.cart.find(item => item.id === id); }

    getCartItemIndex(id) { return this.cart.map((item) => { return item.id; }).indexOf(id); }

    addCartItem(item: CartItem) { this.cart.push(item); }

    removeCartItem(index) { this.cart.splice(index, 1); }

    buildInvoice() {
        this.invoice = []; //empty the array;
        this.cart.forEach(cartItem => {
            const invoiceIndex = this.invoice.map((item) => { return item.id; }).indexOf(cartItem.license_id); //does license id exist in invoice
            const licenseDetails = this.licenseList.find(item => item.id === cartItem.license_id);

            const entry: InvoiceItem = {
                id: cartItem.license_id,
                quantity: 1,
                billingCycle: licenseDetails.contract_name,
                displayString: licenseDetails.name,
                type: licenseDetails.contract_type,
                total: licenseDetails.price
            }
            if (invoiceIndex > -1) {
                this.invoice[invoiceIndex].quantity++; //increate the quantity
            } else { this.invoice.push(entry) }; //if entry already exists, add Quantity to it
        });

    }

    continueCheckout() {
        // alert(JSON.stringify(this.cart, null, '\t'));
        // this.toggleShowDiv('divA');
        //handle refresh of form IF conflict
        this.appInsightService.logEvent('Checkout: continue to summary', { 'organisation': this.data?.organisation_id });

        this.buildInvoice();
        this.checkoutIsLoading = true;
        this.paymentsService.postLicensesCart(this.data?.organisation_id, this.cart)
            .pipe(
                tap(() => {
                    this.disableForm = true;
                    this.checkoutIsLoading = true;
                }),
                finalize(() => {
                    setTimeout(() => {
                        this.disableForm = false;
                        this.checkoutIsLoading = false;
                    }, 2000);
                    //this stops too early. stripe takes time to start sometimes. Consider putting timeout on it //Also SPINNER NOT WORKING> TEST TAP
                })
            )
            .subscribe((resp) => {
                if (!resp?.body?.invoice_total || !resp?.body?.stripe_client_secret) {
                    //throw error
                    this.openSnackBar('The response from the server was missing some values. Please contact our support@pipcall.com', 'dismiss');
                    this.checkoutIsLoading = false;
                } else {
                    this.checkoutResponse = resp?.body;
                    this.formState = 'pay';
                }
            }, (err) => {
                console.log("error:", err);
                this.openSnackBar(err.error.Message, "dismiss");
            })
    }




    totalUpCart() {
        let total = 0;
        let subTotal = 0;

        this.cart.forEach(cartItem => {
            const license = this.licenseList.find(item => item.id === cartItem.license_id);
            total += license.price;

            license.contract_type !== LicenseDataService.annualContract ? subTotal += license.price : null;
        });
        this.cartTotalPrice.total = this.convertToCurrencyString(total);
        this.cartTotalPrice.pounds = this.cartTotalPrice.total.split(".")[0]
        this.cartTotalPrice.pence = this.cartTotalPrice.total.split(".")[1]

        this.subscriptionTotalPrice.total = this.convertToCurrencyString(subTotal);
        this.subscriptionTotalPrice.pounds = this.subscriptionTotalPrice.total.split(".")[0]
        this.subscriptionTotalPrice.pence = this.subscriptionTotalPrice.total.split(".")[1]

    }


    convertToCurrencyString(price) {
        return this.currencyPipe.transform(price / 100, 'GBP', '', '1.2-2');
    }


    closeModal(bool?: boolean) {
        this.onDestroy$.next(); // Emit signal to unsubscribe from observables
        this.onDestroy$.complete(); // Complete the subject to release resources
        const status = bool ? bool : false;
        this.dialogRef.close(status);
    }


    checkoutSucess(event) {
        if (event === true) {
            //log an analytics event here
            this.gA4Service.checkout_success(this.checkoutResponse?.invoice_total);
            this.formState = "success";
            setTimeout(() => {
                this.orgSessionService.updateOrganisationProfile();
            }, 5000);
        }
    }

    openTermsConditionsModal() {

        const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
            panelClass: 'pipcall-modal-container',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                //do action on modal close
            }
        });

    }

    openSnackBar(message: string, action: string) {
        const snackBarRef = this.snackBar.open(message, action, {
            duration: 5000,
        });

    }

    ngOnDestroy() {
        this._subsciption ? this._subsciption.unsubscribe() : null;
    }

    openPricingModal() {

        const dialogRef = this.dialog.open(PricingModalComponent, {
            panelClass: ['pipcall-modal-container'],
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                //do action on modal close
            }
        });

    }

    openPaymentTerms() {
        const data = { firstPayment: true }
        const dialogRef = this.dialog.open(PaymentTermsModalComponent, {
            hasBackdrop: false,
            panelClass: 'pipcall-modal-container',
            data: data,
            autoFocus: false,
            position: {
                top: '180px',
                left: '180px'
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) { }
        });

    }

    // Create a subject to emit a signal when the modal is closed or destroyed
    private onDestroy$: Subject<void> = new Subject<void>();

}
