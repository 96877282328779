/* eslint-disable max-len */
import { environment } from '../../../environments/environment';
import { Component, OnInit, isDevMode, ChangeDetectorRef , HostListener, Renderer2, ViewChild} from '@angular/core';
import { AuthService } from '@app/services/auth-service/auth.service';
import { FormGroup, Validators, FormBuilder, ValidationErrors, FormControl, Form, AbstractControl, FormsModule } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { ModalService } from '@app/services/modal-service/modal.service';
import { finalize } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';

//normalizing number
import { AppTitleService } from '@app/services/helpers/update-title.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { fadeInUpOnEnterAnimation, fadeInRightOnEnterAnimation, fadeOutLeftOnLeaveAnimation, fadeInLeftOnEnterAnimation, fadeOutRightOnLeaveAnimation, fadeInOnEnterAnimation } from 'angular-animations';
import { TitleCasePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { SignupService , SignupForm} from '@app/services/pipcall/signup.service';
import { EmailMatchValidation } from '@app/validators/emailMatch.Validator';
import { PasswordMatchValidation } from '@app/validators/passwordMatch.Validator';
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { ValidateEmailNotTaken } from '@app/validators/async-email.validator';
import { ValidateMobileNotTakenV2 } from '@app/validators/async-mobile.validator';
import { GA4Service } from '@app/services/google-analytics-service';

export class SignupResponse {
    organisation_signup_request_id: string;
    ddis?: string[];
    organisation_id: string;
    is_valid_code: boolean;
    is_code_expired: boolean;
    access_token: string;
    refresh_token: string;
}

@Component({
    selector: 'app-pipmobile-signup-page',
    templateUrl: './pipmobile-signup.component.html',
    styleUrls: ['./pipmobile-signup.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ]),
        trigger('fadeInOut', [
            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({ opacity: 1 })),
            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({ opacity: 0 }),
                animate(600)
            ]),
        ]),
        fadeInOnEnterAnimation({ duration: 800 }),
        fadeOutLeftOnLeaveAnimation({ duration: 250, translate: '50%' }),
        fadeInRightOnEnterAnimation({ duration: 300, delay: 400, translate: '50%' }),
        fadeOutRightOnLeaveAnimation({ duration: 250, translate: '50%' }),
        fadeInLeftOnEnterAnimation({ duration: 300, delay: 400, translate: '50%' }),
        fadeInUpOnEnterAnimation({ anchor: 'enter' }),
    ]
})
export class PiPmobileSignupComponent implements OnInit {

    public isDev: boolean;
    public appversion: string = environment.appversion;
    public copyrightMessage = environment.copyrightMessage;

    signUpForm: FormGroup;
    public pwType: 'password' | 'text' = 'password';
    verificationForm: FormGroup;

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isError: false,
        isSuccess: false,
        errorMessage: '',
        step: 0
    }
    formTouched = false;

    public signUpResponse: SignupResponse;
    public isMobileView = false;

    constructor(
        public dialog: MatDialog,
        private appInsightService: AppInsightService,
        private snackBar: MatSnackBar,
        private modalService: ModalService,
        public auth: AuthService,
        public router: Router,
        private renderer2: Renderer2,
        public route: ActivatedRoute,
        private appTitleService: AppTitleService,
        private cookieService: CookieService,
        private signupService: SignupService,
        private _fb: FormBuilder,
        private ref: ChangeDetectorRef,
        private gA4Service: GA4Service
    ) {

        this.appTitleService.setTitle('PiPcall: PiPmobile Signup');
        this.appInsightService.logPageView('Signup: form');

        const passwordRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
        const emailRegex = new RegExp('^(([^<>()[\\]\\\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');

        this.signUpForm = this._fb.group({
            personalInfo: this._fb.group({
                first_name: ['', [Validators.maxLength(18), Validators.pattern(`^[a-zA-Z]+(([\'-][a-zA-Z ])?[a-zA-Z ]*)*$`), Validators.required]],
                last_name: ['', [Validators.maxLength(18), Validators.pattern(`^[a-zA-Z]+(([\'-][a-zA-Z ])?[a-zA-Z ]*)*$`), Validators.required]],
                region: ['0044', Validators.required],
                contact_phone_number:  ['', [Validators.required, PhonenumberValidator.validateMobileNumber], [ValidateMobileNotTakenV2.createValidator(this.signupService)]],
                organisation_name: ['', [Validators.maxLength(100), Validators.required]],
            }),
            loginCredentials: this._fb.group({
                email: ['', [Validators.required,  Validators.pattern(emailRegex)], [ValidateEmailNotTaken.createValidator(this.signupService)]],
                password: ['', [Validators.required, Validators.pattern(passwordRegex)]],
                termsconditions: [false, Validators.requiredTrue],
            }),
            recaptcha: this._fb.group({
                recaptcha: ['', Validators.required]
            })});

        // passwordConfirm: ['', [Validators.required, PasswordMatchValidation.MatchPassword]],

        this.verificationForm = this._fb.group({
            organisation_name: [''],
            password: ['', [Validators.required]],
            code: ['', [Validators.required]],
            signup_request_id: [, [Validators.required]]
        });
    }

    get f () { return this.signUpForm.controls }
    get contactPhoneNumber() { return this.signUpForm.get('personalInfo').get('contact_phone_number'); }
    get hasExistingToken(): boolean {
        // If a valid access token exists OR a valid refresh token exists, return true
        return this.cookieService.check('access_token') && this.auth.isAuthenticated() ? true : this.cookieService.check('refresh_token') ? true : false;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobileView = window.innerWidth <= 1080;
    }

    ngOnInit() {

        if (this.hasExistingToken) { this.navigateByUrl('/redirect'); }

        // this.renderer2.setStyle(document.body, 'background-color', 'pink');
        // this.renderer2.addClass(document.body, 'dark-body-background');
        this.isMobileView = window.innerWidth <= 1080;
        if (this.isMobileView) {
            this.renderer2.setStyle(document.body, 'background-color', '#2b2d42');
        }

        this.signUpForm.valueChanges.subscribe(value => {
            if (this.formTouched) {return; } else {
                console.log("form is touched");
                this.gA4Service.sign_up_start('pipmobile');
                this.formTouched = true;
            }
        });
    }

    ngAfterViewInit() {
        console.log('ngAfterViewInit=========================');
    }

    navigateByUrl(url) {
        this.router.navigateByUrl(url);
    }


    resolveRecaptcha(event) {
        console.log("recaptcha event:", event);
        this.pageStatus.isError = false;

        this.f.recaptcha.get('recaptcha').patchValue(event);
        this.signUpForm.updateValueAndValidity();

        if (event) {
            this.submitSignup()
        }

    }

    submitSignup() {
        console.log("[signup].submitSignup()", this.signUpForm.value, this.f);

        if (!this.f.personalInfo.valid || !this.f.loginCredentials.valid || !this.f.recaptcha.valid) {
            alert('Please fill in all fields');
            this.pageStatus.isSubmitting = false;
        }

        if (this.f.personalInfo.valid || this.f.loginCredentials.valid || this.f.recaptcha.valid) {
            this.pageStatus.isSubmitting = true;

            //format data
            this.f.personalInfo.get('first_name').patchValue(this.f.personalInfo.value.first_name.trim());
            this.f.personalInfo.get('last_name').patchValue(this.f.personalInfo.value.last_name.trim());

            //format mobile
            const mobileTrimmed = this.f.personalInfo.value.contact_phone_number.trim().replace(/\s+/g, '');
            const newFormat = mobileTrimmed.startsWith('0') ? mobileTrimmed.replace('0', this.f.personalInfo.value.region) : this.f.personalInfo.value.region + mobileTrimmed;

            const signupFormValues: SignupForm = {
                first_name: this.f.personalInfo.value.first_name,
                last_name: this.f.personalInfo.value.last_name,
                contact_phone_number: newFormat,
                email: this.f.loginCredentials.value.email,
                recaptcha: this.f.recaptcha.value.recaptcha,
                pip_variant: 'pipmobile',
            }

            this.signupService.postNewSignup(signupFormValues)
                .pipe(
                    finalize(() => { this.pageStatus.isSubmitting = false })
                )
                .subscribe((resp) => {
                    this.appInsightService.logEvent('Form submitted | Signup', { 'email': this.f.loginCredentials.value.email });

                    if (resp.status === 200 || resp.status === 201) {
                        if (resp.body?.organisation_signup_request_id) {
                            this.gA4Service.sign_up_pipmobile_stage1_complete();
                            this.verificationForm.get('signup_request_id').patchValue(resp.body?.organisation_signup_request_id);
                            this.verificationForm.get('password').patchValue(this.f.loginCredentials.value.password);
                            this.verificationForm.get('organisation_name').patchValue(this.f.personalInfo.value.organisation_name.trim());
                            this.next();
                        } else {
                            this.openSnackBar("We are currently experiencing a problem with signup. Please contact support@pipcall.com if this continues.", "dismiss");
                        }
                    }
                }, (err) => {
                    console.log(err);
                    if (err.status === 500) { this.openSnackBar("Something went wrong", "dismiss") };
                    if (err.status === 412) {
                        this.resetRecaptcha();
                        this.signUpForm.updateValueAndValidity();
                        alert('Recaptcha has expired. Please try again.')
                    }
                })

        }

    }

    clearAllData() {
        this.auth.clearCookieData();
        this.cookieService.set('viewAs', '', -1, '/');
        sessionStorage.clear();
    }

    checkCookie(): void {
        if ( this.cookieService.check('referalcookie')) {

            //if the referal page cookie exists, log it
            const referalcookie = this.cookieService.get('referalcookie');
            const decodedQueryString = decodeURIComponent(referalcookie)
            const urlParams = new URLSearchParams(decodedQueryString);

            const refererJSON = {
                email: this.f.loginCredentials.value.email,
                medium: urlParams.get('utm_medium'),
                campaign: urlParams.get('utm_campaign'),
                source: urlParams.get('utm_source') !== null ? urlParams.get('utm_source') : urlParams.get('referer'),
                term: urlParams.get('utm_term'),
                keywords: urlParams.get('hsa_kw'),
                raw: referalcookie
            }
            this.appInsightService.logEvent('Signup : referal', refererJSON);
        }
    }

    // this called every time when user changed the code
    onCodeChanged(code: string) {
        console.log("code changed", code);
    }

    // this called only if user entered full code
    onCodeCompleted(code: string) {
        this.verificationForm.controls.code.patchValue(code);
        this.verifyCode()
    }

    verifyCode() {

        if (!this.verificationForm.valid) {return console.log("form invalid"); }
        this.clearAllData();
        this.appInsightService.logEvent('Signup: verify');

        this.pageStatus.isSubmitting = true;
        this.pageStatus.isError = false;
        this.signupService.postSignupVerificationCode(this.verificationForm.value.signup_request_id, this.verificationForm.value.code, this.verificationForm.value.password, this.verificationForm.value.organisation_name)
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false })
            )
            .subscribe(
                (resp) => {
                    //     "organisation_signup_request_id":
                    //     "organisation_id":
                    //     "is_valid_code":
                    //     "is_code_expired":
                    //     "access_token":
                    //     "refresh_token":
                    //     "expires_in": "3600",
                    //     "scope": "openid profile offline_access"
                    this.gA4Service.sign_up_pipmobile_stage3_complete();
                    console.log("VERIFY CODE RESPOSE:", resp);
                    // this.signUpResponse = resp.body;
                    // const ddis = this.signUpResponse?.ddis; //this is an array of ddis that have been allocated to the organisation
                    const token = resp.body?.access_token;
                    this.pageStatus.isSuccess = true;
                    this.appInsightService.logEvent('Signup : code verified', { 'email': this.f?.loginCredentials.value.email });
                    this.auth.setSession(token, resp.body?.refresh_token, resp.body?.id_token);
                    this.checkCookie();
                    this.next();
                    setTimeout(() => {
                        //sucessful signup
                        return this.navigateToHome(500);
                    }, 5000);
                    //set delay to allow for db to catch up
                },
                (err) => {
                    console.log("response to error", err);

                    console.log(err.error);

                    switch (err.status) {
                        case 400:
                            this.pageStatus.errorMessage = '400: Verification code is invalid';
                            break;
                        case 409:
                            this.pageStatus.errorMessage = '409: There was a conflict when creating the account';
                            break;
                        case 410:
                            this.pageStatus.errorMessage = '410: Signup request has expired, please go back and resubmit the form';
                            break;
                        default:
                            //this.pageStatus.errorMessage = err.error?.Message ? err.error?.Message : "Something went wrong, signup aborted";
                            this.pageStatus.errorMessage = err.error ? err.error : "Something went wrong, signup aborted";
                            break;
                    }
                    return this.pageStatus.isError = true;
                }
            )
    }

    navigateToHome(waittime: number) {
        setTimeout(() => {
            this.router.navigateByUrl('/organisation');
        }, waittime);
    }

    next() {
        this.pageStatus.step++;
        this.ref.detectChanges();
    }
    prev() {
        this.pageStatus.step > 0 ? this.pageStatus.step-- : null;
        this.ref.detectChanges();
    }

    triggerRecaptcha() {
        this.gA4Service.sign_up_pipmobile_stage2_complete();
        this.pageStatus.isSubmitting = true;
        grecaptcha.execute();
    }

    resetRecaptcha() {
        grecaptcha.reset();
        this.f.recaptcha.get('recaptcha').patchValue('');
    }

    cancelVerification() {
        console.log("cancel verification");
        this.verificationForm.reset();
        this.signUpForm.updateValueAndValidity();
        this.resetRecaptcha();
        setTimeout(() => {
            this.prev();
        }, 180);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

}
