import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';

//create modal components

import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { FlexLayoutModule } from '@angular/flex-layout'

import { AdminUpgradeToPipuserdModule } from '@app/components/admin-upgrade-to-pipuser/admin-upgrade-to-pipuser.module';

import { AdminExtensionInvitationComponent } from './admin-extension-invitation-modal.component';
import { AdminUpgradeInvitationToPipxtModule } from '@app/components/admin-upgrade-invitation-to-pipxt/admin-upgrade-invitation-to-pipxt.module';
@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        MaterialModule,
        DragDropModule,
        FlexLayoutModule,
        AdminUpgradeToPipuserdModule,
        AdminUpgradeInvitationToPipxtModule
    ],
    declarations: [AdminExtensionInvitationComponent],
    exports: [AdminExtensionInvitationComponent],
    providers: [AppInsightService],
    entryComponents: [AdminExtensionInvitationComponent]
})
export class AdminExtensionInvitationModule { }
