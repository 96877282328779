import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
    transform(value: number, currencyCode = 'GBP'): string {
        if (value == null) {return ''; }

        const formattedValue = (value).toFixed(2);
        const displayValue = formattedValue.endsWith('.00')
            ? formattedValue.slice(0, -3)
            : formattedValue;

        const currencySymbol = currencyCode === 'GBP' ? '£' : currencyCode;

        return `${currencySymbol}${displayValue}`;
    }
}
