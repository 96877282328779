import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

//modules (imports)
import { MaterialModule } from '@app/material/material.module';
import { CustomPipesModule } from '@app/pipes/pipes.module'
import { FormBasicControlsModule} from '@app/components/toolbar/form-basic-controls/form-basic-controls.module';


//tabs

import { DDITabSystemModule } from '@app/components/tabs/ddi-system/ddi-system.module';
import { DDITabOverviewModule } from '@app/components/tabs/ddi-overview/ddi-overview.module';

//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AppTitleService } from '@app/services/helpers/update-title.service'
import { UserService } from '@app/services/pipcall/user.service';
import { NumberService } from '@app/services/pipcall/number.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { SharedDataServiceModule } from '@app/services/shared-data.service/shared-data.service.module';
import { ModalService } from '@app/services/modal-service/modal.service';
//declarations
import { DDIBottomSheetComponent } from './ddi-bsheet-modal.component';

import { DDIAdminControlsModule } from '@app/components/admin-controls/ddi-admin-controls/ddi-admin-controls.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        CustomPipesModule,
        MaterialModule,
        FormBasicControlsModule,
        DDITabOverviewModule,
        DDITabSystemModule,
        SharedDataServiceModule,
        DDIAdminControlsModule
    ],
    declarations: [DDIBottomSheetComponent],
    providers: [HttpProxy, AppInsightService, AppTitleService, UserService, OrganisationService, NumberService, ModalService],
    exports: [DDIBottomSheetComponent],
    entryComponents: []
})
export class DDIBottomSheetModule {
}
