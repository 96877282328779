<div class="bottom-sheet-content-container">

  <div class="close-button-wrapper">
    <button class="bs-toolbar-close-btn" mat-flat-button color="primary" (click)="dismiss()" mat-button>Close</button>
  </div>

  <div class="page-container">
    <div class="m-content-container bottom-sheet-container">

      <div class="content-page-title-wrapper">
        <h1>Invoice</h1>
        <h2>{{invoice?.organisation_name}}</h2>
      </div>


      <div class="content-body">


        <div class="fx-width-100">


          <div class="m-content-body-wrapper">
            <ng-container *ngIf="!this.pageStatus.isLoading; else loading">

            <ng-container *ngIf="!this.pageStatus.isLoading && !this.pageStatus.noContent;">
              
                <app-invoice-tabs-view [invoice]="invoice" [invoiceLicenseItems]="invoiceLicenseItems" [addOnPackageItems]="addOnPackageItems"
                  [viewAs]="viewAs" [licenseStubItems]="licenseStubItems" [adHocItems]="adHocItems" (updateParent)="refreshData($event)"></app-invoice-tabs-view>

            </ng-container>

            <ng-container *ngIf="!this.pageStatus.isLoading && this.pageStatus.noContent">
              No Invoice returned
            </ng-container>
            </ng-container>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>

<ng-template #loading>
  <div fxLayoutAlign="center center">
    <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
      <div class="bounce4"></div>
    </div>
  </div>
</ng-template>
