<ng-container *ngIf="organisation_id; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div [@fadeInOnEnter] class="tab-child-view-container">

      <div class="section-wrapper">

        <tab-title-lined>Products</tab-title-lined>

        <tab-section-action [isHidden]="updateOrganisationSettingForm?.pristine">
          <button mat-flat-button *ngIf="!updateOrganisationSettingForm?.pristine" style="margin-right: 4px;"
          (click)="cancelForm()">Cancel</button>

        <button mat-flat-button aria-label="Save changes" class="save-button"
          [disabled]="updateOrganisationSettingForm?.pristine ||  pageStatus.isSubmitting || updateOrganisationSettingForm?.invalid"
          (click)="saveOrgProductSettings(updateOrganisationSettingForm)">
          <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
        </button>
          </tab-section-action>


        <div class="section-content">

          <div class="fx-row fx-col-sm fx-gap-2">

            <form class="fx-col" [formGroup]="updateOrganisationSettingForm">

              <div>
                <mat-slide-toggle formControlName="is_pipmobile_enabled">
                  Enable PiPmobile</mat-slide-toggle> <i [style.margin-left.px]="20" *ngIf="isSubmitting"
                  class="fas fa-spinner fa-spin"></i>
              </div>

              <div>
                <mat-slide-toggle formControlName="is_pipxt_enabled">
                  Enable Mobile+</mat-slide-toggle><i [style.margin-left.px]="20" *ngIf="isSubmitting"
                  class="fas fa-spinner fa-spin"></i>
              </div>

              <div>
                <mat-slide-toggle formControlName="is_esim_enabled">
                  Enable eSIM</mat-slide-toggle><i [style.margin-left.px]="20" *ngIf="isSubmitting"
                  class="fas fa-spinner fa-spin"></i>
              </div>

            </form>
          </div>
        </div>
      </div>

      <div class="section-wrapper">
        <tab-title-lined>Notes&nbsp;<mat-icon svgIcon="compose"></mat-icon></tab-title-lined>

        <tab-section-action [isHidden]="updateOrgNoteForm?.pristine">
          <button mat-flat-button *ngIf="!updateOrgNoteForm?.pristine" style="margin-right: 4px;"
          (click)="cancelForm()">Cancel</button>

        <button mat-flat-button aria-label="Save changes" class="save-button"
          [disabled]="updateOrgNoteForm?.pristine ||  pageStatus.isSubmitting || updateOrgNoteForm?.invalid"
          (click)="saveNote(updateOrgNoteForm)">
          <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
        </button>

        </tab-section-action>

        <div class="section-content">

          <div class="fx-row fx-width-100 fx-col-sm fx-gap-2">

            <form [formGroup]="updateOrgNoteForm" class="fx-col fx-width-50 fx-width-100-sm"  [ngClass]="{'readonly-block': pageStatus.isSubmitting}">

              <mat-form-field appearance="outline"  class="ff-style-outline fx-width-100">
                <textarea formControlName="notes" style="min-height: 100px;" matInput placeholder=""></textarea>
              </mat-form-field>
            </form>
            <div class="fx-col fx-grow" style="padding-top:20px;">
              This note is only visible by system administrators
            </div>
          </div>


        </div>
      </div>




      <div class="fx-row fx-col-sm fx-gap-08">
        <div class="section-wrapper fx-width-50 fx-width-100-sm">
          <tab-title-lined>State</tab-title-lined>


          <tab-section-action [isHidden]="organisationStateForm?.pristine">

              <button mat-flat-button *ngIf="!organisationStateForm?.pristine" style="margin-right: 4px;"
                (click)="cancelForm()">Cancel</button>

              <button mat-flat-button aria-label="Save changes" class="save-button"
                [disabled]="organisationStateForm?.pristine ||  pageStatus.isSubmitting || organisationStateForm?.invalid"
                (click)="patchState(organisationStateForm)">
                <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
              </button>
   
          </tab-section-action>

          <div class="section-content">

            <div class="fx-row fx-col fx-gap-2 fx-width-100">
              <form [formGroup]='organisationStateForm' class="fx-col fx-width-100-sm" style="width: 200px;" [ngClass]="{'readonly-block': pageStatus.isSubmitting}">
                <mat-form-field appearance="outline" class="ff-style-outline 3 fx-grow">
                  <mat-select formControlName="state" placeholder="Select">
                    <mat-option *ngFor="let state of stateOptions" [value]="state.name">
                      {{state.name}}
                    </mat-option>
                  </mat-select>
                  <mat-label>State</mat-label>
                </mat-form-field>
              </form>

            </div>
          </div>
        </div>

        <div class="section-wrapper fx-width-50 fx-width-100-sm">

          <tab-title-lined>Trial End</tab-title-lined>

          <tab-section-action [isHidden]="updateTrialDateForm?.pristine">
              <button mat-flat-button *ngIf="!updateTrialDateForm?.pristine" style="margin-right: 4px;"
                (click)="cancelForm()">Cancel</button>

              <button mat-flat-button aria-label="Save changes" class="save-button"
                [disabled]="updateTrialDateForm?.pristine ||  pageStatus.isSubmitting || updateTrialDateForm.invalid"
                (click)="saveTrialDate(updateTrialDateForm)">
                <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
              </button>
          </tab-section-action>

          <div class="section-content fx-col fx-justify-space-between fx-align-start">

            <div class="fx-row fx-col-sm fx-gap-2 fx-width-100">

              <form [formGroup]="updateTrialDateForm" class="fx-col fx-width-100-sm" style="width: 200px;">

                <mat-form-field appearance="outline" class="ff-style-outline">

                  <input id="mat-select-custom" formControlName="trial_end_date" class="clickable" autocomplete="false"
                    matInput [matDatepicker]="picker" [min]="today" [value]="trialEndDate" (click)="picker.open()"
                    placeholder="Choose a date" (dateChange)="trialdDateValueChange($event.value)">
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-icon matSuffix class="clickable icon_24" (click)="picker.open()" svgIcon="down_arrow"></mat-icon>
                  <mat-label>Trial end date</mat-label>
                </mat-form-field>


              </form>

              <div class="fx-col fx-width-40 fx-width-100-sm" style="padding-top: 20px;">
                Organisation trial end date
              </div>

            </div>
          </div>
        </div>
      </div>




      <ng-container *ngIf="organisationProfile?.is_pipxt_enabled">

        <app-org-pbx-migration-panel-panel [organisation_id]="organisation_id"
          [state]="organisationProfile.state"></app-org-pbx-migration-panel-panel>
      </ng-container>

      <!-- //shared Directories -->
      <app-org-directories-panel [organisation_id]="organisation_id" [viewAs]="viewAs"
        [is_pipxt_enabled]="organisationProfile?.is_pipxt_enabled"></app-org-directories-panel>

    </div>


  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>