/* eslint-disable max-len */
import { environment } from '../../../environments/environment';
import { Component, OnInit, isDevMode } from '@angular/core';
import { AuthService } from '@app/services/auth-service/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { ModalService } from '@app/services/modal-service/modal.service';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';

//normalizing number
import { AppTitleService } from '@app/services/helpers/update-title.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { CookieService } from 'ngx-cookie-service'
import { fadeInOnEnterAnimation, fadeInRightOnEnterAnimation, fadeInLeftOnEnterAnimation, slideInUpOnEnterAnimation, slideInUpAnimation, slideInDownAnimation, slideOutRightAnimation, slideOutRightOnLeaveAnimation, slideOutLeftAnimation, slideOutLeftOnLeaveAnimation, slideInRightOnEnterAnimation, fadeOutOnLeaveAnimation, fadeOutAnimation } from 'angular-animations';

@Component({
    selector: 'app-home-page',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ]),
        trigger('fadeInOut', [
            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({ opacity: 1 })),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({ opacity: 0 }),
                animate(600)
            ]),
            // fade out when destroyed. this could also be written as transition('void => *')
            // transition(':leave',
            //     animate(100, style({ opacity: 0 })))
        ]),
        slideInUpOnEnterAnimation(),
        fadeInLeftOnEnterAnimation({ anchor: 'enter' }),
        fadeInOnEnterAnimation({ anchor: 'onLoadEnter', duration: 800, delay: 0 }),
        fadeOutOnLeaveAnimation({ anchor: 'leave', duration: 500, delay: 0 })

    ]
})
export class HomeComponent implements OnInit {

    public isDev: boolean;
    public appversion: string = environment.appversion;

    constructor(
        public dialog: MatDialog,
        private modalService: ModalService,
        public auth: AuthService,
        public router: Router,
        public route: ActivatedRoute,
        private appTitleService: AppTitleService,
        private cookieService: CookieService,
    ) {
        this.route.queryParams.subscribe(params => {
            if (params['signup'] === 'true') {
                this.navigateByUrl('/signup')
            } else {
                this.navigateByUrl('/login')
            }
        });

    }


    ngOnInit() {
    }

    ngAfterViewInit() {
    }


    navigateByUrl(url) {
        this.router.navigateByUrl(url);
    }


    continueToPortal(): void {
        console.log('[home].continueToPortal()');
        if (this.auth.isAuthenticated()) {
            this.navigateByUrl('/redirect');
        } else {
            sessionStorage.setItem('redirect_url', '/redirect');
            this.router.navigateByUrl('/');
            // this.auth.login();
        }
    }

}
