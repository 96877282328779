import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { BaseUrl } from './return-base-url..service';
import { HttpProxy } from './http-proxy.service';
import {Auth0UserManagement} from './auth0-user-management';
//If Shared module is not used then need to declare service as provider in app module


@NgModule({
    // declarations: [],
    // exports: [],
    imports: [
        HttpClientModule
    ],
    providers: [
        BaseUrl,
        HttpProxy,
        Auth0UserManagement
    ],
})
export class SharedModule {
}

//only used in limited places