<div class="hover-to-show-copy fx-row fx-justify-start fx-align-center">


  <div class="avatar-container-38" *ngIf="number_id">
    <div *ngIf="!is_extensionless" matTooltip="Extension"  class="avatar-icon-background fx-row fx-center">
      <mat-icon *ngIf="!is_extensionless" style="fill: white;height: 22px;width: 22px;" svgIcon="connect"></mat-icon>
    </div>

    <div *ngIf="is_extensionless" matTooltip="Extensionless" class="avatar-icon-background yellow-background fx-row fx-center">
      <mat-icon *ngIf="is_extensionless" style="fill: white;height: 22px;width: 22px;" svgIcon="link"></mat-icon>
    </div>

  </div>


  <div class="fx-col" style="padding-left:12px;">

    <div style="color: var(--c-url); white-space: nowrap;">

      <span (click)="openExtensionProfile(number_id, viewAs, organisation_id)"
        class="clickable overflow-elipsis max-width-name"
        style="color: var(--c-url)">{{number_id | normalizeToInternational}}</span>

      <mat-icon *ngIf="is_blocked" style="color: var(--c-error);vertical-align: middle; margin-left:4px;"
        class="icon-14" svgIcon="block" matTooltip="This number is blocked"></mat-icon>

      <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(number_id)" class="clickable copy-icon"
        matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="Copy">
      </mat-icon>

    </div>

    <div *ngIf="pbx_platform_id">
      <span style="max-width: 230px;" class="max-width-name overflow-elipsis" style="opacity: 0.6;font-size:0.9em;"
        [matTooltip]="pbx_platform_id">{{pbx_platform_id}}&nbsp;</span>
    </div>

  </div>
