import { environment } from "@src/environments/environment";
export class SideNavItem {
    type: 'link' | 'menu';
    title: string;
    svgIcon: string;
    route: string;
    variant: string[];
    showIfRole: string[];
    isStartsExpanded?: boolean;
    child?: any[];
    customClass?: string;
}

export const sideNavMenuConfignew: SideNavItem[] = [
    {
        type: 'link',
        title: 'Team',
        svgIcon: 'teams',
        route: '/manage/users',
        variant: ['pipmobile', 'pipxt'],
        showIfRole: ['isAdmin', 'user'],
    },
    {
        type: 'link',
        title: 'Numbers',
        svgIcon: 'pip-mobile',
        route: '/manage/numbers',
        variant: ['pipmobile'],
        showIfRole: ['isAdmin', 'user'],
    },
    {
        type: 'link',
        title: 'Extensions',
        svgIcon: 'pip-xt',
        route: '/manage/pipoffice',
        variant: ['pipxt'],
        showIfRole: ['isAdmin', 'user'],
    },
    {
        type: 'link',
        title: 'Licences',
        svgIcon: 'key',
        route: '/manage/licenses',
        variant: ['pipmobile'],
        showIfRole: ['isAdmin'],
    },
    {
        type: 'link',
        title: 'Billing',
        svgIcon: 'billing',
        route: '/manage/billing',
        variant: ['pipmobile'],
        showIfRole: ['isAdmin'],
    },
    {
        type: 'link',
        title: 'Usage',
        svgIcon: 'usage',
        route: '/manage/usage',
        variant: ['pipmobile'],
        showIfRole: ['isAdmin'],
    },
    {
        type: 'link',
        title: 'Upgrade now',
        svgIcon: 'checkout',
        route: '',
        variant: ['pipmobile'],
        showIfRole: ['isAdmin'],
        customClass: 'upgrade-link'
    },
    {
        type: 'link',
        title: 'Dashboard',
        svgIcon: 'dashboard',
        route: '/admin/dashboard',
        variant: [],
        showIfRole: ['sysAdmin'],
    },
    {
        type: 'menu',
        title: 'Search',
        svgIcon: 'search',
        isStartsExpanded: true,
        route: '',
        variant: [],
        showIfRole: ['sysAdmin'],
        child: [
            {title: 'Find', route: '/admin/find',   showIfRole: ['sysAdmin']},
            {title: 'Organisations', route: '/admin/organisations',   showIfRole: ['sysAdmin']},
            {title: 'DDI', route: '/admin/ddi',   showIfRole: ['sysAdmin']},
            {title: 'Users', route: '/admin/users',   showIfRole: ['sysAdmin']},
            {title: 'Users [Detached]', route: '/admin/users-detached',   showIfRole: ['sysAdmin']},
            {title: 'eSIMs', route: '/admin/esims',   showIfRole: ['sysAdmin']}
        ]
    },
    {
        type: 'menu',
        title: 'Build',
        svgIcon: 'build',
        route: ``,
        variant: [],
        showIfRole: ['sysAdmin'],
        child: [
            {title: 'General', route: '/admin/build/general',   showIfRole: ['sysAdmin']},
            {title: 'Convert To Extension', route: '/admin/build/convert-to-extension',   showIfRole: ['sysAdmin']},
        ]
    },
    {
        type: 'link',
        title: 'Trial Extensions',
        svgIcon: 'connect',
        route: `/admin/organisation/trialextensions`,
        variant: [],
        showIfRole: ['sysAdmin']
    },
    {
        type: 'menu',
        title: 'Billing',
        svgIcon: 'billing',
        route: '',
        variant: [],
        showIfRole: ['sysAdmin'],
        child: [
            {title: 'Licences', route: '/admin/licenses',   showIfRole: ['sysAdmin']},
            {title: 'Invoices', route: '/admin/invoices',   showIfRole: ['sysAdmin']},
        ]
    },
    {
        type: 'menu',
        title: 'Reports',
        svgIcon: 'report',
        route: '',
        variant: [],
        showIfRole: ['sysAdmin'],
        child: [
            {title: 'Invitations', route: '/admin/reports/invitations',   showIfRole: ['sysAdmin']},
            {title: 'Org Signup', route: '/admin/reports/signups',   showIfRole: ['sysAdmin']},
            {title: 'Expo Signup', route: '/admin/reports/expo-signups',   showIfRole: ['sysAdmin']},
        ]
    },
    {
        type: 'link',
        title: 'Expo Signup Form',
        svgIcon: 'dashboard',
        route: '/expo-signup',
        variant: [],
        showIfRole: ['sysAdmin']
    }
]


// {
//     type: 'menu',
//     title: 'Numbers',
//     svgIcon: 'numbers',
//     route: '',
//     variant: ['pipmobile'],
//     showIfRole: ['isAdmin', 'user'],
//     child: [
//         {title: 'Link1', route: '/manage/numbers',   showIfRole: ['isAdmin', 'user']},
//         {title: 'Link2', route: '/manage/numbers',   showIfRole: ['isAdmin', 'user']}
//     ]
// }
// {
//   label: 'Billing',
//   matIcon: 'attach_money',
//   route: '/manage/billing',
// },


//add Id
//add type: parent | child
//parent:id
//child: id  or use route as the id? maybe overlap?


// {
//     type: 'menu',
//     title: 'Usage',
//     svgIcon: 'usage',
//     route: '',
//     variant: [],
//     showIfRole: ['sysAdmin'],
//     child: [
//         {title: 'Call', route: '/admin/usage/call',   showIfRole: ['sysAdmin']},
//         {title: 'SMS', route: '/admin/usage/sms',   showIfRole: ['sysAdmin']},
//     ]
// },

// {
//     type: 'link',
//     title: 'Search (beta)',
//     svgIcon: 'search',
//     route: '/admin/advanced-search',
//     variant: [],
//     showIfRole: ['sysAdmin'],
// },

// {
//     type: 'link',
//     title: 'Numbers',
//     svgIcon: 'pip-mobile',
//     route: '/admin/pipnumbers',
//     variant: [],
//     showIfRole: ['sysAdmin'],
// },
// {
//     type: 'link',
//     title: 'Extensions',
//     svgIcon: 'connect',
//     route: '/admin/pipoffice',
//     variant: [],
//     showIfRole: ['sysAdmin'],
// },
// {
//     type: 'link',
//     title: 'Features',
//     svgIcon: 'connect',
//     route: '/manage/enhancedfeatures',
//     variant: ['pipmobile', 'pipxt'],
//     showIfRole: ['csAdmin'],
// },
// {
//     type: 'link',
//     title: 'Licences',
//     svgIcon: 'key',
//     route: '/admin/licenses',
//     variant: [],
//     showIfRole: ['sysAdmin'],
// },
// {
//     type: 'link',
//     title: 'Invoices',
//     svgIcon: 'billing',
//     route: '/admin/invoices',
//     variant: [],
//     showIfRole: ['sysAdmin'],
// },
