import {Component, TemplateRef, ViewChild, Inject, Input, Output, EventEmitter} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
import { map, tap, filter, catchError, mergeMap, finalize, distinctUntilChanged, last } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';




@Component({
    selector: 'cc-floating-card',
    templateUrl: './cc-floating-card.component.html',
    styleUrls: ['./cc-floating-card.component.scss']
})
export class CCFloatingCardComponent {


    @Input() cardSize = 'normal'; // Default width is 580px // normal, large is 1080px
    @Input() showCopyRight = false;
    @Input() showBrandLogo = false;
    @Input() noInnerContainer = false; // this will remove the inner padding containers and allow component to set its own

    public copyrightMessage = environment.copyrightMessage;

    isFullScreen = false;

    constructor(
        private router: Router,
        private ref: ChangeDetectorRef,
        private breakpointObserver: BreakpointObserver
    ) {

    }

    ngOnInit() {
        this.checkBreakpoints();
    }

    checkBreakpoints(): void {
        const customBreakpoints = {
            medium: '(max-width: 580px)',
            large: '(min-width: 580px) and (max-width: 1080px)',
            xLarge: '(min-width: 1081px)'
        };

        this.breakpointObserver.observe([
            customBreakpoints.medium, customBreakpoints.large, customBreakpoints.xLarge
        ]).subscribe(result => {
            if (result.matches) {
                if (result.breakpoints[customBreakpoints.medium]) {
                    console.log('medium');
                    //if cardSize is normal and breakpoint is medium then set isFullScreen to true
                    if (this.cardSize === 'normal') {
                        this.isFullScreen = true;
                    }
                }
                if (result.breakpoints[customBreakpoints.large]) {
                    //if card is large and breakpoint is large then set isFullScreen to true
                    if (this.cardSize === 'large') {
                        this.isFullScreen = true;
                    } else {
                        this.isFullScreen = false;
                    }
                }
                if (result.breakpoints[customBreakpoints.xLarge]) {
                    this.isFullScreen = false;
                }

            }
        });
    }

}

