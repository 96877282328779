import { Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import * as decode from 'jwt-decode';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth-service/auth.service';
import { CookieService } from 'ngx-cookie-service';
import * as jwt_decode from "jwt-decode";


@Injectable()
export class AppInsightService {



    // https://medium.com/@umashankar.itn/angular-apps-monitoring-using-azure-application-insights-e25a55fe49d5

    private config: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: environment.app_insights_instrumentationkey
    };


    constructor(private authService: AuthService, private cookieService: CookieService) {

        if (!AppInsights.config) {
            AppInsights.downloadAndSetup(this.config);
            // If you want to log UserId in metrices
            // Logic to get logged in User
            if (this.cookieService.check('id_token')) {
                const token = this.cookieService.get('id_token')
                const decoded = this.getDecodedToken(token);
                const userId = decoded?.sub;
                userId ? AppInsights.setAuthenticatedUserContext(userId.replace('auth0|', '')) : null;
            }
        }

    }

    logPageView(
        name?: string,
        url?: string,
        properties?: any,
        measurements?: any,
        duration?: number
    ) {
        AppInsights.trackPageView(name, url, properties, measurements, duration);
    }
    logEvent(name: string, properties?: any, measurements?: any) {
        AppInsights.trackEvent(name, properties, measurements);
    }
    logException(
        exception: Error,
        handledAt?: string,
        properties?: any,
        measurements?: any
    ) {
        AppInsights.trackException(exception, handledAt, properties, measurements);
    }
    logTrace(message: string, properties?: any, severityLevel?: any) {
        AppInsights.trackTrace(message, properties, severityLevel);
    }

    logUserContext(userid: string, accountId?: string, storeIncookie?: boolean) {
        AppInsights.setAuthenticatedUserContext(userid, accountId, storeIncookie);
    }

    private getDecodedToken(token?: string): any {
        if (!token) { return null; }
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }
}
