import { environment } from '../../environments/environment';
const namespace = `https://${environment.auth0_domain}`



interface IUserProfile {
    id: string,
    first_name: string,
    last_name: string,
    email: string,
    mobile: string,
    notes: string,
    dnd: boolean,
    is_blocked: boolean,
    is_name_set: boolean,
    notification_id: string,
    os: string,
    os_version: string,
    carrier: string,
    pipcall_version: string,
    pipcall_build: string,
    installation_id: string,
    model: string,
    dialmode: string,
    device_created_at: string,
    device_modified_at: string,
    organisation_list: Map<string, string>;
}

interface IusersOrganisations {
    id: string,
    name: string,
    notes: string;
    owner_user_id: string
    is_blocked: boolean;
    license_id: string;
    licensed_at: string;
    is_license_unpaid: boolean;
    allow_renewal: boolean;
    next_renewal_date: string;
    can_dial_international: boolean;
    can_use_sms: boolean;
    removal_requested: boolean;
    ddi_list: Map<string, string>;
}

interface IOrgUserDdiList {
    number: string,
    label: string,
    pip_variant: string,
    sip_extension_number: string,
    sip_extension_cli: string
}

export class OrgUserDdiList implements IOrgUserDdiList {
    number: string;
    label: string;
    pip_variant: string;
    sip_extension_number: string;
    sip_extension_cli: string;
}

export class UserProvisioningRequest {
    name: string;
    email: string;
    mobile: string;
}

export class OrganisationUser implements IusersOrganisations {
    id: string;
    name: string;
    notes: string;
    owner_user_id: string;
    isAdmin: boolean;
    is_blocked: boolean;
    license_id: string;
    licensed_at: string;
    is_license_unpaid: boolean;
    can_dial_international: boolean;
    allow_renewal: boolean;
    next_renewal_date: string;
    can_use_sms: boolean;
    removal_requested: boolean;
    ddi_list: Map<string, string>;
    is_pipxt_enabled?: boolean;
    is_pipmobile_enabled?: boolean;
}
//is enabled required to render pipnumber views

export class UserProfile implements IUserProfile {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    notes: string;
    dnd: boolean;
    is_blocked: boolean;
    is_name_set: boolean;
    notification_id: string;
    os: string;
    os_version: string;
    carrier: string;
    pipcall_version: string;
    pipcall_build: string;
    installation_id: string;
    device_created_at: string;
    device_modified_at: string;
    model: string;
    dialmode: string;
    organisation_list: Map<string, string>;
}

// ================================================user query
export class UserQueryRequest {
    organisation_id: string;
    organisation_state_list: string[];
    search_string: string;
    page_size = 500;
    page_number = 0;
}

export class UserQueryResponse {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    dnd: boolean;
    is_blocked: boolean;
    notification_id: string;
    modes: string;
    created_at: Date;
    organisation_id: string;
    organisation_name: string;
    organisation_state: string;
    os: string;
    os_version: string;
    carrier: String;
    pipcall_version: string;
    pipcall_build: string;
    installation_id: string;
    device_created_at: string;
    device_modified_at: string;
    notes: string;
}
// =================================================user put update
export class UserDetailUpdate {
    first_name: string
    last_name: string
    organisation_id: string;
    license_id: string;
    can_dial_international: boolean;
    can_use_sms: boolean;
}
