import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter , ElementRef} from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSortable, MatSnackBar, MatDialog, MatSort, MatDialogRef, MAT_DIALOG_DATA, MatTabChangeEvent, MatSelectionList, MatList, Sort } from '@app/material/material-essentials.module';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, startWith, distinctUntilChanged } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AuthService} from '@app/services/auth-service/auth.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { Article} from '@app/services/pipcall/content.service';
import { HelpCenterContentService, FAQS } from '@app/services/help-center-content.service';
import { debounceTime, tap, finalize , retry} from 'rxjs/operators';


export class Breadcrumb {
    label: string;
    url: string;
    parent: string;
}

@Component({
    selector: 'app-help-center-search',
    templateUrl: './help-center-search.component.html',
    styleUrls: ['./help-center-search.component.scss']
})
export class HelpCenterSearchComponent implements OnInit, OnDestroy {


    @Input() viewAs: string; // sysAdmin ,user, isAdmin
    @Output() selectedArticle = new EventEmitter<Article>(); //output to parent

    public dataSource: MatTableDataSource<Article>;
    _destroy$ = new Subject<void>();

    public pageStatus = {
        isLoading: true
    }

    //filter rules
    public filteredValues = {
        isSysAdmin: null,
    };

    //subscriptions
    private latestFaqs: Subscription;
    // @ViewChild("searchinput") searchinput: ElementRef;
    @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoComplete') matAutocomplete: MatAutocomplete;

    searchForm: FormGroup;
    userInputCtrl = new FormControl();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private helpCenterContentService: HelpCenterContentService,
        private fb: FormBuilder,
        private appInsightService: AppInsightService
    ) {
        this.dataSource = new MatTableDataSource();
        this.dataSource.filterPredicate = this.customFilterPredicate();
        this.dataSource.data = [];
        this.searchForm = this.fb.group({
            userInputCtrl: ''
        })
    }

    ngOnInit() {

        this.latestFaqs = this.helpCenterContentService.faqsAsObservable.subscribe((resp) => {
            this.dataSource.data = resp?.articles;
            this.pageStatus.isLoading = false;
        })

        this.searchForm
            .get('userInputCtrl')
            .valueChanges
            .pipe(
                debounceTime(700),
                tap(() => this.pageStatus.isLoading = true),
            )
            .subscribe((resp) => {
                console.log("value change response", resp);
                this.appInsightService.logEvent('Helpcentre: search', { 'search_term': resp });
                this.applyFilter(resp);
            }, (err) => {
            // this.filteredItems = [];
                this.pageStatus.isLoading = false;
                console.log("ERROR", err);
            });

    }
    applyFilter(searchString?: string) {
        this.dataSource.filter = JSON.stringify(this.filteredValues);

        setTimeout(() => {
            this.pageStatus.isLoading = false;
        }, 300);
    }

    customFilterPredicate() {
        const myFilterPredicate = (data: Article, filter: string): boolean => {


            let isGlobalMatch = !this.searchForm.get('userInputCtrl').value;

            if ( this.searchForm.get('userInputCtrl').value) {
                // search all text fields for string match
                isGlobalMatch = this.searchGlobalMatch([data?.title, data?.description, data?.tags, data?.RowKey], this.searchForm.get('userInputCtrl').value, data?.tags);

            } else {

                return false}

            if (!isGlobalMatch) {
                //actiate only on search
                return; }


            // apply all custom filters ( if any )
            const filteredValues = JSON.parse(filter);


            //return true to declare a match
            return true;
        }
        return myFilterPredicate;
    }



    searchGlobalMatch(fieldsToSearch: any[], searchString, tags?: string) {
        //this search will break up the search string into an array of individual words. It will ignore common words like can , i do.
        //it will check each requested field for a match, all words must match one of the fields to return a match

        searchString = searchString?.toLowerCase();
        let isGlobalMatch = false;

        //return positive if match found for all search strings,  on any of the fields.
        let match = false;
        //convert string into array of words, split by ,

        //return match for any matches on a tag
        const _tags: string[] = tags ? tags?.split(',') : [];
        _tags.includes('test')



        fieldsToSearch.forEach((field, i) => {

            if (field != null && field !== '') {


                if (field?.toString().trim().toLowerCase().indexOf(searchString) !== -1) {
                    return match = true;
                }


                //alternative Smart search, search for each word in the search string. Must match all words
                const ignoreTerms = ['can', 'do', 'I', 'how', 'what', 'my', 'what', 'is', 'when'];
                const searchStringArray = searchString.split(" ");
                let matchAllStrings = true;
                searchStringArray.forEach((searchValue) => {
                    if (ignoreTerms.includes( searchValue.trim() )) { return; };

                    if (_tags.includes(searchValue?.trim())) { return; } //return match for any matches on a tag

                    if (field?.toString().trim().toLowerCase().indexOf(searchValue) === -1) {
                        // console.log("=================MATCH FAILED---[", field, ", searchValue [", searchValue, "]");
                        matchAllStrings = false;
                    }
                });
                matchAllStrings ? match = true : '';

            }
        })

        isGlobalMatch = match;
        return isGlobalMatch;
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const item = this.dataSource.data.find(data => data.RowKey === event.option?.id);
        this.selectedArticle.emit(item);
    }

    public clearValues() {
        this.searchForm.get('userInputCtrl').setValue('', { emitEvent: false });
        // this._selectedItem.emit('');
    }

    ngOnDestroy() {
        this.latestFaqs ? this.latestFaqs.unsubscribe() : '';
    }
}
