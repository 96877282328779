import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule} from '../../material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


//declarations
import { TabTitleLinedComponent } from './tab-title-lined/tab-title-lined.component'
import { TabSectionActionComponent } from './tab-section-action/tab-section-action.component'
import { SGridItemComponent} from './s-grid-item/s-grid-item.component'
import { SGridItemsContainerComponent } from './s-grid-items-container/s-grid-item-container.component'
import { BtnToggleComponent } from './btn-style-toggle/btn-toggle.component'

@NgModule({
    declarations: [
        TabTitleLinedComponent,
        TabSectionActionComponent,
        SGridItemComponent,
        SGridItemsContainerComponent,
        BtnToggleComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule
    ],
    exports: [
        TabTitleLinedComponent,
        TabSectionActionComponent,
        SGridItemComponent,
        SGridItemsContainerComponent,
        BtnToggleComponent
    ]
})
export class UniversalComponentsModule { }
