import { Injectable } from '@angular/core';
// import { AuthService } from '@app/services/auth-service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { switchMap, filter, catchError, debounceTime, tap, finalize, takeUntil, take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {
    HttpErrorResponse,
    HttpResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { LoaderService } from './loader.service';
import { environment } from '@src/environments/environment';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];
    private baseDomain  = environment.ApiBaseUrl;
    constructor(private loaderService: LoaderService, private router: Router, private cookieService: CookieService,  private activatedRoute: ActivatedRoute) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);

        }
        // console.log(i, this.requests.length);
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    private routeHasGuard(): boolean {
        const currentRoute = this.router.routerState.snapshot.root;
        return this.checkGuardInRoute(currentRoute);
    }
    private checkGuardInRoute(route: any): boolean {
        if (route.data?.['canActivate']) {
            return true;
        }
        if (route.children) {
            for (const child of route.children) {
                if (this.checkGuardInRoute(child)) {
                    return true;
                }
            }
        }
        return false;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(req);
        this.loaderService.isLoading.next(true);
        const hasGuard = this.routeHasGuard(); //check if this is a route that has a guard

        if (!req.url.startsWith(this.baseDomain)) {
            return next.handle(req); // Pass through the request without additional logic
        }

        return new Observable(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            console.log('interceptor for URL:', req.url);
                            if (event.status === 401 && hasGuard) {

                                console.log('|||=loader.interceptor -||| event.status = 401');

                                if (this.cookieService.check('access_token') === null && this.cookieService.check('refresh_token') === null) {
                                    sessionStorage.removeItem('redirect_url');
                                    this.router.navigateByUrl('/?message=session_expired');
                                } else {
                                    console.log('need to refresh token');
                                    //should be able to refresh token here (consider sredirecting somewhere to refresh token); Use redirect url (?);
                                }

                            }
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    error => {
                        this.removeRequest(req);
                        observer.error(error);
                        this.loaderService.isLoading.next(false); // Stop loader on error
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                        if (this.requests.length === 0) {
                            this.loaderService.isLoading.next(false); // Stop loader if no pending requests
                        }
                    }
                );

            // Teardown logic
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }

    //original implementation changed as on 03-2024
    // _intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     this.requests.push(req);
    //     this.loaderService.isLoading.next(true);

    //     return Observable.create(observer => {
    //         const subscription = next.handle(req)
    //             .subscribe(
    //                 event => {
    //                     if (event instanceof HttpResponse) {

    //                         //intercept http request and expose contents in event variable. handle events here. can use window.location.reload(true); where refresh needed during downtime.
    //                         // console.log("INTERRCEPT HTTP EVENT:", event);

    //                         if (event.status === 401) {
    //                             console.log('|||=loader.interceptor -||| event.status = 401.:');
    //                             //handle unathuroized event
    //                             if (!this.auth.isAuthenticated) {
    //                                 console.log('|||=loader.interceptor -||| logout and redirect====:');
    //                                 sessionStorage.removeItem('redirect_url');
    //                                 this.router.navigateByUrl('/');
    //                             }
    //                         }

    //                         this.removeRequest(req);
    //                         observer.next(event);
    //                     }
    //                 },
    //                 err => {
    //                     this.removeRequest(req); observer.error(err); },
    //                 () => { this.removeRequest(req); observer.complete(); });
    //         // teardown logic in case of cancelled requests
    //         return () => {
    //             this.removeRequest(req);
    //             subscription.unsubscribe();
    //         };
    //     });
    // }




}
