<div class="pie-chart-container">
    <svg class="pie-chart" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
        <!-- Define a gradient -->
        <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" [attr.stop-color]="gradientColors.start" />
                <stop offset="100%" [attr.stop-color]="gradientColors.end" />
              </linearGradient>
        </defs>
        

        <!-- Background Circle -->
        <circle class="background" cx="60" cy="60" r="50" stroke="#e0e0e0" stroke-width="15" fill="none" />
        <!-- Foreground Circle (used data) -->
        <circle class="foreground" cx="60" cy="60" r="50" [attr.stroke-dasharray]="circumference"
            [attr.stroke-dashoffset]="strokeDashoffset" stroke="url(#gradient)" stroke-width="15" fill="none"
            stroke-linecap="butt" style="transform: rotate(-90deg); transform-origin: 50% 50%;" />
    </svg>
    <!-- Center Text -->
    <div *ngIf="!isEsimUsage" class="center-text">{{ remainingPercentage | number: '1.0-1' }}%</div>
    <div *ngIf="isEsimUsage" class="center-text fx-col fx-gap-1">
        <div *ngIf="!chartValue" class="usage-data-value">0 GB</div>
        <div *ngIf="chartValue" class="usage-data-value">
            <span [innerHTML]="chartValue | byteFormat"></span>
        </div>
        <div *ngIf="esim_expires_at" class="usage-timeleft-value">{{getRemainingTime(esim_expires_at)}}</div>
        <!-- {{ remainingPercentage | number: '1.0-1' }}% -->
    </div>
    <!-- <div  class="center-text">

    </div> -->
</div>

