<div class="card-wrapper prefer-light-mode">
  <ng-container *ngIf="pageStatus.isSuccess === false && pageStatus.notValid === false">
    <h2 class="title-font">Verify your email</h2>
    <p class="subtitle-top">A verification code has been sent to {{signup_request_email}}, Please enter the code below
      to proceed</p>

    <div>
      <form [formGroup]="verificationForm"
        [ngClass]="{'readonly-block': pageStatus.isLoading || pageStatus.isSubmitting}">
        <div style="margin-bottom: 2rem;">

          <code-input [isCodeHidden]="false" [isFocusingOnLastByClickIfFilled]="true" [codeLength]="6"
            [initialFocusField]="0" [disabled]="pageStatus.isSubmitting" (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)">
          </code-input>
        </div>
      </form>

      <div class="error-message" *ngIf="pageStatus?.isError">{{pageStatus?.errorMessage}}</div>
    </div>


    <!-- <div class="tc-container">By continuing, you agree to the <a href="">Terms & Conditions</a> and <a href="https://www.pipcall.com/downloads/customer-privacy-policy">Privacy Policy</a>.</div> -->
    <div class="submit-container">
      <button (click)="cancelVerification()" mat-flat-button tabindex="-1"
        [style.visibility]="!pageStatus?.isSubmitting ? 'visible' : 'hidden'" style="font-size: 1rem;">Cancel</button>
      <button mat-flat-button color="accent" [disabled]="!verificationForm.valid || pageStatus?.isSubmitting">
        <ng-container *ngIf="!pageStatus.isSubmitting; else spinner">Verify Email</ng-container>
      </button>
    </div>

    <div class="support-email"><a href="mailto:support@pipcall.com" target="_blank">support&#64;pipcall.com</a></div>

  </ng-container>

  <ng-container *ngIf="pageStatus.isSuccess === true && pageStatus.notValid === false">
    <h2 class="title-font" style="margin-bottom: 4rem;">Success</h2>
    <div class="fx-row fx-justify-center fx-align-center">
      <div class="loader-wave"></div>
    </div>
    <p class="subtitle-top">Your acount has been created, you will be redirected in just a moment...</p>
  </ng-container>

  <ng-container *ngIf="pageStatus.notValid === true">
    <h2 class="title-font">Oops, something went wrong</h2>
    <p class="subtitle-top">The request is not valid or has expired. contact us at <a href="mailto:support@pipcall.com"
        target="_blank">support&#64;pipcall.com</a> if you are having trouble. </p>
  </ng-container>
</div>

<ng-template #spinner>
  <div>
    <span class="loading-icon">
      <div class="loader-spinner"></div>
    </span>
  </div>
</ng-template>