//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';

import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { Subject, Subscription, Observable, throwError } from "rxjs";

import { UserProfile } from '@app/models/user-profile.model';
import { UserService } from '@app/services/pipcall/user.service';
import { UserDetailUpdate } from '@app/models/user-profile.model';

import { ValidateMobileNotTaken, ValidateBusinessNumberNotMobile } from '@app/validators/async-mobile.validator';
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { SignupService } from '@app/services/pipcall/signup.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';

import { LicenseModel, Licensedata } from '@app/models/license.model';
import { PaymentsService, } from '@app/services/pipcall/payments.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { ModalService } from '@app/services/modal-service/modal.service';
import { AdminUpgradeToPipuserModuleComponent } from '@app/components/modals/admin-upgrade-to-pipuser-modal/admin-upgrade-to-pipuser-modal.component';
import { AdminExtensionInvitationComponent } from '@app/components/modals/admin-extension-invitation-modal/admin-extension-invitation-modal.component';
import { UpdateUserProfilePictureModalComponent} from '@app/components/modals/update-userprofile-picture-modal/update-userprofile-picture-modal.component';
@Component({
    selector: 'app-user-profile-tabs-overview',
    templateUrl: './user-profile-overview.component.html',
    styleUrls: ['./user-profile-overview.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class UserProfileTabOverviewComponent {


    @Input() viewAs: string;
    @Input() userProfile: UserProfile;
    @Input() user_id: string; //require either userID or profile
    @Input() organisation_id: string; //required
    @Input() organisationProfile: OrganisationModel; //required

    @Output() updateParent = new EventEmitter();
    @Output() updateParentModal = new EventEmitter();

    activeUserId: string;

    noContent = false;
    _destroy$ = new Subject<void>();
    public sharedDataSubscription: Subscription;

    pageStatus = {
        isSubmitting: false,
        isLoading: true,
        isLoadingLicence: true
    }

    // public organisationProfile: OrganisationModel;

    //forms
    userNameForm: FormGroup;
    userEmailForm: FormGroup;
    userHandsetForm: FormGroup;

    //licence
    currentLicense: LicenseModel;
    activeLicenseList: LicenseModel[];

    public trialDays: number;

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private userService: UserService,
        private signupService: SignupService,
        private normalizeNumberService: NormalizeNumberService,
        private ref: ChangeDetectorRef,
        private paymentsService: PaymentsService,
        private licenseDataService: LicenseDataService,
        private authService: AuthService,
        private appInsightService: AppInsightService,
        private modalService: ModalService
    ) {

        // const regex = new RegExp('^[a-zA-Z0-9\ \-]+$');
        const regex = new RegExp('^[a-zA-Z]+(([\'-][a-zA-Z ])?[a-zA-Z ]*)*$');
        this.activeLicenseList = new Array<LicenseModel>();
        this.sharedDataSubscription = this.licenseDataService.activeLicenseListAsObservable.subscribe((activeLicenseList) => {
            console.log("LICENCE RESPONSE", activeLicenseList);
            activeLicenseList ? this.activeLicenseList = activeLicenseList : null;
            if (this.userProfile ) {
                this.setCurrentLicence();
            }
            this.pageStatus.isLoadingLicence = false;
        });


        this.userNameForm = this._formBuilder.group({
            id: [{ value: '', disabled: true }, Validators.required],
            first_name: ['', {updateOn: 'change', validators: [Validators.pattern(regex), Validators.required,  Validators.minLength(1), Validators.maxLength(30), ]}],
            last_name: ['', {updateOn: 'change', validators: [Validators.pattern(regex), Validators.required,  Validators.minLength(1), Validators.maxLength(30), ]}]
        });

        this.userEmailForm = this._formBuilder.group({
            id: [{ value: '' }, Validators.required],
            email: [{value: '', disabled: true }, {updateOn: 'change', validators: [Validators.email, Validators.required ]}]
        });
        //ADD EMAIL CHECKER TO THIS. DOES IT EXIST ALREADY ?

        this.userHandsetForm = this._formBuilder.group({
            id: [{ value: '' }, Validators.required],
            mobile: [{value: '', disabled: true },  {updateOn: 'change', validators: [Validators.required, PhonenumberValidator.validateNumber], asyncValidators: [ValidateMobileNotTaken.createValidator(this.signupService)]}]
        });


        // this.organisationProfile = new OrganisationModel();

    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("CHANGES FIRED",  changes);
        (changes['userProfile']?.currentValue !== changes['userProfile']?.previousValue) &&  !changes['userProfile']?.isFirstChange() ?  this.setUserFormValues(this.userProfile) : null;
        (changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue) &&  !changes['organisationProfile']?.isFirstChange() ?   this.trialDays = this.setTrialDate(this.organisationProfile?.trial_end_date) : null;
    }



    ngOnInit() {
        this.activeUserId = this.authService.getUserId();

        if (this.userProfile) {
            // found a profile from parent
            console.log("THERE IS A IUSER PROFILE FOUND", this.userProfile);
            this.setCurrentLicence();
            this.pageStatus.isLoading = false;
            this.setUserFormValues(this.userProfile);
        }

        if ( !this.userProfile && this.user_id ) {
            // no profile from parent. get it now
            this.getDetails();
        }

        if ( !this.userProfile && !this.user_id ) {
            //No profile or ID from parent. throw error.
            this.noContent = true;
            this.pageStatus.isLoading = false;
            this.openSnackBar("There was an error geting user details", "dismiss");
        }

        if (this.organisationProfile) {
            this.trialDays = this.setTrialDate(this.organisationProfile?.trial_end_date);
            this.ref.markForCheck();
        }

    }

    setUserFormValues(userProfile: UserProfile) {
        //name form
        this.setFormValue(this.userNameForm, 'id', userProfile.id);
        userProfile?.is_name_set ? this.setFormValue(this.userNameForm, 'first_name', userProfile.first_name) : this.setFormValue(this.userNameForm, 'first_name', '');
        userProfile?.is_name_set ? this.setFormValue(this.userNameForm, 'last_name', userProfile.last_name) : this.setFormValue(this.userNameForm, 'first_name', '');

        //email form
        this.setFormValue(this.userEmailForm, 'id', userProfile.id);
        this.setFormValue(this.userEmailForm, 'email', userProfile.email);

        //handsetform
        this.setFormValue(this.userHandsetForm, 'id', userProfile.id);

        const currentmobile = userProfile.mobile ? this.normalizeNumberService.normaliseNumberWithPlus(userProfile.mobile) : '';
        this.activeUserId === userProfile?.id ? this.setFormValue(this.userHandsetForm, 'mobile', currentmobile) : this.setFormValue(this.userHandsetForm, 'mobile', '** ******' + currentmobile.slice(10));
        this.ref.detectChanges();
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    setCurrentLicence() {
        this.currentLicense = this.userProfile?.organisation_list[0]?.license_id ? this.licenseDataService.returnLicense(this.userProfile?.organisation_list[0]?.license_id) : null;
    }
    getDetails() {
        //get profile if one was not passed
        this.pageStatus.isLoading = true;

        this.userService.getUser(this.user_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .subscribe((resp) => {
                if (resp.status === 200 ) {
                    this.userProfile = resp.body;
                    this.setUserFormValues(this.userProfile);
                    this.setCurrentLicence();
                    this.pageStatus.isLoading = false;
                }
            },
            (err) => {
                console.log(err);
                this.pageStatus.isLoading = false;
                this.openSnackBar(`An error occured getting user details [${err.status}] `, "dismiss");
            })

    }

    setSettings(user: UserProfile, setInternational: boolean, setSMS: boolean) {
        console.log("update Internatial and sms permission", setInternational, setSMS, user)
        const orgid = this.organisationProfile?.id;
        const userid = user.id;

        const userDetailsUpdate = {
            can_dial_international: setInternational,
            can_use_sms: setSMS
        }

        this.appInsightService.logEvent('User permissions updated', { 'userid': userid, 'update': userDetailsUpdate });
        this.pageStatus.isSubmitting = true;

        this.submitForm(this.userService.patchUserDetails(orgid, userid, userDetailsUpdate))
    }

    patchAdminIsPipuser(org_id: string, user_id: string) {

        console.log("PATCH USR IS ADMIN");
        const data = new ModalConfirmData;
        data.title = 'Remove PiPcall licence';
        data.choice = true;
        data.content = `This will remove access to PiPcall for this user and all associated numbers, it will return to an Admin only state. You will no longer be billed for this user.`;
        data.confirmButtonLabel = 'Confirm';
        data.closeButtonLabel = 'Cancel';


        this.modalService.openConfirmModal(data, (answer: boolean) => {

            if (answer) {
                this.appInsightService.logEvent('Patch owner is pipuser', { 'userid': user_id, 'organisation_id': org_id });
                this.submitForm( this.userService.patchOrgOwnerIsPipuser(org_id, user_id))
            }
        });


    }

    removeUserFromOrg(user_id: string, name: string) {
        //in Trial
        const data = new ModalConfirmData;
        data.title = 'Permanently remove user';
        data.choice = true;
        data.content = `You are about to remove ${name} from the organisation. They will no longer have access to PiPcall. Are you sure ? `;
        data.confirmButtonLabel = 'Remove';
        data.closeButtonLabel = 'Cancel';

        // const removed_by_id = this.authService.getUserId();

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                console.log("remove user", user_id, 'for org:', this.organisation_id);
                this.submitForm(this.organisationService.deleteOrganisationUserTrial( this.organisation_id, user_id), true)

            }
        });
    }

    cancelRemoveUserFromOrg(id: string) {
        const removed_by_id = this.authService.getUserId();
        this.submitForm(this.organisationService.patchRemoveUserRequest( this.organisation_id, id, removed_by_id, false))
    }

    saveUserName(f: FormGroup) {
        console.log("SAVE USER:", f);

        if (f.valid) {
            const userUpdate: UserDetailUpdate = new UserDetailUpdate();
            userUpdate.first_name = f.value.first_name.trim();
            userUpdate.last_name = f.value.last_name.trim();
            return this.submitForm(this.userService.patchUserName(f.getRawValue().id, userUpdate));
        }
    }

    saveEmail(f: FormGroup) {
        console.log("SAVE USER:", f);

        if (f.valid) {
            return this.submitForm(this.userService.patchUserEmail(f.getRawValue().id, f.value.email));
        }
    }

    saveMobile(f: FormGroup) {
        console.log("SAVE USER:", f);

        if (f.valid) {
            return this.submitForm(this.userService.patchUserMobile(f.getRawValue().id, f.value.mobile));
        }
    }



    activatePipuser(userProfile: UserProfile) {
        const data = {
            userProfile: userProfile,
            organisationProfile:  this.organisationProfile,
            organisation_id:  this.organisationProfile?.id,
            viewAs: this.viewAs
        }

        const dialogRef = this.dialog.open(AdminUpgradeToPipuserModuleComponent, {
            panelClass: 'pipcall-modal-container',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            //refresh the view if needed
            if (result) {
                console.log("RESULT FROM MODAL:", result)

                this.triggerUpdate();
            }
        });
    }

    activatePipXTuser(userProfile: UserProfile) {
        const data = {
            userProfile: userProfile,
            organisationProfile:  this.organisationProfile,
            organisation_id:  this.organisationProfile?.id,
            viewAs: this.viewAs
        }

        const dialogRef = this.dialog.open(AdminExtensionInvitationComponent, {
            panelClass: 'pipcall-modal-container',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            //refresh the view if needed
            if (result) {
                console.log("[user-profile-overview].activatePipXTuser()", result)
                this.triggerUpdate();
            }
        });
    }


    submitForm(form: Observable<any>, closeOnSuccess?: boolean) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => {
                    this.pageStatus.isSubmitting = false,
                    this.ref.markForCheck()
                }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
                closeOnSuccess ? this.updateParentModal.emit() : null;
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    changeProfilePicture() {
        const dialogRef = this.dialog.open(UpdateUserProfilePictureModalComponent, {
            panelClass: 'pipcall-modal-container',
        });

        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                this.triggerUpdate();
            }

        });
    }

    setTrialDate(trialEndDate) {
        console.log("TRIAL DATE:", trialEndDate);
        const today = new Date();
        // const createdAt = new Date(this.orgProfile.created_at);
        const trialEnds = new Date(trialEndDate);
        trialEnds.setHours(23, 59, 59, 59);
        const diff = trialEnds.getTime() - today.getTime();
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24)) - 1; //added minus 1 to adjust for first day partial.
        console.log("TRIAL ENDS: ", trialEnds >= today ? diffDays : -1)
        this.ref.markForCheck();
        return trialEnds >= today ? diffDays : -1;
    }

    cancelForm() {
        this.setUserFormValues(this.userProfile);
    }


    requestRemoveUserFromOrg(user_id: string, name: string) {
        const data = new ModalConfirmData;
        data.title = 'Request removal of user';
        data.choice = true;
        data.content = `You are about to request to permanently remove ${name} from the organisation. Ths will go to our support team to action. Are you sure?.`;
        data.confirmButtonLabel = 'Confirm';
        data.closeButtonLabel = 'Cancel';
        data.customClass = 'red-button'

        const removed_by_id = this.authService.getUserId();
        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                console.log("remove user", user_id, 'for org:', this.organisation_id);
                this.organisationService.patchRemoveUserRequest(this.organisation_id, user_id, removed_by_id).subscribe((resp) => {
                    if (resp.status === 200 || resp.status === 201) {
                        this.triggerUpdate();
                        this.openSnackBar("Thanks, your request has been sent to our support team", "dismiss");
                        return;
                    }
                    this.openSnackBar("sorry something went wrong with this request", "dismiss");
                }, (err) => {
                    console.log(err);
                    this.openSnackBar("sorry something went wrong with this request", "dismiss");
                })

            }
        });
    }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    triggerUpdate() {
        console.log("TRIGGER UPDATE");
        this.updateParent.emit(true);
        //need to check this is updating when invitation is sent
        this.ref.detectChanges();
    }


    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
