import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';

//create modal components
import {TermsAndConditionsComponent} from './terms-and-conditions-modal.component';


@NgModule({
    imports: [
        MaterialModule,
        CommonModule
    ],
    declarations: [TermsAndConditionsComponent],
    exports: [TermsAndConditionsComponent],
    providers: [],
    entryComponents: [TermsAndConditionsComponent]
})
export class TermsAndConditionsModalModule { }
