import { Injectable, ModuleWithProviders, NgModule, ApplicationRef } from '@angular/core';
import { of } from 'rxjs/observable/of';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { debounce, debounceTime, tap, finalize, first, take, switchMap, } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { UserProfile } from '@app/models/user-profile.model';
import { UserService } from './pipcall/user.service';
import { AuthService, UserIdentification } from '@app/services/auth-service/auth.service';

export { UserIdentification };
export { UserProfile };

@Injectable({
    providedIn: 'root'
})
export class UserSessionService {

    public impersonateUserId: string;
    public userProfileAsObservable: Observable<any>;
    private userProfile = new BehaviorSubject<UserProfile>(null);
    public useridentification: UserIdentification;

    private sessionStatus =
    {
        isGettingUser: false
    }
    constructor(
        private router: Router,
        private cookieService: CookieService,
        private userService: UserService,
        private authService: AuthService
    ) {
        this.userProfile.next(null);
        this.userProfileAsObservable = this.userProfile.asObservable().filter(resp => !!resp);
        this.startSetUserSession();
    }

    public startSetUserSession() {
        this.authService.userIdentificationAsObservable
            .subscribe((resp) => {
                console.log('[user-session-service] userIdentificationAsObservable has recieved an update', resp);
                this.useridentification = resp;
                if (this.authService.validAccessTokenExists()) {
                    this.getSessionUserProfile();
                }
            });
    }
    public updateUserProfile() {
        //force an update
        this.getSessionUserProfile();
    }

    private getSessionUserProfile() {
        const user_id = this.authService.getUserId();
        if ( this.sessionStatus.isGettingUser === true) { return; }
        console.log('[user-session-service getting user profile for id]', user_id);

        this.userService.getUser(user_id)
            .take(1)
            .subscribe((resp) => {
                this.userProfile.next(resp?.body);
            }, (err) => {

                alert("There was a problem getting your user profile data. Redirecting to homepage..."); //need to handle refresh token before this OR now.
                this.router.navigate(['/']);
            });
    }

    public impersonateUser(user_id: string) {
        //only continue if user has system_admin role on token.
        if (this.authService.is_system_admin() === false) { return; }
        // Open a new window and set a specific session value for that window
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        // Convert vw and vh to pixels
        const width = Math.round(viewportWidth * 0.95); // 80vw
        const height = Math.round(viewportHeight * 0.95); // 80vh

        const newWindow = window.open(`/organisation?impersonateUser=${user_id}`, '_blank', `width=${width},height=${height}`);
        newWindow.sessionStorage.setItem('impersonateUser', user_id);
    }

}
