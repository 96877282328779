<div class="hover-to-show fx-row fx-align-center">


    <div style="white-space: nowrap;max-width: 230px;" class="overflow-elipsis">
      {{invoice?.id}}
    </div>

    <mat-icon matSuffix svgIcon="view" (click)="openInvoice(invoice?.id, viewAs)" class="clickable action-icon"
      matTooltipPosition="below" matTooltipShowDelay="200">
    </mat-icon>

    <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(invoice?.id)" class="clickable action-icon"
      matTooltipPosition="below" matTooltipShowDelay="200">
    </mat-icon>
  

</div>