<ng-container *ngIf="organisation_id; else notFound">

  <div class="section-wrapper">
    <tab-title-lined>Shared Directories&nbsp;<mat-icon style="margin-left: 10px;" svgIcon="info"
      matTooltip="Add the config as JSON. A sample has been provided. Do not string escape this json."
      [matTooltipClass]="'tooltipcustom tooltipmultiline'"></mat-icon></tab-title-lined>
   
   

    <div class="section-content" fxLayout="column">



      <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100%" fxLayoutAlign="start">

        <ng-container *ngIf="pageStatus?.isLoading">
          <div>
            <div class="loader-spinner"></div>
          </div>
        </ng-container>


        <ng-container *ngIf="this.is_pipxt_enabled === false; else showDirectories">
          <div fxLayout="column" fxFlex="100%">
            Enable PiPxt to add directory connection
          </div>
        </ng-container>

        <ng-template #showDirectories>

          <div fxLayout="column" fxFlex="100%">

            <div *ngFor="let c of directoryConnections; let i = index">
              <ng-container *ngTemplateOutlet="existingConnection; context:{'index': i }">
              </ng-container>

              <ng-template #existingConnection let-index="index">

                <div fxLayout.lt-md="column" fxLayout="row" fxFlex="100%" fxLayoutGap="40px"
                  [ngClass]="{'border-bottom': directoryConnections?.length > 0 && index !== (directoryConnections?.length - 1) }">

                  <div fxLayout="column" fxFlex="50%" fxFlex.lt-md="100%" fxFlexOrder.lt-lg="2">

                    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">

                      <mat-form-field class="readonly-block custom-form-field-v3" appearance="outline" fxFlex="80%">
                        <input type="text" matInput placeholder="Directory type"
                          [ngModel]="directoryConnections[index].directory_type_name">
                        <mat-label>Directory type</mat-label>
                      </mat-form-field>

                      <mat-form-field [ngClass]="{'readonly-block': pageStatus.isSubmitting}" appearance="outline"
                        class="custom-form-field-v3" fxFlex="20%">
                        <input type="number" matInput placeholder="priority"
                          [(ngModel)]="directoryConnections[index].priority"
                          (ngModelChange)="directoryConnections[index].touched = true" min="1" max="10">
                        <mat-label>Priority</mat-label>
                      </mat-form-field>

                    </div>


                    <mat-form-field appearance="outline" floatLabel="always" style="width:100%;margin-bottom: 20px;"
                      [ngClass]="{'readonly-block': pageStatus.isSubmitting}" class="custom-form-field-v3">
                      <mat-label>Configuration</mat-label>
                      <textarea style="min-height: 100px;"
                        [ngModel]="returnUnescapeJSON(directoryConnections[index].configuration_json)"
                        (ngModelChange)="setUpdatedJson($event, index)" matInput
                        placeholder="Paste config JSON here"></textarea>
                    </mat-form-field>

                  </div>

                  <div fxLayout="column" fxFlex="50%" fxFlex.lt-md="100%" fxFlexOrder.lt-lg="1">

                    <div fxLayout="row" fxLayoutAlign="end center">

                      <tab-section-action >

                        <button *ngIf="!directoryConnections[index].touched"
                        (click)="confirmDelete(directoryConnections[index].id)" mat-flat-button
                        style="background: var(--c-error);color:white;margin-right:10px;"
                        [disabled]="pageStatus.isSubmitting" title="delete connection">
                        <ng-container *ngIf="!pageStatus.isSubmitting;else loadingConnections;">
                          <mat-icon class="icon-16" svgIcon="trash"></mat-icon>
                        </ng-container>
                      </button>

                      <button mat-flat-button *ngIf="directoryConnections[index].touched" style="margin-right: 4px;"
                        (click)="cancelForm()">Cancel</button>

                      <button mat-flat-button class="save-button"
                        [disabled]="pageStatus.isSubmitting ||  !directoryConnections[index].touched"
                        (click)="updateConnection(directoryConnections[index], index)">
                        <ng-container *ngIf="!pageStatus.isSubmitting;else loadingConnections;">Save Changes
                        </ng-container>
                      </button>
                      </tab-section-action>



                    </div>

                    <div fxLayout="row" fxLayoutAlign="end center" style="margin-top: 74px;">The tenant ID can be found in the organisations PBX Portal by examining the URL, it should end with server=X, where X is the id.

                      <div class="menu-item" fxLayoutAlign="center" *ngIf="viewAs === 'sysAdmin'">
                        <button style="min-width: unset;" mat-button (click)="openHelpCenter()">
                          <mat-icon svgIcon="help"></mat-icon> <span fxHide.lt-md></span>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>


              </ng-template>

            </div>


            <div *ngIf="!pageStatus?.isLoading">
              <ng-container *ngIf="!showAddNewForm">

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center center">

                  <!-- <div style="font-size:16px;font-weight: 600;margin-right: 8px;">Add new directory connection</div> -->
                  <button button mat-stroked-button (click)="toggleAddNewForm(true)">Add new directory
                    connection</button>
                  <!-- <button mat-fab (click)="toggleAddNewForm(true)" style="font-size: 28px;">+</button> -->
                </div>
              </ng-container>

              <ng-container *ngIf="showAddNewForm">


                <div>
                  <!-- <mat-icon class="icon-22" style="padding:2px;" (click)="toggleAddNewForm(false)"
                      svgIcon="remove_circle_outline"></mat-icon> -->

                  <form [formGroup]="connectionForm" fxLayout.lt-md="column" fxLayout="row" fxFlex="100%">

                    <div fxLayout="column" fxFlex="50%" fxFlex.lt-md="100%" fxFlexOrder.lt-lg="2">

                      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">

                        <mat-form-field appearance="outline" class="custom-form-field-v3" fxFlex="80%">
                          <mat-select placeholder="Add connection"
                            (selectionChange)="switchDirectoryTypeEvent($event.value)">
                            <mat-option [value]="connection.id" *ngFor="let connection of availableDirectoryTypes">
                              {{connection.name}}</mat-option>
                          </mat-select>
                          <mat-label>Select directory type</mat-label>
                        </mat-form-field>

                        <mat-form-field
                          [ngClass]="{'readonly-block': pageStatus.isSubmitting || !connectionForm.controls.directory_type_id.value}"
                          appearance="outline" class="custom-form-field-v3" fxFlex="20%">
                          <input type="number" matInput placeholder="priority" formControlName="priority" min="1"
                            max="10">
                          <mat-label>Priority</mat-label>
                        </mat-form-field>

                      </div>

                      <mat-form-field appearance="outline" floatLabel="always" style="width:100%;margin-bottom: 20px;"
                        [ngClass]="{'readonly-block': pageStatus.isSubmitting || !connectionForm.controls.directory_type_id.value}"
                        class="custom-form-field-v3">
                        <mat-label>Configuration</mat-label>
                        <textarea style="min-height: 100px;" formControlName="configuration_json" matInput
                          placeholder="Paste config JSON here"></textarea>
                      </mat-form-field>


                    </div>

                    <div fxLayout="column" fxFlex="50%" fxFlex.lt-md="100%" fxFlexOrder.lt-lg="1">

                      <div fxLayout="row" fxLayoutAlign="end center">

                        <!-- <mat-form-field appearance="outline" floatLabel="always" style="width:100%;margin-bottom: 10px;"
                          [ngClass]="{'readonly-block': pageStatus.isSubmitting || !connectionForm.controls.directory_type_id.value}" class="custom-form-field-v3">
                          <mat-label>Configuration</mat-label>
                          <textarea style="min-height: 100px;" formControlName="configuration_json" matInput
                            placeholder="Paste config JSON here"></textarea>
                        </mat-form-field> -->

                        <button mat-flat-button style="margin-right: 4px;"
                          (click)="toggleAddNewForm(false)">Cancel</button>

                        <button mat-flat-button class="save-button"
                          [disabled]="connectionForm.status !== 'VALID' || pageStatus.isSubmitting"
                          (click)="saveNewConnection(connectionForm)">
                          <ng-container *ngIf="!pageStatus.isSubmitting;else loadingConnections;">Save</ng-container>
                        </button>


                      </div>
                    </div>


                  </form>
                </div>

              </ng-container>
            </div>

          </div>

        </ng-template>


      </div>





    </div>
  </div>




</ng-container>


<ng-template #notFound>


  <div class="section-wrapper">
    <tab-title-lined>Shared Directories</tab-title-lined>

    <div class="section-content" fxLayout="column">
      Error getting data
    </div>
  </div>


</ng-template>


<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>
