
//core requirements
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//modules - core
// import { GravatarDirectiveModule} from '@app/directives/gravatar/gravatar.module';
import { MaterialModule } from '@app/material/material.module';
import { CustomPipesModule } from '@app/pipes/pipes.module';
import { SvgSimpleChartModule } from '@app/components/svg-simple-chart/svg-simple-chart.module';

//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { EsimOpenService } from '@app/services/pipcall/open/esim-open.service';
import { EsimTelnaService } from '@app/services/pipcall/esim_telna.service';

//declare components
import { EsimUsageComponent } from './esim-usage.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        RouterModule,
        CustomPipesModule,
        SvgSimpleChartModule
    ],
    declarations: [EsimUsageComponent],
    providers: [HttpProxy, AppInsightService, EsimOpenService, EsimTelnaService],
    entryComponents: []
})
export class EsimUsageModule {
}

