import { Component, AfterViewInit, ElementRef , ViewChild, Input, ViewEncapsulation, OnInit, Output, EventEmitter} from '@angular/core';
@Component({
    selector: 'btn-toggle',
    templateUrl: './btn-toggle.component.html',
    styleUrls: ['./btn-toggle.component.scss'],
    animations: [
    ],
    // encapsulation: ViewEncapsulation.None
})
export class BtnToggleComponent implements OnInit {

    @Input() isDisabled: boolean;
    // @Input() isReadOnly: boolean;
    @Input() isSubmitting: boolean ;
    @Input() value: boolean;
    @Input() style: 'outline' | 'fill' = 'fill';
    @Input() color: 'accent' | 'blue' | 'red' | 'green' | 'dark' | 'light' | 'default' = 'default';
    @Input() size: 'md' | 'sm' | 'lg' = 'md'; // sm , md , lg
    @Input() align: 'flex-end' | 'flex-start' | 'center' = 'center';

    @Output() valueChange = new EventEmitter<boolean>();

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    // Method to map color input to a CSS variable
    get styleClass(): string {
        return `style-${this.style}`;
    }


    get alignClass(): string {
        return `align-${this.align}`;
    }

    get colorClass(): string {
        return `color-${this.color}`;
    }

    get sizeClass(): string {
        return `size-${this.size}`;
    }

    toggleValue() {
        if (!this.isDisabled && !this.isSubmitting) {
            this.value = !this.value;
            this.valueChange.emit(this.value);
        }
    }
}
