


<main class="signup_container">
    <ng-container  *ngIf="isMobileView">
            <ng-container *ngTemplateOutlet="brandLogo"></ng-container>
    </ng-container>

<section class="signup_section-1">

    <div class="section-content_items">
        <div class="section-content_card-container">
            <card-signup-request></card-signup-request>
        </div>
    </div>
        
      <footer class="signup_footer" style="justify-content: center;">
        <div>Version {{appversion}}</div>
    </footer>
</section>


<section class="signup_section-2">
    <div>
        <ng-container  *ngIf="!isMobileView">
            <ng-container *ngTemplateOutlet="brandLogo"></ng-container>
        </ng-container>
        
        <h1 class="title-font">All your business mobile solutions in one place</h1>
        <div>
            <ul class="product-list">
                <li><mat-icon svgIcon="check_circle_fill"></mat-icon>&nbsp;Advanced business call features</li>
                <li><mat-icon svgIcon="check_circle_fill"></mat-icon>&nbsp;eSIM for worldwide travel</li>
                <li><mat-icon svgIcon="check_circle_fill"></mat-icon>&nbsp;UK voice & data plans</li>
                <li><mat-icon svgIcon="check_circle_fill"></mat-icon>&nbsp;IoT data</li>
                <li><mat-icon svgIcon="check_circle_fill"></mat-icon>&nbsp;International plans for overseas staff</li>
            </ul>
        </div>
        
    </div>
    <footer class="signup_footer">
        <div>{{copyrightMessage}}</div>
        <div><a href="https://pipcall.com"  aria-label="Link to PiPcall.com" matTooltip="https://pipcall.com"><mat-icon svgIcon="home"></mat-icon></a></div>
    </footer>
</section>
</main>


<ng-template #brandLogo>
    <div class="signup_brand-contents">
        <a href="https://pipcall.com" aria-label="Link to PiPcall.com" class="signup_brand-logo"> <img class="pip-logo" src="/assets/brand/pipcall-logo-white-flat.png"></a>
     </div>
</ng-template>