import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


//modules (imports)
import { MaterialModule } from '@app/material/material.module';
import { CustomPipesModule } from '@app/pipes/pipes.module'
import { FormBasicControlsModule} from '@app/components/toolbar/form-basic-controls/form-basic-controls.module';

//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AppTitleService } from '@app/services/helpers/update-title.service'
import { InvoiceService } from '@app/services/pipcall/invoice.service';

//declarations
import { InvoiceBottomSheetComponent } from './invoice-bsheet-modal.component';

//tabs
import { InvoiceViewModule } from '@app/components/tabs/invoice-view/invoice-view.module';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        CustomPipesModule,
        MaterialModule,
        FormBasicControlsModule,
        InvoiceViewModule
    ],
    declarations: [InvoiceBottomSheetComponent],
    providers: [HttpProxy, AppInsightService, AppTitleService, InvoiceService],
    exports: [],
    entryComponents: []
})
export class InvoiceBottomSheetModule {
}
