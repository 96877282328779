<ng-container *ngIf="licence; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div [@fadeInOnEnter] class="tab-child-view-container">


      <div class="section-wrapper">

        <div class="section-title fx-col fx-justify-start">

          <div class="fx-row fx-justify-start">Licence
          </div>

          <div>
            <button mat-flat-button *ngIf="!patchRenewalDateForm?.pristine" style="margin-right: 4px;"
              (click)="cancelForm()">Cancel</button>
  
            <button mat-flat-button aria-label="Save changes" class="save-button"
              [disabled]="patchRenewalDateForm?.pristine ||  pageStatus.isSubmitting || patchRenewalDateForm?.invalid"
              (click)="patchLicenceDates(patchRenewalDateForm)">
              <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
            </button>
          </div>
        </div>

        <div class="section-content">

          <div class="fx-row fx-col-sm fx-gap-2">


            <div class="fx-col fx-grow">
              <form [formGroup]="patchRenewalDateForm" style="width: 100%;">
                <table class="table tab-table-basic table-bordered cell-border no-hover fx-width-100">
                  <tbody>

                    <tr style="height: 10px;">
                      <td style="width: 220px;">
                        <strong class="title-color">Organisation</strong>
                      </td>
                      <td>
                        <div class="fx-row">

                          {{organisationProfile?.name}} ( 
                          <div [matTooltip]="organisationProfile?.state" class="fx-row fx-justify-start"
                          *ngIf="organisationProfile?.state"><span
                            class="overflow-ellipsis">{{organisationProfile?.state}}</span>
    
                          <div [hidden]="organisationProfile?.state !== 'Active'" class="status-icon">
                            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-accept)'></mat-icon>
                          </div>
    
                          <div [hidden]="organisationProfile?.state!== 'Trial'" class="status-icon">
                            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-warn)'></mat-icon>
                          </div>
    
                          <div [hidden]="organisationProfile?.state !== 'In Arrears'" class="status-icon">
                            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-error)'></mat-icon>
                          </div>
    
                        </div>)

                        </div>
                      </td>
                    </tr>


                    <tr style="height: 10px;">
                      <td style="width: 220px;">
                        <strong class="title-color">User</strong>
                      </td>
                      <td>
                        {{licence?.first_name}}  {{licence?.last_name}}
                      </td>
                    </tr>

                    <tr style="height: 10px;">
                      <td style="width: 220px;">
                        <strong class="title-color">Name</strong>
                      </td>
                      <td>
                        {{licence?.license_name}}
                      </td>
                    </tr>

                    <tr style="height: 10px;">
                      <td style="width: 220px;">
                        <strong class="title-color">Contract</strong>
                      </td>
                      <td>
                        {{licence?.license_contract_name}} {{licence?.license_version_number}}
                      </td>
                    </tr>


                    <tr style="height: 10px;">
                      <td style="width: 220px;">
                        <strong class="title-color">Licensed At</strong>
                      </td>
                      <td>
                        {{licence?.licensed_at | date: 'dd/MM/yyyy HH:mm'}}
                      </td>
                    </tr>


                    <tr style="height: 10px;">
                      <td style="width: 220px;">
                        <strong class="title-color">Renew Date</strong>
                      </td>
                      <td>
                        {{patchRenewalDateForm.controls.next_renewal_date.value}}
                        {{licence?.next_renewal_date | date: 'dd/MM/yyyy HH:mm'}} &nbsp; <button [disabled]="licence?.licensed_user_typ!=='User'" (click)="picker.open()" mat-stroked-button style="float:right" >Edit</button>
                        <mat-form-field floatLabel="never" style="width:1px;visibility:hidden;height:1px;">
                        <input matInput [matDatepicker]="picker" readonly="true"  name="Renewal date"
                        placeholder="Set date" [value]="renewalDate" (dateChange)="setRenewalDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                          <mat-icon style="font-size: 24px;" matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                      </mat-form-field>
                      </td>
                    </tr>

                    <tr style="height: 10px;" >
                      <td style="width: 220px;">
                        <strong class="title-color">Auto Renew</strong>
                      </td>
                      <td>
                        <div style="width: 60px; display: inline-block;">{{patchRenewalDateForm.controls.allow_renewal.value}}</div>
         
                          <button   [disabled]="licence?.licensed_user_type !=='User'" *ngIf="patchRenewalDateForm.controls.allow_renewal.value" (click)="setAutoRenewal(false)" class="red-button" style="float:right" mat-flat-button>Remove Auto renew</button>
                          <button [disabled]="patchRenewalDateForm.invalid || licence?.licensed_user_type !=='User'" *ngIf="!patchRenewalDateForm.controls.allow_renewal.value"  (click)="setAutoRenewal(true)"  class="save-button" style="float:right"  mat-flat-button>Activate Auto renew</button>
           
                     
                      </td>
                    </tr>

                    <tr style="height: 10px;">
                      <td style="width: 220px;">
                        <strong class="title-color">Price</strong>
                      </td>
                      <td>
                        {{licence?.license_price/100 | currency: 'GBP': 'symbol': '1.2-2'}}
                      </td>
                    </tr>

                    <tr style="height: 10px;">
                      <td style="width: 220px;">
                        <strong class="title-color">Payment Status</strong>
                      </td>
                      <td>
                        <span *ngIf="licence?.is_paid" style="color: var(--c-accept)">Paid</span>
                        <span *ngIf="!licence?.is_paid" style="color: var(--c-error)">Payment due</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </form>
            </div>

            <div class="fx-col">
              <span>Auto renewal and renewal date currently are only applicable to pay up front, annual contracts.</span>
              <span *ngIf="licence?.licensed_user_type==='User'">Changes to renewal dates can only be made on Licences where the invitation has been accepted.</span>
          
            </div>
          </div>
        </div>
      </div>






    </div>


  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div style="margin-top:2px;margin-bottom: 2px;" class="loader-spinner"></div>
  </div>
</ng-template>
