

<div style="height: 38px;" class="hover-to-show clickable fx-row fx-justify-start fx-align-center fx-gap-08">

  <div class="avatar-container av-size-30" *ngIf="licence?.license_id">
    <div class="avatar-icon-background fx-row fx-center">
      <mat-icon style="fill: white;height: 22px;width: 22px;" svgIcon="key"></mat-icon>
    </div>
  </div>

  <div class="fx-col fx-justify-center fx-align-start" >

    <div  style="height: 22px;">

      
      <span class="overflow-elipsis max-width-name" >
        <span *ngIf="!licence?.is_paid">*</span>
        {{licence?.license_name}}<span *ngIf="viewAs === 'sysAdmin'">&nbsp;v{{licence?.license_version_number}}</span>
      </span>

    </div>

    <div *ngIf="licence?.license_contract_name">
      <span style="max-width: 230px;" class="max-width-name overflow-elipsis" style="opacity: 0.6;font-size:0.9em;" [matTooltip]="licence?.license_contract_name">{{licence?.license_contract_name}}&nbsp;</span>
    </div>

  </div>

  <mat-icon matSuffix svgIcon="view" (click)="openLicence(licence, viewAs, organisation_id)" class="clickable action-icon"
  matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="Quick view licence">
</mat-icon>

  <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(licence?.license_id)" class="clickable action-icon"
  matTooltipPosition="below" matTooltipShowDelay="200" matTooltip="Copy licence ID">
</mat-icon>
</div>


