//core
import { Component, OnInit, Inject, isDevMode, HostListener, ViewChild, ElementRef, ChangeDetectorRef, EventEmitter, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { trigger, transition, animate, style, useAnimation } from '@angular/animations'

import { StripeService, Elements, Element as StripeElement, ElementsOptions, StripeFactoryService, StripeInstance, HandleCardPaymentOptions } from "ngx-stripe";
// import { Stripe} from "@stripe/stripe-js";

import { FormGroup, NgForm, FormBuilder, Validators } from "@angular/forms";
import { environment } from '../../../environments/environment';
import { map, tap, filter, catchError, mergeMap, finalize } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { slideInUpOnEnterAnimation} from 'angular-animations';
import { AppInsightService} from '@app/services/helpers/app-insights.service';
import {DefaultCard, PaymentsService} from '@app/services/pipcall/payments.service';

export interface CheckoutResponse {
    invoice_total: number;
    stripe_client_secret: string;
}


@Component({
    selector: 'app-payment-stripe-payment',
    templateUrl: './stripe-payment.component.html',
    styleUrls: ['./stripe-payment.component.scss'],
    animations: [
        slideInUpOnEnterAnimation()
    ]
})
export class StripePaymentComponent {

    elements: Elements;
    card: StripeElement;

    stripeForm: FormGroup;

    // optional parameters
    elementsOptions: ElementsOptions = {
        locale: 'en'
    };

    payment_method: "existing" | "new" = "existing";
    defaultCard: DefaultCard;

    // @Input() showExistingCardOption = false;
    @Input() checkoutResponse: CheckoutResponse;
    @Input() organisation_id: string;
    @Input() hideTitle: boolean;
    // @Input() mobileView: boolean;
    @Output() checkoutEvent = new EventEmitter(); //boolean, true if successfull checkout
    @Output() cancelCheckout = new EventEmitter(); //cancel event emit


    public errorMessage = {
        status: false,
        message: ''
    }



    pageStatus = {
        isCheckingPaymentMethods: false,
        isLoading: false,
        isSubmitting: false,
    }

    constructor(
        private snackBar: MatSnackBar,
        private cd: ChangeDetectorRef,
        private stripeService: StripeService,
        private fb: FormBuilder,
        private stripeFactory: StripeFactoryService,
        private appInsightService: AppInsightService,
        private paymentsService: PaymentsService
    ) {
        this.pageStatus.isLoading = false;
        this.pageStatus.isCheckingPaymentMethods = true;
    }


    ngOnInit() {

        this.stripeForm = this.fb.group({
            name: ['', [Validators.required]]
        });
        this.getDefaultCard();
        this.mountCard();
    }

    mountCard() {
        this.stripeService.elements(this.elementsOptions)
            .subscribe(elements => {
                this.elements = elements;
                // Only mount the element the first time
                if (!this.card) {
                    this.card = this.elements.create('card', {
                        style: {
                            base: {
                                iconColor: '#808080',
                                color: '#000000',
                                lineHeight: '40px',
                                fontWeight: 500,
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                '::placeholder': {
                                    color: '#808080'
                                }
                            },
                            invalid: {
                                iconColor: '#a972a5',
                                color: '#f31717',
                            }
                        },
                    });
                    this.card.mount('#card-element')
                    // this.mobileView ? this.card.mount('#card-element-mobile') : this.card.mount('#card-element');

                    console.log('mounted card', this.card);

                }
            });
    }

    getDefaultCard() {
        this.pageStatus.isCheckingPaymentMethods = true;

        if (!this.organisation_id) {
            this.payment_method = "new";
            this.pageStatus.isCheckingPaymentMethods = false;
            return;
        }
        this.paymentsService.getDefaultCard(this.organisation_id)
            .subscribe((resp) => {
                console.log('default card', resp);
                if (resp.body?.payment_method_id) {
                    this.defaultCard = resp.body;
                    this.payment_method = "existing";
                    this.pageStatus.isCheckingPaymentMethods = false;
                } else {
                    this.payment_method = "new";
                    this.pageStatus.isCheckingPaymentMethods = false;
                }
            }, (err) => {
                this.payment_method = "new";
                console.log('error getting default card', err);
                this.pageStatus.isCheckingPaymentMethods = false;
            })
    }

    pay() {
        if (this.payment_method === "new") {
            console.log('paying with new card');
            this.payWithNewCard();
        } else if (this.payment_method === "existing") {
            console.log('paying with existing card');
            this.payWithExistingCard(this.defaultCard.payment_method_id);
        }
    }


    payWithNewCard() {
        this.appInsightService.logEvent('Checkout: Make Stripe payment with new card');

        const clientSecret = this.checkoutResponse.stripe_client_secret;
        this.errorMessage.status = false;
        this.errorMessage.message = '';
        this.pageStatus.isSubmitting = true;


        // receipt_email?: string;
        const payment_method_data: HandleCardPaymentOptions = {
            save_payment_method: true
        }
        // https://docs.stripe.com/js/deprecated/handle_card_payment
        // this.stripeService.handleCardPayment(clientSecret, {payment_method: '{PAYMENT_METHOD_ID}'})
        // send payment_method or payment_method_data. receipt_email. setup_future_usage = off_session
        this.stripeService.handleCardPayment(clientSecret, this.card, payment_method_data)
            .pipe(
                tap(() => {
                    this.pageStatus.isSubmitting = true;
                })
            )
            .subscribe((resp) => {
                if (resp.error) {
                    //show an error now
                    console.log("ERROR", resp.error['message']);
                    this.errorMessage.message = resp.error['message'];
                    this.errorMessage.status = true;
                    this.pageStatus.isSubmitting = false;
                    this.appInsightService.logEvent('Checkout: Stripe payment failed');
                } else {
                    // paymentIntent object , id , status == succeeded
                    this.appInsightService.logEvent('Checkout: Stripe payment success', resp);
                    console.log('success - pamentintent', resp);
                    // result.paymentIntent: the successful PaymentIntent.
                    this.checkoutEvent.emit(true);
                    return;
                }
                return ;
            }, (err) => {
                console.log("error:", err);
                this.appInsightService.logEvent('Checkout: Stripe payment failed', {'err': err});
                this.errorMessage.message = "something went wrong with this transaction. You have not been charged."; //check api for status codes toflesh out error message
                this.errorMessage.status = true;
                this.pageStatus.isSubmitting = false;
            })
    }

    payWithExistingCard(paymentMethodId: string) {
        console.log('paying with existing card');

        this.appInsightService.logEvent('Checkout: Make Stripe payment with existing card');

        const clientSecret = this.checkoutResponse.stripe_client_secret;
        this.errorMessage.status = false;
        this.errorMessage.message = '';
        this.pageStatus.isSubmitting = true;

        // https://docs.stripe.com/js/deprecated/handle_card_payment
        // this.stripeService.handleCardPayment(clientSecret, {payment_method: '{PAYMENT_METHOD_ID}'})
        // send payment_method or payment_method_data. receipt_email. setup_future_usage = off_session
        this.stripeService.handleCardPayment(clientSecret, {payment_method: paymentMethodId})
            .pipe(
                tap(() => {
                    this.pageStatus.isSubmitting = true;
                })
            )
            .subscribe((resp) => {
                if (resp.error) {
                    //show an error now
                    console.log("ERROR", resp.error['message']);
                    this.errorMessage.message = resp.error['message'];
                    this.errorMessage.status = true;
                    this.pageStatus.isSubmitting = false;
                    this.appInsightService.logEvent('Checkout: Stripe payment failed');
                } else {
                    // paymentIntent object , id , status == succeeded
                    this.appInsightService.logEvent('Checkout: Stripe payment success', resp);
                    console.log('success - pamentintent', resp);
                    // result.paymentIntent: the successful PaymentIntent.
                    this.checkoutEvent.emit(true);
                    return;
                }
                return ;
            }, (err) => {
                console.log("error:", err);
                this.appInsightService.logEvent('Checkout: Stripe payment failed', {'err': err});
                this.errorMessage.message = "something went wrong with this transaction. You have not been charged."; //check api for status codes toflesh out error message
                this.errorMessage.status = true;
                this.pageStatus.isSubmitting = false;
            })

    }

    //to add
    //save card
    //update card
    //remove payment method


    ngOnDestroy() {

    }

}


// this.appInsightService.logEvent('Checkout: Make Stripe payment');
// //deprecated for confirmCardPayment. But will continue to work. new method not available in ngx stripe yet


// const clientSecret = this.checkoutResponse.stripe_client_secret;
// this.errorMessage.status = false;
// this.errorMessage.message = '';
// this.isLoading = true;

// // https://docs.stripe.com/js/deprecated/handle_card_payment
// // this.stripeService.handleCardPayment(clientSecret, {payment_method: '{PAYMENT_METHOD_ID}'})
// // send payment_method or payment_method_data. receipt_email. setup_future_usage = off_session
// this.stripeService.handleCardPayment(clientSecret, this.card)
//     .pipe(
//         tap(() => {
//             this.isLoading = true;
//         })
//     )
//     .subscribe((resp) => {
//         if (resp.error) {
//             //show an error now
//             console.log("ERROR", resp.error['message']);
//             this.errorMessage.message = resp.error['message'];
//             this.errorMessage.status = true;
//             this.isLoading = false;
//             this.appInsightService.logEvent('Checkout: Stripe payment failed');
//         } else {
//             // paymentIntent object , id , status == succeeded
//             this.appInsightService.logEvent('Checkout: Stripe payment success', resp);
//             console.log('success - pamentintent', resp);
//             // result.paymentIntent: the successful PaymentIntent.
//             this.checkoutEvent.emit(true);
//             return;
//         }
//         return ;
//     }, (err) => {
//         console.log("error:", err);
//         this.appInsightService.logEvent('Checkout: Stripe payment failed', {'err': err});
//         this.errorMessage.message = "something went wrong with this transaction. You have not been charged."; //check api for status codes toflesh out error message
//         this.errorMessage.status = true;
//         this.isLoading = false;
//     })
