import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BlacklistService {
    private domainBlacklist: string[] = [];

    constructor(private http: HttpClient) {}

    loadBlacklist(): void {
        this.http.get<any>('https://pipcallportalstorage.blob.core.windows.net/files/domain-blacklist.json?timestamp=' + new Date().getTime())
            .pipe(
                tap(data => this.domainBlacklist = data.blacklist || []),
                catchError(error => {
                    console.error('Failed to load blacklist:', error);
                    this.domainBlacklist = []; // Set to an empty array on error
                    return of([]); // Return an empty observable to complete the stream
                })
            )
            .subscribe(() => {
                console.log('Blacklist loaded', this.domainBlacklist);
            });
    }
    isDomainBlacklisted(email: string): boolean {
        // Extract the domain part after the '@'
        const domain = email.split('@')[1];
        let result = false;
        this.domainBlacklist ? result = this.domainBlacklist.includes(domain) : result = false;
        return result;
    }
}
