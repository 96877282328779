<div class="pip-modal-content">
  <button class="pip-modal-close_btn"  mat-mini-fab color="primary" (click)="closeModal()"aria-label="close modal"> 
    <mat-icon svgIcon="clear"></mat-icon>
  </button> 
  <div class="pip-modal-header">
    <!-- <span class="pip-modal-close" (click)="closeModal()">X</span> -->
    <span class="pip-modal-title">Licence pricing</span>
    <!-- <span class="pip-modal-subtitle">This is a subtitle</span> -->
  </div>
  <div id="pipmodalbody" class="pip-modal-body">

    <div  class="tarrif-content-body fx-col fx-center" >

      <div class="fx-col fx-justify-center fx-align-end">
    
      <div >
      <app-view-licence-tariff></app-view-licence-tariff>
      </div>
  
      <div *ngIf="includeAddOns">
        <app-view-add-on-tariff></app-view-add-on-tariff>
      </div>
    </div>
    
       <div class="extra-info fx-row fx-justify-center fx-align-end">
         <div class="fx-col fx-justify-center fx-align-end" style="width: 100%;">
           <div><i>All prices exclude VAT</i></div>
           <div><i>*Fair usage applies</i></div>
         </div>
       </div>
  
    </div>




  </div>
  <div class="pip-modal-footer">

    <!-- <button mat-flat-button color="primary" (click)="closeModal()">Close</button> -->
  </div>
</div>
