import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-empty-redirect',
    template: ''
})
export class BillingRedirectComponent implements OnInit {
    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id');
        this.router.navigate([`/organisation/${id}`], { queryParams: { tab: 'billing' } });
    }
}
