import { NgModule } from '@angular/core';

//requirements
import { MaterialModule } from '../../../material/material.module';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '../../../services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule } from '@app/pipes/pipes.module';
//create modal components
import { CreateOrganisationModalComponent } from './create-organisation-modal.component';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { ValidatorsModule } from '../../../validators/validators.module';
import { SignupService } from '@app/services/pipcall/signup.service';
import { OrganisationService} from '@app/services/pipcall/organisation.service';


@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        ValidatorsModule,
        FlexLayoutModule,
        CustomPipesModule,
        StatusIconsModule,
        RouterModule
    ],
    declarations: [CreateOrganisationModalComponent],
    exports: [CreateOrganisationModalComponent],
    providers: [HttpProxy, SignupService, OrganisationService],
    entryComponents: [CreateOrganisationModalComponent]
})
export class CreateOrganisationModalModule { }
