import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-svg-simple-pie-chart',
    templateUrl: './svg-simple-chart.component.html',
    styleUrls: ['./svg-simple-chart.component.scss']
})
export class SvgSimpleChartComponent implements OnInit {


    @Input() maxValue: number; // Maximum value
    @Input() chartValue: number; //

    @Input() isEsimUsage: boolean; // display esim content in center
    @Input () esim_expires_at: string; // esim expiry date

    circumference: number = 2 * Math.PI * 50; // Circumference of the circle (r=50 in SVG)
    strokeDashoffset = 0; // Initial dash offset
    remainingPercentage = 0; // Remaining percentage
    gradientColors: { start: string; end: string } = { start: '#36a2eb', end: '#ff6384' }; // Default colors


    constructor(
    ) {

    }

    ngOnInit() {
        this.updateChart();
    }

    ngOnChanges(): void {
        this.updateChart();
    }


    private updateChart(): void {
    // Calculate the remaining percentage
        this.remainingPercentage = (this.chartValue / this.maxValue) * 100;

        // Calculate the stroke-dashoffset for the remaining percentage
        const newStrokeDashoffset = this.circumference * (1 - this.remainingPercentage / 100);


        // Update gradient colors based on the remaining percentage
        this.updateGradient(this.remainingPercentage);

        // Animate the change in stroke-dashoffset
        setTimeout(() => {
            this.strokeDashoffset = newStrokeDashoffset;
        }, 100);
    }


    // for esim usage ============================
    getTimeRemainingInSeconds(targetDateTime: Date): number {
        return Math.floor((targetDateTime.getTime() - Date.now()) / 1000);  // Calculate difference directly in seconds
    }

    getRemainingTime(expiry_date: string): string {
        const targetDate = new Date(expiry_date);  // Use expiry_date passed in
        const time_remaining = this.getTimeRemainingInSeconds(targetDate);

        if (time_remaining <= 0) {
            return 'Expired';
        }

        const days = Math.floor(time_remaining / (24 * 60 * 60));  // Total days
        const hours = Math.floor((time_remaining % (24 * 60 * 60)) / (60 * 60));  // Remaining hours after removing days
        const minutes = Math.floor((time_remaining % (60 * 60)) / 60);  // Remaining minutes after removing hours

        // Constructing the output based on which time units are relevant
        if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''}` + (hours > 0 ? ` ${hours} hour${hours > 1 ? 's' : ''}` : '');
        }
        if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''}` + (minutes > 0 ? ` ${minutes} minute${minutes > 1 ? 's' : ''}` : '');
        }
        if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }

        return 'Expired';
    }
    //================================================================================================
    private updateGradient(percentage: number): void {
        if (percentage > 75) {
            this.gradientColors = { start: '#40a8f3', end: '#4cf45d' }; // Blue to Green
        } else if (percentage > 50) {
            this.gradientColors = { start: '#4cf45d', end: '#f3f74c' }; // Green to Yellow
        } else if (percentage > 25) {
            this.gradientColors = { start: '#f3f74c', end: '#f4a54c' }; // Yellow to Orange
        } else {
            this.gradientColors = { start: '#f4a54c', end: '#f44c4c' }; // Orange to Red
        }
    }
}
