import { Injectable, ModuleWithProviders, NgModule, ApplicationRef } from '@angular/core';
import { of } from 'rxjs/observable/of';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { debounce, debounceTime, tap, finalize, first, take, switchMap, } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from './pipcall/organisation.service';
import { AuthService, UserIdentification } from '@app/services/auth-service/auth.service';
import { UserSessionService, UserProfile } from '@app/services/user-session.service';


export { OrganisationModel };

@Injectable({
    providedIn: 'root'
})
export class OrgSessionService {

    public impersonateUserId: string;
    public  organisationProfileAsObservable: Observable<any>;
    private organisationProfile = new BehaviorSubject<OrganisationModel>(null);
    private userProfile: UserProfile;
    public useridentification: UserIdentification;
    private subscription: Subscription;
    private organisation_id: string;
    private sessionStatus =
    {
        isGettingOrg: false
    }
    constructor(
        private router: Router,
        private cookieService: CookieService,
        private organisationService: OrganisationService,
        private userSessionService: UserSessionService,
        private authService: AuthService
    ) {
        this.organisationProfile.next(null);
        this.organisationProfileAsObservable = this.organisationProfile.asObservable().filter(resp => !!resp);
        this.startSetOrgSession();
    }

    public updateOrganisationProfile() {
        console.log('manually trigger update organisation profile');
        this.getSessionOrgProfile();
    }

    private startSetOrgSession() {
        //update thiis to use id token unless impersonating and isSystemAdmin - then use userprofile session.?

        this.subscription = this.userSessionService.userProfileAsObservable.subscribe((resp) => {
            console.log('Subscription in Org session -> [user-session-service] userProfileAsObservable has recieved an update', resp);
            this.userProfile = resp;
            this.organisation_id = resp?.organisation_list[0]?.id;
            this.organisation_id ? this.getSessionOrgProfile() : '';
        });
    }

    private getSessionOrgProfile() {
        console.log('Organisation session service - get organisation profile');

        if ( this.sessionStatus.isGettingOrg === true) {
            return; }
        console.log('[org-session-service getting user organisation for id', this.organisation_id);

        this.organisationService.getOrganisationById(this.organisation_id)
            .take(1)
            .subscribe((resp) => {
                console.log('Organisation session service - get organisation profile response', resp);
                this.organisationProfile.next(resp?.body);
            }, (err) => {
                console.log('[**error**] problem getting organisation data', err);
            });
    }


    ngOnDestory() {
        this.subscription ? this.subscription.unsubscribe() : '';
    }
}
