import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';

//create modal components
import { AllocateDdiToOrganisationModalComponent } from './allocate-ddi-to-organisation-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule } from '@app/pipes/pipes.module';

//services
import { NumberService } from '@app/services/pipcall/number.service'

import { OrgSearchSelectModule} from '@app/components/form-field/ff-org-search-select/ff-org-search-select.module';

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        MaterialModule,
        DragDropModule,
        FlexLayoutModule,
        CustomPipesModule,
        OrgSearchSelectModule
    ],
    declarations: [AllocateDdiToOrganisationModalComponent],
    exports: [AllocateDdiToOrganisationModalComponent],
    providers: [AppInsightService, NumberService],
    entryComponents: [AllocateDdiToOrganisationModalComponent]
})
export class AllocateDdiToOrganisationModalModule { }
