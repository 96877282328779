import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { OrganisationModel } from '@app/models/organisation.model';
import { UserProfile, OrganisationUser } from '@app/models/user-profile.model';
import { AuthService } from '@app/services/auth-service/auth.service';
import { UserService } from '@app/services/pipcall/user.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import 'rxjs/add/operator/switchMap';
import { PaymentsService } from '@app/services/pipcall/payments.service';
import { LicenseModel, AddonPackageModel, PricingListItem } from '@app/models/license.model';
import { map, tap, filter, catchError, mergeMap, finalize } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common'
import { E } from '@angular/cdk/keycodes';

export class ContractType {
    value: string;
    display: string;
    hierarchy: number;
}

export class LicenseType {
    value: string;
    display: string;
    hierarchy: number;
    isMobile: boolean;
}

// https://stackblitz.com/edit/angular-data-sharing-example-dsxnnc?file=app%2FdataService.ts
@Injectable()
export class LicenseDataService {

    //additional - build contract and license Options

    private licenseList = new BehaviorSubject<LicenseModel[]>([]);
    public licenseListAsObservable = this.licenseList.asObservable().filter(resp => !!resp);

    private activeLicenseList = new BehaviorSubject<LicenseModel[]>([]);
    public activeLicenseListAsObservable = this.activeLicenseList.asObservable().filter(resp => !!resp);

    private addOnPackageList = new BehaviorSubject<AddonPackageModel[]>([]);
    public addOnPackageListAsObservable = this.addOnPackageList.asObservable().filter(resp => !!resp);

    private activeAddOnPackageList = new BehaviorSubject<AddonPackageModel[]>([]);
    public activeAddOnPackageListAsObservable = this.activeAddOnPackageList.asObservable().filter(resp => !!resp);

    private pricingList = new BehaviorSubject<PricingListItem[]>([]); //returns active only
    public pricingListAsObservable = this.pricingList.asObservable().filter(resp => !!resp);


    private contractTypes = new BehaviorSubject<ContractType[]>([]);
    public contractTypesAsObservable = this.contractTypes.asObservable().filter(resp => !!resp);

    private licenseTypes = new BehaviorSubject<LicenseType[]>([]);
    public licenseTypesAsObservable = this.licenseTypes.asObservable().filter(resp => !!resp);


    gettingData = false;
    gettingAddOndata = false;

    public static addOnSMSType = 'sms';
    public static addOnBasicType = 'basic_call';
    public static addOnIntType = 'intl_call';

    public static annualContract = '1y_up-front';
    public static monthlyContract = '1y_monthly';
    public static rollingContract = '1m_monthly';
    public static isMobileIdentifier = 'text';
    public static isBasicIdentifier = 'basic';


    constructor(
        private paymentsService: PaymentsService,
        private currencyPipe: CurrencyPipe,
    ) {
        this.gettingData, this.gettingAddOndata = false;
        this.innit();
    }


    innit(location?: string) {
        if (this.activeLicenseList.value.length < 1 || this.licenseList.value.length < 1 || this.addOnPackageList.value.length || !this.isValid()) {
            this.gettingData ? null : this.getData(); //only get data once.
            this.gettingAddOndata ? null : this.getAddOnPackageData();
        }
    }



    private getData() {
        this.gettingData = true;
        this.paymentsService.getListLicenses()
            .pipe(
                tap(value => this.licenseList.next(value.body.license_list)),
                map(value => this.filterToActive(value.body.license_list)),
                finalize(() => { this.gettingData = false; })
            )
            .subscribe((resp) => {
                console.log("[LicenseDataService].getData()", resp)
                resp ? this.activeLicenseList.next(resp) : null;
                this.getDistinctContractTypes();
                this.setExpiresTime(15);
            });

    }

    private getAddOnPackageData() {
        this.gettingAddOndata = true;
        this.paymentsService.getAddOnPackages()
            .pipe(
                tap(value => this.addOnPackageList.next(value.body.add_on_package_list)),
                map(value => this.filterToActiveAddOn(value.body.add_on_package_list)),
                finalize(() => { this.gettingAddOndata = false; })
            )
            .subscribe((resp) => {
                console.log("[LicenseDataService].getAddOnPackageData()", resp)
                resp ? this.activeAddOnPackageList.next(resp) : null;
            });
    }

    public returnLicense(id) {
    //return license details by supplying a license id. includes archived licenses. price, name, billing cycle, id etc
        if (this.licenseList.value.length > 0) {
            return this.licenseList.value.find(license => license.id === id);
        } else {
            this.innit();
            setTimeout(() => {
                return this.licenseList.value.find(license => license.id === id);
            }, 3000);
        }
    }

    private filterToActive(list: LicenseModel[]): LicenseModel[] {
        const activeLicenseList: LicenseModel[] = new Array<LicenseModel>();
        const dateNow = new Date();

        list.forEach(item => {
            const validFrom = item.valid_from ? new Date(item.valid_from) : null;
            const validTo = item.valid_to ? new Date(item.valid_to) : null;
            if ((validFrom < dateNow && validTo > dateNow) || (validFrom < dateNow && validTo == null)) {
                activeLicenseList.push(item);
            }
        });
        console.log("active licence list returned", activeLicenseList)
        return activeLicenseList; //return only Valid licenses
    }

    private filterToActiveAddOn(list: AddonPackageModel[]): AddonPackageModel[] {
        const activeAddOnPackageList: AddonPackageModel[] = new Array<AddonPackageModel>();
        const dateNow = new Date();

        list.forEach(item => {
            const validFrom = item.valid_from ? new Date(item.valid_from) : null;
            const validTo = item.valid_to ? new Date(item.valid_to) : null;
            if ((validFrom < dateNow && validTo > dateNow) || (validFrom < dateNow && validTo == null)) {
                activeAddOnPackageList.push(item);
            }
        });
        return activeAddOnPackageList; //return only Valid licenses
    }


    private setExpiresTime(minutes: number) {
        const expiresAs = new Date().getTime() + minutes * 60000; //15 mins from now
        sessionStorage.setItem("_exp_data", expiresAs.toString());
    }

    private addMinutes(date, minutes) {
        return new Date(date.getTime() + 15 * 60000);
    }

    private isValid(): boolean {
        const expiresAt = sessionStorage.getItem('_exp') ? parseInt(sessionStorage.getItem('_exp')) : 0;
        const now = (new Date().getTime() / 1000);
        return expiresAt ? now < expiresAt : false;
    }


    //pricing Data

    getDistinctContractTypes() {

        //return all the distinct contract types from the active license list. e.g 1y_up-front, 1y_monthly, 1m_monthly
        let distinctContractTypes = [];
        distinctContractTypes = this.activeLicenseList.value.map(item => item.contract_type)
            .filter((value, index, self) => self.indexOf(value) === index)


        //returns all the distinct license types from the active license list. e.g talk , talk_and_text
        let distinctTypes = [];
        distinctTypes = this.activeLicenseList.value.map(item => item.type)
            .filter((value, index, self) => self.indexOf(value) === index)


        const _contractTypes: ContractType[] = new Array<ContractType>();

        ///give the contract types a heiracy, used for upgrade options later
        distinctContractTypes.forEach(item => {
            let _hierachy = 0;
            switch (item) {
                case LicenseDataService.annualContract:
                    _hierachy = 3;
                    break;
                case LicenseDataService.monthlyContract:
                    _hierachy = 2;
                    break;
                case LicenseDataService.rollingContract:
                    _hierachy = 1;
                    break;
                default:
                    _hierachy = 0;
                    break;
            }

            const _contractType: ContractType = {
                value: item,
                display: this.activeLicenseList.value.find(license => license.contract_type === item).contract_name,
                hierarchy: _hierachy
            }
            _contractTypes.push(_contractType);
        });

        // E.g returns
        //[ {display: "1 Month (rolling)", hierarchy: 1,value: "1m_monthly" }]



        const _licenseTypes: LicenseType[] = new Array<LicenseType>();
        const _priceList: PricingListItem[] = new Array<PricingListItem>();

        distinctTypes.forEach(item => {


            const _licenseType: LicenseType = {
                value: item,
                display: this.activeLicenseList.value.find(license => license.type === item)?.name,
                hierarchy: item.includes(LicenseDataService.isBasicIdentifier) ? 0 : 1,
                isMobile: item.includes(LicenseDataService.isMobileIdentifier)
            }

            _licenseTypes.push(_licenseType);


            const rolling = this.activeLicenseList.value.find(license => license.type === item && license.contract_type === LicenseDataService.rollingContract);
            const monthly = this.activeLicenseList.value.find(license => license.type === item && license.contract_type === LicenseDataService.monthlyContract);
            const annually = this.activeLicenseList.value.find(license => license.type === item && license.contract_type === LicenseDataService.annualContract);

            // let annualCost = {total:, display:}
            //still looping. For this distrinct type, create a list item and set the price and display name
            const price: PricingListItem = {
                display: this.activeLicenseList.value.find(license => license.type === item)?.name,
                type: item,
                isMobile: item.includes(LicenseDataService.isMobileIdentifier),
                isBasic: item.includes(LicenseDataService.isBasicIdentifier),
                rolling: {
                    contract_type: rolling ? rolling?.contract_type : null,
                    licence_type:  rolling ?  this.activeLicenseList.value.find(license => license.type === item).type : null,
                    licence_type_display:  rolling ? this.activeLicenseList.value.find(license => license.type === item).name : null,
                    display:  rolling ? rolling?.contract_name : null,
                    license_id:  rolling ? rolling?.id : null,
                    price: rolling ? rolling?.price : null,
                    yearlyPrice:  rolling ? this.convertToCurrencyString(rolling?.price * 12) : null,
                    monthlyPrice:  rolling ? this.convertToCurrencyString(rolling?.price)   : null,
                    pounds:  rolling ?  this.convertToCurrencyString(rolling?.price).split(".")[0] : null,
                    pence:  rolling ?  this.convertToCurrencyString(rolling?.price).split(".")[1]  : null,
                },
                monthly: {
                    contract_type: monthly ? monthly?.contract_type : null,
                    licence_type: monthly ? this.activeLicenseList.value.find(license => license.type === item).type : null,
                    licence_type_display: monthly ? this.activeLicenseList.value.find(license => license.type === item).name : null,
                    display: monthly ? monthly.contract_name : null,
                    price: monthly ? monthly?.price : null,
                    license_id: monthly ?  monthly?.id : null,
                    yearlyPrice: monthly ?  this.convertToCurrencyString(monthly?.price * 12) : null,
                    monthlyPrice: monthly ? this.convertToCurrencyString(monthly?.price) : null,
                    pounds: monthly ?  this.convertToCurrencyString(monthly?.price).split(".")[0] : null,
                    pence: monthly ? this.convertToCurrencyString(monthly?.price).split(".")[1] : null
                },
                annually: {
                    contract_type: annually ? annually?.contract_type : null,
                    licence_type:  annually ? this.activeLicenseList.value.find(license => license.type === item).type : null,
                    licence_type_display:  annually ? this.activeLicenseList.value.find(license => license.type === item).name : null,
                    display:  annually ?  annually?.contract_name : null,
                    license_id:  annually ?  annually?.id : null,
                    price:  annually ? annually?.price : null,
                    yearlyPrice: annually ?  this.convertToCurrencyString(annually?.price) : null,
                    monthlyPrice:  annually ? this.convertToCurrencyString(annually?.price / 12) : null,
                    pounds:  annually ?  this.convertToCurrencyString(annually?.price / 12).split(".")[0] : null,
                    pence:  annually ? this.convertToCurrencyString(annually?.price / 12).split(".")[1] : null
                }
            }
            _priceList.push(price);
        });

        console.log("==================Price List", _priceList);

        this.contractTypes.next(_contractTypes); //set contract type values
        this.licenseTypes.next(_licenseTypes); // set the license types
        this.pricingList.next(this.arrayMove(_priceList, 1, 2)); //set the price list  //Reorder the Price List
    }

    private arrayMove(arr, fromIndex, toIndex) {
    //adjust position of data in array
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        return arr;
    }
    private convertToCurrencyString(price) {
        return this.currencyPipe.transform(price / 100, 'GBP', '', '1.2-2');
    }
    private returnDistinct(array: any[], key) {
        return [...new Map(array.map(item =>
            [item[key], item])).values()];
    }
}

//get details on load
//store user details here
//store Org details here
