/* eslint-disable max-len */
import { environment } from '../../../environments/environment';
import { Component, OnInit, OnDestroy, isDevMode, ChangeDetectorRef, HostListener, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, ValidationErrors, FormControl, Form, AbstractControl, FormsModule } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { ModalService } from '@app/services/modal-service/modal.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
//normalizing number
import { AppTitleService } from '@app/services/helpers/update-title.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { CookieService } from 'ngx-cookie-service'
import { fadeInOnEnterAnimation, fadeInRightOnEnterAnimation, fadeInLeftOnEnterAnimation, slideInUpOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { UserProfile } from '@app/models/user-profile.model';
import { LoginService} from '@app/services/pipcall/login.service';
import { TitleCasePipe } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
// import { UserSessionService } from '@app/services/user-session.service';
import { AuthService, UserIdentification } from '@app/services/auth-service/auth.service';

import * as jwt_decode from "jwt-decode";
import { I } from '@angular/cdk/keycodes';


@Component({
    selector: 'app-login-page',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ]),
        trigger('fadeInOut', [
            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({ opacity: 1 })),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({ opacity: 0 }),
                animate(600)
            ]),
            // fade out when destroyed. this could also be written as transition('void => *')
            // transition(':leave',
            //     animate(100, style({ opacity: 0 })))
        ]),
        slideInUpOnEnterAnimation(),
        fadeInLeftOnEnterAnimation({ anchor: 'enter' }),
        fadeInOnEnterAnimation({ anchor: 'onLoadEnter', duration: 800, delay: 0 }),
        fadeOutOnLeaveAnimation({ anchor: 'leave', duration: 500, delay: 0 })

    ]
})
export class LoginV2Component implements OnInit,  OnDestroy {

    @Input() public isModal: boolean;

    public isDev: boolean;
    public appversion: string = environment.appversion;
    public copyrightMessage = environment.copyrightMessage;

    signInForm: FormGroup;
    public pwType: 'password' | 'text' = 'password';

    // public userProfile: UserProfile;
    public userIdToken: UserIdentification;
    // public fullname: string;
    // public firstname = '';


    public pageStatus = {
        pageLoading: false,
        isLoading: false,
        isError: false,
        isSubmitting: false,
        errorMessage: ''
    }

    existingToken = false;
    private subscription = new Subscription();
    private refreshtoken_: Subscription;
    private sessionSubscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private modalService: ModalService,
        public router: Router,
        public route: ActivatedRoute,
        private appTitleService: AppTitleService,
        private appInsightService: AppInsightService,
        private _fb: FormBuilder,
        private snackBar: MatSnackBar,
        private titlecasePipe: TitleCasePipe,
        private cookieService: CookieService,
        private loginService: LoginService,
        private ref: ChangeDetectorRef,
        private authService: AuthService
    ) {
        this.appTitleService.setTitle('PiPcall: Login');
        this.appInsightService.logPageView('Login');


        this.signInForm = this._fb.group({
            email: ['', [Validators.email, Validators.required]],
            password: ['', [Validators.required]]
        });
    }

    get hasExistingToken(): boolean {
        // If a valid access token exists OR a valid refresh token exists, return true
        return this.authService.isSessionValid();
        // return this.cookieService.check('access_token') && this.auth.isAuthenticated() ? true : this.cookieService.check('refresh_token') ? true : false;
    }

    ngOnInit() {
        // this.pageStatus.pageLoading = true;
        console.log("[/login].ngOnInit()", this.pageStatus)

        this.subscription =  this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            // Check if the NavigationEnd event is for this component's route
            if (event.url === '/login') {
                //this addresses a load problem when the back button is clicked
                // window.location.reload();
            }
        });

        this.initializePage()

        setTimeout(() => {
            this.ref.detectChanges();
        }, 800);
    }

    initializePage() {
        if (!environment.production) {
            console.log("set dev environment")
            this.isDev = true;
        }

        this.authService.userIdentificationAsObservable.subscribe((resp) => {
            console.log("login component . userid as observable returned (id token)", resp)
            if (resp) {
                this.userIdToken = resp;
                this.pageStatus.pageLoading = false;
            }
        });

        this.existingToken = this.hasExistingToken;
        // this.setUsersProfile();
        this.pageStatus.pageLoading = false;
    }


    ngAfterViewInit() {
    }


    transformToTitleCase(value: string) {
        return this.titlecasePipe.transform(value);
    }


    continueToPortal(): void {
        console.log('login-component : continue to portal');
        if (this.authService.validAccessTokenExists()) {
            console.log('login-component : Valid access token exists. go to home.');
            this.navigateToHome(0);
        } else if (this.authService.validRefreshTokenExists()) {
            // this.useRefreshToken();
            console.log('login-component : else - valid refresh token. lets refesh it now');
            this.pageStatus.isSubmitting = true;

            this.authService.refreshAccesTokenNow()
                .subscribe((resp) => {
                    console.log('[**debug**] token was refreshed');
                    this.pageStatus.isSubmitting = false;
                    const redirectUrl = sessionStorage.getItem('redirect_url') ? sessionStorage.getItem('redirect_url') : '/redirect';
                    sessionStorage.removeItem('redirect_url');
                    this.redirect(redirectUrl);
                });
        } else {
            console.log('login-component : No access token or refresh token. redirect to login');
            sessionStorage.removeItem('redirect_url');
            this.router.navigateByUrl('/');
        }
    }

    submitForm(f: FormGroup): void {
        //lets obfuscate the password here
        f.markAllAsTouched();
        console.log("LOG USER IN",  f);

        if (!f.valid) { return; } //exit if not valid

        this.pageStatus.isSubmitting = true;
        this.setError(false);

        this.loginService.postLogin(f.value.email, f.value.password)
            .subscribe((resp) => {
                console.log("RESULT:", resp);
                if ((resp.status === 200 || resp.status === 201) && resp.body?.access_token) {
                    this.appInsightService.logEvent('User login | emailpassword', {email: f.value.email});

                    const redirectUrl = sessionStorage.getItem('redirect_url');
                    sessionStorage.removeItem('redirect_url');

                    this.authService.setSession(resp.body?.access_token, resp.body?.refresh_token, resp.body?.id_token) ? this.navigateToHome(300, redirectUrl) : this.navigateToHome(2000, redirectUrl);
                    this.ref.detectChanges();

                } else {
                    this.setError(true, f.value.email);
                    this.pageStatus.isSubmitting = false;
                    this.ref.detectChanges();
                }
            }, (err) => {
                console.log('error', err);
                this.setError(true, f.value.email);
                f.markAsPristine();
                this.pageStatus.isSubmitting = false;
                this.ref.detectChanges();
            })
    }

    setError(bool: boolean, email?: string, message?: string) {
        this.signInForm.markAsPristine();
        this.pageStatus.isError = bool;
        this.pageStatus.errorMessage =  message ? message : "Username or password incorrect";
        bool ? this.appInsightService.logEvent('Username or password incorrect | emailpassword', {email: email }) : ' ';
    }


    navigateToHome(waittime: number, redirectUrl?: string) {
        // this.navigateByUrl('/redirect');
        //need to update this to use set and use the original url
        setTimeout(() => {

            if (redirectUrl) {
                this.router.navigateByUrl(redirectUrl)
            } else {
                if (this.authService.is_system_admin()) {
                    this.router.navigateByUrl('/admin/dashboard');
                } else if (this.authService.is_customer_admin() ) {
                    this.router.navigateByUrl('/organisation');
                } else {
                    console.log('redirect to account if no valid roles found.');
                    this.router.navigateByUrl('/account');
                }
            }
        }, waittime);
    }

    redirect(uri: string) {
        // this.redirect('//place your uri here');
        // this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
        //this is addressing an infinite loop problem that was occuring on refresh token use and redirect
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
            this.router.navigate([uri]));
    }
    private getDecodedToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    navigateByUrl(url) {
        this.router.navigateByUrl(url);
    }

    signOut() {
        this.pageStatus.isSubmitting = true;
        this.authService.logout();
        this.existingToken = false;
        this.pageStatus.isSubmitting = false;
        this.ref.detectChanges();
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    //use this method to destroy component on navigating away from component
    @HostListener('unloaded')
    ngOnDestroy() {
        console.log('login destroyed');
        this.pageStatus.isLoading = false;
        this.pageStatus.pageLoading = false;
        this.refreshtoken_ ? this.refreshtoken_.unsubscribe() : null;
        this.sessionSubscription ? this.sessionSubscription.unsubscribe() : null;
    }

}
