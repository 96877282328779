import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth-service/auth.service';
import { AppTitleService } from '../../services/helpers/update-title.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
@Component({
    templateUrl: './redirect.component.html'
})
export class RedirectComponent {

    public viewAs: string;


    constructor(
        private router: Router,
        private appTitleService: AppTitleService,
        private authService: AuthService,
        private cookieService: CookieService,
        private ref: ChangeDetectorRef
    ) {
        this.appTitleService.setTitle('PiPcall: home');
        // const impersonate = this.cookieService.check('impersonateUser');

        // console.log("REDIRECT COMPONENT CONSTRUCTOR");

        // (this.is_system_admin() && !impersonate) ? this.navigateToSysAdminView() : this.navigateToCustomerView();
    }

    ngOnInit() {
        const impersonate = this.cookieService.check('impersonateUser');
        console.log("[redirect component].ngOninit");
        (this.is_system_admin() && !impersonate) ? this.navigateToSysAdminView() : this.navigateToCustomerView();
        this.ref.detectChanges();
    }

    is_system_admin(): boolean {
        return this.authService.is_system_admin();
    }

    navigateToSysAdminView() {
        return this.router.navigateByUrl('/admin/dashboard');
    }

    navigateToCustomerView() {
        console.log("[redirect component].navigateToCustomerView");
        return this.router.navigateByUrl('organisation');
    }

}


