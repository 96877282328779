import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';
//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { ModalService } from '@app/services/modal-service/modal.service';

//component
import { OrgTabSystemComponent } from './org-system.component'

import { ConfirmBillingStateModalModule } from '@app/components/modals/confirm-billing-state-modal/confirm-billing-state-modal.module';
import { OrgPbxMigrationPanelModule } from '@app/components/tabs-panel/org-pbx-migration-panel/org-pbx-migration-panel.module';
import { OrgDirectoriesPanelModule } from '@app/components/tabs-panel/org-directories-panel/org-directories-panel.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        ConfirmBillingStateModalModule,
        OrgDirectoriesPanelModule,
        OrgPbxMigrationPanelModule,
        UniversalComponentsModule
    ],
    declarations: [OrgTabSystemComponent],
    exports: [OrgTabSystemComponent],
    providers: [AppInsightService, OrganisationService, ModalService],
    entryComponents: []
})
export class OrgTabSytemModule { }
