/* eslint-disable max-len */
import { Component, OnInit, isDevMode, ChangeDetectorRef , HostListener, Renderer2, ViewChild} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '@app/services/auth-service/auth.service';
import { FormGroup, Validators, FormBuilder, ValidationErrors, FormControl, Form, AbstractControl, FormsModule } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { ModalService } from '@app/services/modal-service/modal.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { map, tap, filter, catchError, mergeMap, finalize, distinctUntilChanged } from 'rxjs/operators';
import { AppTitleService } from '@app/services/helpers/update-title.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { fadeInUpOnEnterAnimation, fadeInRightOnEnterAnimation, fadeOutLeftOnLeaveAnimation, fadeInLeftOnEnterAnimation, fadeOutRightOnLeaveAnimation } from 'angular-animations';
import { CookieService } from 'ngx-cookie-service';
import { SignupService, SignupRequest } from '@app/services/pipcall/signup.service';
import { ValidateEmailNotTaken } from '@app/validators/async-email.validator';
import { EmailMatchValidation } from '@app/validators/emailMatch.Validator';
import { BlacklistService} from '@app/services/helpers/domain-blacklist.service';
import { GA4Service } from '@app/services/google-analytics-service';
import { ValidateEmailDomainNotBlacklisted } from '@app/validators/domain-blacklist.validator';

export interface IpinfoResponse {
    ip?: string;
    hostname?: string;
    city?: string;
    region?: string;
    country?: string;
    loc?: string;
    org?: string;
    postal?: string;
    timezone?: string;
}

@Component({
    selector: 'card-signup-request',
    templateUrl: './card_signup-request.component.html',
    styleUrls: ['./card_signup-request.component.scss'],
    animations: []
})
export class CardSignupRequestComponent implements OnInit {

    public isDev: boolean;
    public appversion: string = environment.appversion;
    ipInfo: IpinfoResponse | null = null;

    signUpForm: FormGroup;

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isError: false,
        errorMessage: '',
        step: 0
    }


    isTouched = false;
    domainBlacklist = [];

    constructor(
        public dialog: MatDialog,
        private appInsightService: AppInsightService,
        private snackBar: MatSnackBar,
        private modalService: ModalService,
        public auth: AuthService,
        public router: Router,
        public route: ActivatedRoute,
        private appTitleService: AppTitleService,
        private cookieService: CookieService,
        private signupService: SignupService,
        private _fb: FormBuilder,
        private gA4Service: GA4Service,
        private http: HttpClient,
        private blacklistService: BlacklistService,
        private activatedRoute: ActivatedRoute
    ) {

        this.appTitleService.setTitle('PiPcall: Signup');
        this.appInsightService.logPageView('Signup: form');
    }

    get f () { return this.signUpForm.controls }
    get hasExistingToken(): boolean {
        // If a valid access token exists OR a valid refresh token exists, return true
        return this.cookieService.check('access_token') && this.auth.isAuthenticated() ? true : this.cookieService.check('refresh_token') ? true : false;
    }

    ngOnInit() {

        if (this.hasExistingToken) { this.navigateByUrl('/redirect'); }


        this.signUpForm = this._fb.group({
            details: this._fb.group({
                email: ['', [Validators.email, Validators.required],  Validators.composeAsync([ValidateEmailNotTaken.createValidator(this.signupService)])],
                location: [''],
                source: [''],
                product: ['esim']
            }),
            recaptcha: this._fb.group({
                recaptcha: ['', Validators.required]
            })
        });


        //track in GA4 if the form has been touched.
        this.signUpForm.valueChanges.subscribe(val => {
            if (this.isTouched) {return} else {
                this.isTouched = true;
                this.gA4Service.sign_up_start('esim');
            }
        });

        this.setLocationInfo(); //optionally turn off for dev mode
        this.blacklistService.loadBlacklist();
        this.setSourceData();
    }

    ngAfterViewInit() {
    }

    setLocationInfo() {
        const url = 'https://ipinfo.io?token=e9d1dc48b98e14';
        this.http.get(url)
            .take(1)
            .subscribe(
                data => {
                    this.ipInfo = data;
                    const location = this.ipInfo.city + ', ' + this.ipInfo?.region + ', ' + this.ipInfo?.country;
                    this.f.details.get('location').patchValue(location);
                    this.appInsightService.logEvent('IP Info', this.ipInfo);
                    console.log('IP Info:', location);
                },
                error => {
                    this.appInsightService.logEvent('IP Info error', error?.message);
                    console.error('Error fetching IP info:', error);
                }
            );
    }

    triggerRecaptcha() {
        //triggerd by continue button
        this.pageStatus.isSubmitting = true;
        grecaptcha.execute();
    }

    resolveRecaptcha(event) {
        //this is called by the recaptcha callback after triggering and resolving the recaptcha, if successfull. Add logic to continue
        console.log("recaptcha event:", event);
        this.pageStatus.isError = false;
        this.f?.recaptcha.get('recaptcha').patchValue(event);
        this.signUpForm.updateValueAndValidity();

        if (event) {
            this.submitSignupRequest(); //continue to submit the form
        }

    }

    //checkemail blacklist at later point (?).

    submitSignupRequest() {
        console.log('submitSignupRequest()');

        this.pageStatus.isSubmitting = true;

        if (!this.signUpForm.valid) {
            alert('Sorry, there was a problem. Please contact support if this continues.');
            this.pageStatus.isSubmitting = false;
            return;
        }

        // check for signup_complete cookie. If it exists, reject signup
        if (this.cookieService.check('signup_complete') && isDevMode() === false) {
            this.pageStatus.isSubmitting = false;
            alert('Too many signups. Please try again later.');
            return
        }

        if (this.blacklistService.isDomainBlacklisted(this.f.details.value.email) === true) {
            //reject this email
            this.pageStatus.isSubmitting = false;
            this.appInsightService.logEvent('Signup blocked due to blacklisted domain | Signup', { 'email': this.f.details.value.email });
            alert('Signups from this domain are barred. If you think this was an error, please contact support@pipcall.com');
            return
        }

        console.log('submitSignupRequest() all good - continue');

        this.gA4Service.sign_up_pipcall_stage1_complete();
        const signupFormValues: SignupRequest = {
            email: this.f.details.value.email,
            recaptcha: this.f.recaptcha.value.recaptcha,
            product: this.f.details.value.product,
            location: this.f.details.value.location ? this.f.details.value.location : null,
            source: this.f.details.value.source ? this.f.details.value.source : null,
        }

        console.log("[signup].submitSignupRequest()", this.signUpForm.value, this.f);
        this.signupService.postSignupRequest(signupFormValues)
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false })
            )
            .subscribe((resp) => {
                this.appInsightService.logEvent('Form submitted | Signup', { 'email': this.f.details.value.email });

                if (resp.status === 200 || resp.status === 201) {
                    if (resp.body?.organisation_signup_request_id) {
                        // this.gA4Service.sign_up_pipmobile_stage1_complete(); //udate to new value
                        //ADD A COOKIE HERE TO STOP REPEAT SIGNUPS FOR SPECIFED PERIOD OF TIME. TURN THIS OFF FOR DEV MODE -ALSO (?) ADD THIS AT VERIFICATION STEP.
                        this.router.navigate([resp.body.organisation_signup_request_id, 'verify'], {
                            relativeTo: this.activatedRoute // assuming this.activatedRoute is the current route
                        });
                    } else {
                        this.openSnackBar("Sorry, we are currently experiencing a problem with signup. Please contact support@pipcall.com if this continues.", "dismiss");
                    }
                }
            }, (err) => {
                console.log(err);
                if (err.status === 500) { this.openSnackBar("Something went wrong", "dismiss") };
                if (err.status === 412) {
                    this.resetRecaptcha();
                    this.signUpForm.updateValueAndValidity();
                    alert('Recaptcha has expired. Please try again.')
                }
            })



    }

    resetRecaptcha() {
        grecaptcha.reset();
        this.f.recaptcha.get('recaptcha').patchValue('');
    }

    setSourceData(): void {
        console.log('[**debug**] Setting source data')
        const referalcookie = this.cookieService.get('referalcookie');
        //check if the website has set a referal cookie
        if (referalcookie) {

            try {
                const decodedQueryString = decodeURIComponent(referalcookie);
                const urlParams = new URLSearchParams(decodedQueryString);

                this.f.details.get('source')?.patchValue(referalcookie);

                // Construct the referer JSON object
                const refererJSON = {
                    medium: urlParams.get('utm_medium'),
                    campaign: urlParams.get('utm_campaign'),
                    source: urlParams.get('utm_source') || urlParams.get('referer'),
                    term: urlParams.get('utm_term'),
                    keywords: urlParams.get('hsa_kw'),
                    raw: referalcookie
                };

                // Log the referral event
                this.appInsightService.logEvent('Signup : referral', refererJSON);

                // Update the form value with the source
                console.log('set sourve:', this.f.details.get('source')?.value);

            } catch (error) {
                console.error('[**error**] Error processing source data:', error);
            }
        } else {
            console.warn('Referral cookie not found.');
        }

    }

    navigateByUrl(url) {
        this.router.navigateByUrl(url);
    }
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }



}
