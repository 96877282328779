/* eslint-disable max-len */
import { environment } from '../../../environments/environment';
import { Component, OnInit, isDevMode, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '@app/services/auth-service/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { ModalService } from '@app/services/modal-service/modal.service';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';

//normalizing number
import { AppTitleService } from '@app/services/helpers/update-title.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { CookieService } from 'ngx-cookie-service'
import { fadeInOnEnterAnimation, fadeInRightOnEnterAnimation, fadeInLeftOnEnterAnimation, slideInUpOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { finalize } from 'rxjs/operators';
import { LoginService , ResetPasswordRequest} from '@app/services/pipcall/login.service';

@Component({
    selector: 'app-setpassword-page',
    templateUrl: './setpassword.component.html',
    styleUrls: ['./setpassword.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ]),
        trigger('fadeInOut', [
            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({ opacity: 1 })),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({ opacity: 0 }),
                animate(600)
            ]),
            // fade out when destroyed. this could also be written as transition('void => *')
            // transition(':leave',
            //     animate(100, style({ opacity: 0 })))
        ]),
        slideInUpOnEnterAnimation(),
        fadeInLeftOnEnterAnimation({ anchor: 'enter' }),
        fadeInOnEnterAnimation({ anchor: 'onLoadEnter', duration: 800, delay: 0 }),
        fadeOutOnLeaveAnimation({ anchor: 'leave', duration: 500, delay: 0 })

    ]
})
export class SetpasswordV2Component implements OnInit {

    public isDev: boolean;
    public appversion: string = environment.appversion;
    public copyrightMessage = environment.copyrightMessage;


    //forgot my password
    forgotPWForm: FormGroup;

    public user_password_request_id = '';
    public pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isSuccess: false,
        isError: false,
        errorMessage: '',
        step: 0
    }

    //verify and set my password
    verificationForm: FormGroup;
    public pwType: 'password' | 'text' = 'password';

    public displayCopy = 'Forgot password?';

    constructor(
        public dialog: MatDialog,
        private modalService: ModalService,
        public auth: AuthService,
        public router: Router,
        public route: ActivatedRoute,
        private appTitleService: AppTitleService,
        private appInsightService: AppInsightService,
        private cookieService: CookieService,
        private loginService: LoginService,
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private ref: ChangeDetectorRef
    ) {
        this.pageStatus.isLoading = true;
    }


    ngOnInit() {
        console.log('setpassword component loaded');

        if (this.router.url === '/setpassword') {
            this.displayCopy = 'Set a new password';
        } else if (this.router.url === '/forgot') {
            this.displayCopy = 'Forgot password?';
        }

        this.forgotPWForm = this._formBuilder.group({
            email: ['', [Validators.email, Validators.required]]
        });

        const passwordRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$');

        this.verificationForm = this._formBuilder.group({
            password: ['', [Validators.required, Validators.pattern(passwordRegex)]],
            verificationCode: ['', [Validators.required]],
            request_id: ['', [Validators.required]]
        });

        this.pageStatus.step = 0;
        this.pageStatus.isLoading = false;

        setTimeout(() => {
            this.ref.detectChanges();
        }, 700);
    }

    ngAfterViewInit() {

    }


    navigateByUrl(url) {
        this.router.navigateByUrl(url);
    }

    next() {
        this.pageStatus.step = 0;
        setTimeout(() => {
            this.pageStatus.step = 1;
            this.appTitleService.setTitle('PiPcall: Verify new password');
            this.appInsightService.logPageView('New password: verify');
        }, 180);
    }

    onCodeChanged(code: string) {
        console.log("code changed", code);
    }

    // this called only if user entered full code
    onCodeCompleted(code: string) {
        this.verificationForm.controls.verificationCode.patchValue(code);
    }
    submitForm(email: string): void {
        // this.forgotPWForm
        console.log("EMAIL:", email);
        this.pageStatus.isSubmitting = true;
        this.loginService.postResetPassword(email)
            .pipe(
                finalize(() => this.pageStatus.isSubmitting = false)
            )
            .subscribe(
                (resp) => {
                    this.verificationForm.controls['request_id'].setValue(resp.body?.user_password_request_id);
                    this.next();
                },
                (err) => {
                    console.log('error here', err)
                    this.pageStatus.errorMessage = err?.error;
                    this.pageStatus.isError = true;
                    this.ref.detectChanges();
                })
    }

    verifyCode(f: FormGroup) {
        const request_id = f.value.request_id;
        const code = f.value.verificationCode;
        const password = f.value.password;

        this.pageStatus.isSubmitting = true;
        this.pageStatus.isError = false;
        this.loginService.posVerifytResetPassword(request_id, code, password)
            .pipe(
                finalize(() => this.pageStatus.isSubmitting = false)
            )
            .subscribe(
                (resp) => {
                    if ((resp.status === 200 || resp.status === 201) && resp.body.is_valid_code && resp.body?.access_token) {
                        this.displayCopy = 'Success!'
                        this.pageStatus.step = 2;
                        this.auth.setSession(resp.body?.access_token, resp.body?.refresh_token, resp.body?.id_token) ? this.navigateToHome(300) : this.navigateToHome(2000);
                    } else if (resp.body.is_code_expired) {
                        this.pageStatus.errorMessage = 'Password change request has expired';
                        this.pageStatus.isError = true;
                    } else if (!resp.body.is_valid_code) {
                        this.pageStatus.errorMessage = 'Verification code is not valid';
                        this.pageStatus.isError = true;
                    }
                },
                (err) => {
                    console.log(err);
                    switch (err?.status) {
                        case 404:
                            // this.pageStatus.errorMessage = err?.error?.Message;
                            this.pageStatus.errorMessage = err?.error ? err?.error : 'The code was invalid.';
                            this.pageStatus.isError = true;
                            break;
                        case 400:
                            // this.pageStatus.errorMessage = err?.error?.Message;
                            this.pageStatus.errorMessage = err?.error ? err?.error : 'Password has previously been used or has ilegal characters';
                            this.pageStatus.isError = true;
                            break;
                        default:
                            this.pageStatus.errorMessage = 'An unspecified error occured.';
                            this.pageStatus.isError = true;
                            break;
                    }
                }
            )
    }

    navigateToHome(waittime: number) {
        setTimeout(() => {
            this.router.navigateByUrl('/redirect');
        }, waittime);
    }

    cancelVerification() {
        this.ngOnInit();
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

}
