
//requirements
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SaveQueryModalComponent } from './save-query-modal.component';
import { UserDataService } from '@app/services/pipcall/user-data.service';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule
    ],
    declarations: [SaveQueryModalComponent],
    exports: [SaveQueryModalComponent],
    providers: [UserDataService],
    entryComponents: [SaveQueryModalComponent]
})
export class SaveQueryModallModule {}
