import { Injectable } from '@angular/core';
import { HttpProxy } from '../../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from 'src/environments/environment';
import { PortalApiV2HelperService, QueryParams } from '@app/services/helpers/portal-api-v2-helper';
import { map, tap, filter, catchError, mergeMap, finalize, distinctUntilChanged } from 'rxjs/operators';
import { escape, unescape} from 'safe-string-literal';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { delay } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

import { EsimUsageModel } from '@app/models/esim.open.model';

export {EsimUsageModel};


@Injectable()
export class EsimOpenService {


    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`;
    public options;


    constructor(private httpProxy: HttpProxy, private portalApiV2HelperService: PortalApiV2HelperService) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }

    public getEsimUsage(esim_id: string): Observable<HttpResponse<EsimUsageModel>> {
        console.log("[[api]] -> get esim usage by id");
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/esim/telna/${esim_id}/usage${this.code}`, 'response', false);
    }


}
