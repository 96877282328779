<ng-container [ngSwitch]="version">
  <!-- new implemenation table view -->
  <ng-container *ngSwitchCase="1">

    <div  class="cto-row-container fx-row fx-justify-start fx-align-center fx-gap-08">
      <div class="avatar-container av-size-30">
        <img appGravatar [firstname]="first_name" [lastname]="last_name" [email]="email">
      </div>

      <div class="fx-col fx-justify-center fx-align-start">

        <div class="hover-to-show cto-top-row-wrapper clickable" >

          <a  class="overflow-elipsis cto-max-width" style="color: var(--c-url);" href="/admin/user/{{user_id}}/profile" matTooltipPosition="below" matTooltipShowDelay="200"
          matTooltip="Open {{first_name}}">{{first_name}}&nbsp;{{last_name}}</a>
          
          <mat-icon (click)="openUsernDetails(user_id, viewAs, organisation_id)" matSuffix svgIcon="view"
          (click)="$event.stopPropagation()" class="clickable action-icon" matTooltipPosition="below"
          matTooltipShowDelay="200" matTooltip="Quick view"></mat-icon>

          <mat-icon *ngIf="user_id" (click)="impersonateUser(user_id)" matSuffix svgIcon="impersonate"
          (click)="$event.stopPropagation()" class="clickable action-icon" matTooltipPosition="below"
          matTooltipShowDelay="200" matTooltip="Impersonate user"></mat-icon>
        </div>
        
        <div *ngIf="email && !hide_detail" class="cto-bottom-row-wrapper" >
          <span class="overflow-elipsis cto-bottom-row-content cto-max-width cto-wd-230"
          [matTooltip]="email">{{email}}&nbsp;</span>
        </div>
      </div>

    </div>
  </ng-container>


  <ng-container *ngSwitchDefault>

   
  </ng-container>
</ng-container>