
//core requirements
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomPipesModule } from '@app/pipes/pipes.module';

//declare components
import { SvgSimpleChartComponent } from './svg-simple-chart.component';

@NgModule({
    imports: [
        CommonModule,
        CustomPipesModule,
    ],
    declarations: [SvgSimpleChartComponent],
    providers: [],
    exports: [SvgSimpleChartComponent],
    entryComponents: []
})
export class SvgSimpleChartModule {
}

