import {Component, TemplateRef, ViewChild, Inject} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { UserProfile } from '@app/models/user-profile.model';
import { OrganisationModel } from '@app/models/organisation.model';
import { UserService } from '@app/services/pipcall/user.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subscription, Observable, throwError, Subject } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@app/material/material-essentials.module';
import { UpdateUserProfilePictureModalComponent} from '@app/components/modals/update-userprofile-picture-modal/update-userprofile-picture-modal.component';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatDialog } from '@app/material/material-essentials.module';
import {
    MAT_BOTTOM_SHEET_DATA,
    MatBottomSheet,
    MatBottomSheetRef,
    MatBottomSheetConfig
} from '@angular/material/bottom-sheet';


@Component({
    selector: 'bs-userprofile-bsheet-modal',
    templateUrl: './userprofile-bsheet-modal.component.html',
    styleUrls: ['./userprofile-bsheet-modal.component.scss']
})
export class UserProfileBottomSheetComponent {


    public viewAs: string; //owner,admin or user. set to owner if matches on return of data.
    public isloading = true;
    public notFound: boolean = null;
    public userProfile: UserProfile;
    public user_id: string;
    public organisation_id: string;
    public organisationProfile: OrganisationModel;
    // public organisationProfile: OrganisationModel;
    // public organisationUserList: Array<UserProfile>;


    // public first_name: string;
    // public last_name: string;
    public userUrl = window.location.origin;

    public pageStatus = {
        activeTab: '',
        isLoading: true,
        noContent: false,
        dataChanged: false
    }
    _destroy$ = new Subject<void>();

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: {viewAs: string, user: UserProfile, organisation_id: string, user_id: string },
        private _bottomSheetRef: MatBottomSheetRef<UserProfileBottomSheetComponent>,
        private userService: UserService,
        private ref: ChangeDetectorRef,
        private organisationService: OrganisationService,
        public router: Router,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
    ) {

        console.log("DATA:", this.data);

        this.viewAs = data.viewAs;
        this.userProfile = data?.user ? data?.user : null;
        this.organisation_id = data.organisation_id;
        this.user_id = data.user_id;

        this.userUrl = window.location.origin + '/admin/user/' + this.user_id + '/profile';
        this.viewAs === 'sysAdmin' ? this.pageStatus.activeTab = 'system' : this.pageStatus.activeTab = 'overview';
    }

    ngOnInit() {
        //handle missing data
        // this.first_name = this.userProfile.first_name;
        // this.last_name = this.userProfile.last_name;

        this.pageStatus.isLoading = true;

        if (!this.userProfile) {
            console.log("NO USER PROFILE . GO AND GET IT.");
            this.getData();
        } else {
            console.log("THERE IS A PROFILE. LOADING IS FALSE NOW", this.userProfile);
            this.pageStatus.isLoading = false; }

        if (!this.organisationProfile) {
            this.getUserOrganisation(this.organisation_id)
        }

        //if no Organisation. Get it now.
    }

    getData() {
        if (!this.user_id) {this.pageStatus.noContent = true; this.pageStatus.isLoading = false; return; }
        this.userService.getUser(this.user_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false; }),
                takeUntil(this._destroy$),
            ).subscribe((resp) => {
                this.userProfile = resp.body;
                this.pageStatus.noContent = false;
                this.ref.markForCheck();
            }, (err) => {

                // if (err.status === 404) {this.dismiss()}
                console.log(`Error getting user details [${err.status}]`)})
    }

    getUserOrganisation(organisation_id: string) {
        this.organisationService.getOrganisationById(organisation_id)
            .subscribe((resp) => {
                this.organisationProfile = resp.body;
                this.ref.markForCheck();
            }, (err) => {console.log(`Error getting user details [${err.status}]`)})

    }

    updateParentEvent(event) {
        console.log("EVENT:", event);

        if (event === 'close') {this.dismiss()}

    }
    refreshData(event) {
        console.log("refresh data EVENT:", event);
        this.getData();
        this.pageStatus.dataChanged = true;
    }

    openProfileURL(user_id: string) {
        this._bottomSheetRef.dismiss();
        this.router.navigateByUrl('/admin/user/' + user_id + '/profile');
        // http://localhost:4200/admin/user/auth0|5e99cee366f77d0c718217fa/profile
    }

    dismiss() {
        this._bottomSheetRef.dismiss(this.pageStatus.dataChanged);
    }

    changeProfilePicture() {
        const dialogRef = this.dialog.open(UpdateUserProfilePictureModalComponent, {
            panelClass: 'pipcall-modal-container',
        });

        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                this.updateParentEvent(true);
            }

        });
    }

    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}` , "dismiss");
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
}

