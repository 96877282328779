import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'epochToDate'
})
export class EpochToDatePipe implements PipeTransform {

    transform(value: number, format = 'yyyy-MM-dd HH:mm:ss'): string | null {
        if (!value) {
            return null;
        }

        const date = new Date(value); // Convert epoch to milliseconds
        return date.toLocaleString(); // Modify as needed for formatting
    }
}
