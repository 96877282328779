<div class="card-wrapper prefer-light-mode">
    <h2 class="title-font">Sign up</h2>

    <p class="subtitle-top">Ready to go in just a few seconds</p>

    <form [formGroup]="signUpForm" style="width:100%;">
        <div formGroupName="details">
        <mat-form-field  style="width: 100%;">
            <input type="email" matInput placeholder="Email" autocomplete="email" formControlName="email">
            <mat-icon matSuffix svgIcon="email_v2"></mat-icon>
 
            <mat-error *ngIf="f?.details?.get('email')?.touched && f?.details?.get('email')?.errors?.required">
              Required
              Field</mat-error>
            <mat-error *ngIf="f?.details?.get('email')?.touched && f?.details?.get('email')?.errors?.email">
              not a valid email</mat-error>
            <mat-error *ngIf="f?.details?.get('email')?.status === 'PENDING'">
              checking ...
            </mat-error>
            <mat-error *ngIf="f?.details?.get('email')?.errors && f?.details?.get('email')?.errors.emailTaken">
              Email already in use.
            </mat-error>
            <mat-error *ngIf="f?.details?.get('email')?.errors && f?.details?.get('email')?.errors?.blacklisted">
              Signups from this domnain is not permitted.
            </mat-error>
            <mat-label>Email</mat-label>

            <status-icons *ngIf="f?.details?.get('email')?.touched" [iconStatus]="f?.details?.get('email')?.status">
            </status-icons>

          </mat-form-field>
        </div>
        </form>

        <div class="tc-container">By continuing, you agree to the <a tabindex="-1"  href="https://www.pipcall.com/downloads/portal-terms-and-conditions">Terms & Conditions</a> and <a tabindex="-1" href="https://www.pipcall.com/downloads/customer-privacy-policy">Privacy Policy</a>.</div>
        <div class="submit-container">
            <button mat-flat-button color="accent"
            [disabled]="!f?.details.valid || pageStatus?.isSubmitting"
            (click)="$event.preventDefault()" (click)="triggerRecaptcha()">
            <ng-container *ngIf="!pageStatus.isSubmitting; else spinner">Continue</ng-container>
        </button>
        </div>

        <div class="sign-in-button" ><a  (click)="$event.preventDefault()" (click)="navigateByUrl('/login')" matTooltip="Go to sign in">Already have an account?</a></div>
        <re-captcha size="invisible" badge="bottomright" #invisible
        (resolved)="resolveRecaptcha($event)"></re-captcha>

</div>


<ng-template #spinner>
    <div>
        <span class="loading-icon">
            <div class="loader-spinner"></div>
        </span>
    </div>
</ng-template>