<div class="cc-v-page-wrap">

    <cc-floating-card [showCopyRight]="true" [showBrandLogo]="true">

          <ng-container *ngIf="pageStatus?.pageLoading; else pageLoaded">
            <div class="loading" style="text-align: center;position: relative;top: 180px;">
              <div>
                <span class="loading-icon">
                  <div class="loader-spinner"></div>
                </span>
              </div>
            </div>
          </ng-container>
  
          <ng-template #pageLoaded>
  
            <ng-container *ngIf="!hasExistingToken;else continueOption">
  
              <h1 class="sign-in-card-title">Sign in</h1>
              <p>Enter your credentials to access the PiPcall Portal.</p>
              <div class="main-content">
                <div class="fullheight-block" style="display:flex;flex-direction: column;">
  
                  <form [formGroup]="signInForm" fxLayout="column"
                    [ngClass]="{'readonly-block': pageStatus.isSubmitting}">
  
                    <mat-form-field appearance="outline" floatLabel="never"
                      class="custom-form-field-v3  no-form-label">
                      <input type="email" matInput placeholder="Email" autocomplete="username" formControlName="email"
                        autofocus="false">
                      <mat-icon matSuffix svgIcon="email_v2"></mat-icon>
                      <mat-error
                        *ngIf="signInForm?.controls.email?.touched && signInForm?.controls.email?.errors?.required">
                        Email is required</mat-error>
                      <mat-error *ngIf="signInForm?.controls.email?.touched && signInForm?.controls.email?.errors?.email">
                        Email address is not valid</mat-error>

                    </mat-form-field>
  
                    <mat-form-field appearance="outline" floatLabel="never" class="custom-form-field-v3 no-form-label">
                      <input type="{{pwType}}" matInput placeholder="Password" autocomplete="current-password"
                        formControlName="password" (keyup.enter)="submitForm(signInForm)" autofocus="false">
                      <mat-icon matSuffix class="clickable" *ngIf="pwType != 'password'" (click)="pwType = 'password'"
                        title="hide password" svgIcon="view"></mat-icon>
                      <mat-icon matSuffix class="clickable" *ngIf="pwType === 'password'" (click)="pwType = 'text'"
                        title="show password" svgIcon="view_off"></mat-icon>
                      <mat-error
                        *ngIf="signInForm?.controls.email?.touched && signInForm?.controls.email?.errors?.required">
                        Password is required</mat-error>
                    </mat-form-field>
  

                  </form>
  
                  <div *ngIf="pageStatus.isError && signInForm.pristine" class="error-message">
                    <span>{{pageStatus.errorMessage}}</span>
                  </div>
  
  
                </div>
  
                <div class="button-tray" fxLayout="column">
                  <button class="sign-in-button" color="accent" mat-flat-button (click)="submitForm(signInForm)"
                    [disabled]="pageStatus.isSubmitting" style="width: 100%;">
                    <ng-container *ngIf="!pageStatus.isSubmitting;else spinning">Sign In
                    </ng-container>
                    <ng-template #spinning><span class="loading-spinner"><i class="fas fa-spinner fa-spin"></i></span>
                    </ng-template>
                  </button>
                  <div fxLayout="row" fxLayoutAlign="end">
                    <button style="font-size: 14px;float:right;color: var(--c-title)" mat-button
                      [disabled]="pageStatus.isSubmitting" (click)="navigateByUrl('/forgot')">Forgot
                      password?</button>
                  </div>
                </div>
  
                <div fxLayout="row" fxLayoutAlign="center" class="alt-options-container">
                  <span>New user?</span>&nbsp;<a class="alt-link clickable" (click)="$event.preventDefault()"
                    (click)="navigateByUrl('/signup')">Sign up</a>
                </div>
              </div>
  
            </ng-container>
  
          </ng-template>
  
        
 
 
    </cc-floating-card>
    <div class="app-version">
      <span class="text-right version-details"> Version: {{appversion}} <span *ngIf="isDev">(DEV)</span></span>
    </div>

  
</div>

<ng-template #continueOption>
  <div fxLayout="column" fxFlex="100%" fxLayoutAlign="start center">

    <div fxLayout="column" fxFlex="100%" fxLayoutAlign="center center">
      

      <div class="profile-image-container" style="height:80px;width:80px;">
        <img *ngIf="userIdToken" style="border-radius: 50%;height:100%;width:100%;" appGravatar [fullname]="userIdToken?.name"
          [email]="userIdToken?.email? userIdToken?.email:''">

          <ng-container *ngIf="!userIdToken">
            <!-- this scenario might not happen anymore -->
            <svg width="100%" height="100%" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="profile-image-avatar-no-userprofile" d="M40.5 80C18.3838 80 0 61.8824 0 40C0 18.1569 18.3441 0 40.4603 0C62.6162 0 81 18.1569 81 40C81 61.8824 62.6559 80 40.5 80ZM40.5 38.7843C46.2971 38.8235 51.0221 33.9216 51.0221 27.4118C51.0221 21.3726 46.2971 16.3529 40.5 16.3529C34.7029 16.3529 29.9779 21.3726 29.9779 27.4118C29.9779 33.9216 34.7029 38.7451 40.5 38.7843ZM21.7985 60.549H59.2015C60.8691 60.549 61.6235 59.4902 61.6235 58C61.6235 53.6078 54.8735 42.1176 40.5 42.1176C26.1265 42.1176 19.3765 53.6078 19.3765 58C19.3765 59.4902 20.1309 60.549 21.7985 60.549Z" fill="currentColor"/>
            </svg>
          </ng-container>
      </div>


      <div style="margin-top: 12px;">
        <p>Welcome back, {{userIdToken?.name}}</p>
      </div>

    </div>

    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center">
      <div fxLayout="column" fxFlex="100%">
        <button class="login-button" color="accent" style="width:100%;" mat-flat-button
          (click)="continueToPortal()"><ng-container *ngIf="!pageStatus?.isSubmitting; else spinner">Continue to portal
          </ng-container></button>
        <button style="width:300px;margin-top: 8px;" mat-button (click)="signOut()">Sign Out</button>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #loading>
  <div fxLayoutAlign="center center">
    <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
      <div class="bounce4"></div>
    </div>
  </div>
</ng-template>


<ng-template #spinner>
  <div>
    <span class="loading-icon">
      <div class="loader-spinner"></div>
    </span>
  </div>
</ng-template>