import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {  MatSnackBar } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Subject, Subscription } from "rxjs";
import { map, takeUntil, tap, first, finalize, distinctUntilChanged } from "rxjs/operators";
import { SidenavRightService } from '@app/template/sidenav-right/sidenav-right.service'
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { slideInRightOnEnterAnimation, bounceInUpOnEnterAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, fadeOutAnimation, collapseAnimation } from 'angular-animations';
import { HelpCenterContentService, FAQS } from '@app/services/help-center-content.service';
import { Article} from '@app/services/pipcall/content.service';
import { AuthService} from '@app/services/auth-service/auth.service';
import { ContentService  } from '@app/services/pipcall/content.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { UserSessionService } from '@app/services/user-session.service';
@Component({
    selector: 'help-center-right-menu',
    templateUrl: './help-center-right-menu.component.html',
    styleUrls: ['./help-center-right-menu.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ]),
        slideInRightOnEnterAnimation({ duration: 200 }),
        bounceInUpOnEnterAnimation(),
        fadeOutOnLeaveAnimation({ duration: 800 }),
        fadeInOnEnterAnimation({ duration: 400 }),
        fadeOutAnimation(),
        collapseAnimation()
    ]

})

export class HelpCenterRightMenuComponent implements OnInit {

    data: any;
    _destroy$ = new Subject<void>(); //handles unsubscribing events
    public contextDataSubscription: Subscription;
    public articleSubscription: Subscription;

    pageRoute: string;

    private permissions: any;
    public _permissions = {
        faqAdmin: false,
        sysAdmin: false
    }

    pageStatus = {
        gettingContextData: false,
        isLoading: false,
        isSubmitting: false,
        view: 1,
    }
    //view 1 = home, 2 = FAQ item, 3 = editMode
    updated_by: string;
    viewAs: string;
    article: string;

    //FAQS
    public categoryList = new Array<string>(); //A list of all categories

    public resourceList = new Array<Article>(); //A list of items that make up the resource/external links list. e.g terms, glossary, etc
    // public latestList = new Array<Article>(); //the latest 3 articles

    public activeFAQitem = new Article; //the loaded faq item
    public editMode = 'edit'; //edit or new

    public faqList = new FAQS(); //contains articles, categories, lastRowKey
    public contextualArticles = new Array<Article>();

    constructor(
        private router: Router,
        private sidenavRightService: SidenavRightService,
        private appInsightService: AppInsightService,
        public snackBar: MatSnackBar,
        private helpCenterContentService: HelpCenterContentService,
        private authService: AuthService,
        private ref: ChangeDetectorRef,
        private modalService: ModalService,
        private contentService: ContentService,
        private userSessionService: UserSessionService
    ) {
        this.innit();
        this.getContextData();
        this.getArticles()
    }

    innit() {
        this.pageStatus.isSubmitting = false;
        this.pageStatus.isLoading = true;
        this.viewAs = 'user'; //default
    }

    ngOnInit() {
        this.appInsightService.logPageView('PiPcall: Help Center');

        this.permissions = this.authService.returnPermissions();
        this.permissions.includes("admin:faq") ? this._permissions.faqAdmin = true : this._permissions.faqAdmin = false;
        this.permissions.includes("admin:user") ? this._permissions.sysAdmin = true : this._permissions.sysAdmin = false;

        //get name of current user for updated by
        this.userSessionService.userProfileAsObservable.take(1).subscribe(userProfile => {
            this.updated_by =  userProfile.first_name + ' ' + userProfile.last_name;

        });

        //wait for ContextData to be set & getArticles to be called
        // if (this.pageStatus.isLoading !== true && this.pageStatus.gettingContextData !== true) {
        //     this.loadFAQitem(this.article)
        //     //load specific article, if there is one set in data.article
        // } else {
        //     setTimeout(() => {
        //         this.loadFAQitem(this.article);
        //     }, 1000);
        // }



    }

    startWithSpecificArticle(article: string) {
        //if a specific artlce was requested, load it once the data is ready. ideally pageloading should be an observable
        if (this.pageStatus.isLoading !== true) {
            this.loadFAQitem(article);
            this.pageStatus.gettingContextData = false
        } else {
            setTimeout(() => {
                this.startWithSpecificArticle(article);
            }, 400);
        }
    }

    getContextData() {
        //data passed to the right menu via the diaglog open
        //viewAs, article, url
        this.pageStatus.isLoading = true;
        this.pageStatus.gettingContextData = true;
        this.contextDataSubscription = this.sidenavRightService.getContextData$()
            .pipe(
                takeUntil(this._destroy$),
                first()
            ).subscribe((resp) => {
                console.log("context data:", resp);
                this.data = resp;
                this.pageRoute = this.data?.url;
                this.viewAs = this.data?.viewAs;
                this.data?.article ?  this.startWithSpecificArticle(this.data?.article) :  this.pageStatus.gettingContextData = false;
                return this.helpCenterContentService.faqsAsObservable
            },
            (err) => {
                // this.pageStatus.isLoading = false;
                this.pageStatus.gettingContextData = false;
                console.log("error getting context data");
            })
    }

    setContextualArticles(articles: Article[]) {
        //any articles to show related to this page route
        const context = this.helpCenterContentService.returnContextForRoute(this.pageRoute);

        const result = articles.filter((article) => {
            return article?.context_links?.includes(context);
        });

        if (result?.length > 0) {
            //return latest matching top 3 articles
            this.contextualArticles = [...result].sort((a, b) => b.Timestamp.localeCompare(a.Timestamp)).slice(0, 3);
            this.contextualArticles = result;
            this.ref.markForCheck();
        } else {
            //return the latest 3 articles instead
            this.contextualArticles = [...articles].sort((a, b) => b.Timestamp.localeCompare(a.Timestamp)).slice(0, 3);
            this.ref.markForCheck();
        }

    }

    getArticles() {
        this.articleSubscription = this.helpCenterContentService.faqsAsObservable
            .subscribe(resp => {
                console.log('====FAQ list as observable', resp);
                this.faqList = resp;
                console.log("SORTED:", this.faqList?.articles)//resp.articles;
                this.pageStatus.isLoading = false;
                // this.setLatest(this.faqList?.articles);
                this.setResource(this.faqList?.articles);
                this.categoryList = [...resp.categories].filter(c => c !== 'resource'); //remove resource from category list
                console.log("THIS CATEGORY LIST", this.categoryList);
                this.setContextualArticles(resp.articles);

                this.ref.markForCheck();

            }, (err) => {
                this.pageStatus.isLoading = false;
                console.log("error getting data");
            })
    }

    // setLatest(articleList: Article[]) {
    //     //return 5 latest to be updated
    //     this.latestList = [...articleList].sort((a, b) => b.Timestamp.localeCompare(a.Timestamp)).slice(0, 3);
    // }

    setResource(articleList: Article[]) {
        //first 5 rowKeys reserved for resource
        const rowkeys = ['1', '2', '3', '4', '5'];
        this.resourceList = articleList.filter(a => rowkeys.includes(a.RowKey) && a.is_active === true);
    }

    loadFAQitem(RowKey: string) {
        this.pageStatus.isLoading = true;
        this.activeFAQitem = this.faqList?.articles.find(a => a.RowKey === RowKey);
        if (!this.activeFAQitem) {
            this.pageStatus.view = 1;
        } else {
            this.pageStatus.view = 2;
        }
        this.pageStatus.isLoading = false;
    }

    selectedArticleUpdate(event) {
        if (event) {
            this.loadFAQitem(event.RowKey);
        }
    }

    editArticle(mode: string) {
        this.editMode = mode;
        this.pageStatus.view = 3;
    }

    stepBack(step?: number) {
        //set step or increment by -1

        if (step) {
            this.pageStatus.view = step;
        } else {
            this.pageStatus.view  > 1 ? this.pageStatus.view =  this.pageStatus.view - 1 : null;
        }


    }

    cancelEdit(event) {
        console.log("CANCEL EDIT", event);
        if (event) {
            //update FAQ list
            this.helpCenterContentService.updateContent();
            this.ref.markForCheck();
        }

        if (this.editMode === 'new') {
            this.stepBack(1);
            this.ref.markForCheck();
        } else {
            this.stepBack();
        }

    }

    close() {
        this.sidenavRightService.setOpenState(false);
    }

    getData() {
        this.pageStatus.isLoading = true;
        setTimeout(() => {
            this.pageStatus.isLoading = false;
        }, 6000);
    }
    reset() {
        this.pageStatus.view = 1;
        this.activeFAQitem = null;
        this.getContextData();
        this.helpCenterContentService.updateContent();
    }

    navigateToURL(url: string, openInNew?: boolean) {
        const openIn = openInNew ? "_blank" : "_self";
        window.open(url, openIn);
    }

    deleteArticle(article: Article) {

        const data: ModalConfirmData = {
            title: `Confirm deletion`,
            content: `delete '${article.title}'?`,
            confirmButtonLabel: "Delete",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false,
            customClass: 'red-button'
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.contentService.deleteFaqArticle(article.RowKey,  this.updated_by )
                    .subscribe((resp) => {
                        this.reset();
                        this.openSnackBar('Article deleted', "dismiss");
                        // this.router.navigateByUrl('/help');

                    }, (err) => {
                        console.log(err);
                        this.openSnackBar(err.error, "dismiss");
                    })
                //navigate away
            }
        });
    }

    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
        this.articleSubscription ? this.articleSubscription.unsubscribe() : null;
        this.contextDataSubscription ? this.contextDataSubscription.unsubscribe() : '';
    }
}
