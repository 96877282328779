import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { responseModel } from '../../models/response.model';
import { OrganisationModel, OrganisationQueryRequest, OrgMetaData } from '../../models/organisation.model';
import { FormatDate } from '@app/pipes/formatDate';
import { map, tap, filter, catchError, mergeMap, finalize, distinctUntilChanged } from 'rxjs/operators';
import { PortalApiV2HelperService, QueryParams } from '@app/services/helpers/portal-api-v2-helper';
import { escape, unescape} from 'safe-string-literal';

export interface HostnameList {
    sip_host_name: string;
    sip_voicemail_number: string;
}

export interface NewOrganisation {
    name: string;
    contact_phone_number: string;
    trial_end_date: string;
    is_pipxt_enabled: boolean;
    is_pipmobile_enabled: boolean;
    is_esim_enabled: boolean;
    admin_first_name: string;
    admin_last_name: string;
    admin_email: string;
    admin_mobile: string;
    initial_set_up_fee: number;
    monthly_service_charge: number;
}

export interface AdvancedSearchResultsOrg {
    data: OrganisationTableModel[];
    pagination: {
        pageNumber: number;
        pageSize: number;
        pageCount: number;
        totalCount: number;
    }
}
//advanced search
export class OrganisationTableModel {
    id: string;
    name: string;
    state: string;
    owner_user_id: string;
    owner_first_name: string;
    owner_last_name: string;
    owner_fullname: string;
    owner_email: string;
    is_blocked: boolean;
    is_pipxt_enabled: boolean;
    is_pipmobile_enabled: boolean;
    is_esim_enabled: boolean;
    trial_end_date: string;
    billing_day: number;
    is_auto_billing_enabled: boolean;
    stripe_customer_id: string;
    xero_customer_id: string;
    contact_phone_number: string;
    initial_set_up_fee: number;
    monthly_service_charge: number;
    notes: string;
    metadata: string;
    organisation_size: number;
    source: string;
    location: string;
    created_at: Date;
    created_by: string;
    modified_at: Date;
    modified_by: string;
}
@Injectable()
export class OrganisationService {
    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`;

    public options;
    constructor(private httpProxy: HttpProxy, private portalApiV2HelperService: PortalApiV2HelperService) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }
    //get all organisation list.
    public getOrganisationList(): Observable<responseModel> {
        console.log("[[api]] -> get organisation list");
        return this.httpProxy.get<responseModel>(this.ApiBaseUrl + '/organisations/' + this.code, 'response', true);
    }

    //admin
    public postCreateNewOrganisation(body: NewOrganisation): Observable<responseModel> {
        console.log("[[api]] -> Post new organisation");
        return this.httpProxy.post(this.ApiBaseUrl + `/organisations/withadmin${this.code}`, body, 'response', true);
    }

    public patchOrganisationTrialEnd(orgId: string, dateEnd: string): Observable<responseModel> {
        console.log("[[api]] -> patch organisation trial end");
        //format 2019-08-31
        const body = {
            "trial_end_date": dateEnd
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${orgId}/trialenddate${this.code}`, body, 'response');
    }

    public patchOrganisationState(orgId: string, state: string): Observable<responseModel> {
        console.log("[[api]] -> patch organisation state");
        const body = {
            "state": state
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${orgId}/state${this.code}`, body, 'response');
    }

    public patchOrganisationFees(orgId: string, initial_set_up_fee: number, monthly_service_charge: number ): Observable<responseModel> {
        console.log("[[api]] -> patch organisation fees");
        //format 2019-08-31
        const body = {
            "initial_set_up_fee": initial_set_up_fee,
            "monthly_service_charge": monthly_service_charge,
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${orgId}/charges${this.code}`, body, 'response');
    }


    //get Organisation Details by ID
    public getOrganisationById(id: string): Observable<HttpResponse<OrganisationModel>> {
        console.log("[[api]] -> get organisation by id");
        return this.httpProxy.get(this.ApiBaseUrl + '/organisations/' + id + this.code, 'response', true);
    }


    // get users by Organisation Id
    public getOrgUsersById(orgId: string): Observable<responseModel> {
        console.log("[[api]] -> get organisation users");
        return this.httpProxy.get<responseModel>(`${this.ApiBaseUrl}/organisations/${orgId}/users${this.code}`, 'response', true);
    }

    public postOrganisationQuery(searchParams: OrganisationQueryRequest): Observable<responseModel> {
        return this.httpProxy.post(this.ApiBaseUrl + `/organisations/query${this.code}`, searchParams, 'response', true);
    }


    public getDdiListByOrganisation(orgId: string): Observable<responseModel> {
        return this.httpProxy.get(this.ApiBaseUrl + `/organisations/${orgId}/ddis${this.code}`, 'response', true);
    }

    public getHostnamesforOrganisation(organisation_id: string) {
        return this.getDdiListByOrganisation(organisation_id)
            .pipe(
                map(list => this.filterToPiPxt(list))
            )
    }


    private filterToPiPxt(response: responseModel) {
        if ( response.status === 200) {

            const tmplist = response.body?.ddi_list
            .filter(item => item?.pip_variant === 'pipxt')
            .filter((v, i, a) => a.findIndex(t => (t.sip_host_name === v.sip_host_name && t.sip_voicemail_number === v.sip_voicemail_number)) === i)
            const _templist: HostnameList[] = [];
            tmplist.forEach(element => {
                const newitem = {
                    sip_host_name: element.sip_host_name,
                    sip_voicemail_number: element.sip_voicemail_number
                }
                _templist.push(newitem);
            });

            return _templist
        } else {
            return [];
        }
    }


    public patchOrganisationBlocked(id: string, isBlocked: boolean): Observable<responseModel> {
        console.log("[[api]] -> patch organisation isblocked");
        const body = {
            "is_blocked": isBlocked
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${id}/isblocked${this.code}`, body, 'response');
    }


    public patchOrganisationBilling(orgId: string, isEnabled: boolean, stripe_Id?: string): Observable<responseModel> {
        console.log("[[api]] -> patch organisation trial end");
        //format 2019-08-31
        const body = {
            "is_auto_billing_enabled": isEnabled,
            "stripe_customer_id": stripe_Id
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${orgId}/billing_enabled${this.code}`, body, 'response');
    }


    public patchOrganisationMetadata(orgId: string, metadata: OrgMetaData): Observable<responseModel> {
        console.log("[[api]] -> patch organisation metadata");
        const modified_at: Date = new Date();
        metadata.modified_at = modified_at.toISOString();
        metadata.version = environment.metaVersion;
        // "metadata": escape(JSON.stringify(metadata))
        const body = {
            "metadata": escape(JSON.stringify(metadata))
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${orgId}/metadata${this.code}`, body, 'response');
    }

    public patchOnboardingMetaReset(organisation_id: string, stageIndex?: number, existingMeta?: OrgMetaData, label?: string): Observable<responseModel> {
        //if no existing meta, then reset to 0 and default else
        //merge existing meta with new stage. if there is no given stage, then reset to 0
        label = label ? label : 'labelhere';
        const meta: OrgMetaData = existingMeta ? existingMeta : { };
        meta.onboarding = {
            stage: stageIndex ? stageIndex : 0,
            label: stageIndex ? label : 'Patched by SySAdmin'
        }
        return this.patchOrganisationMetadata(organisation_id, meta)
    }

    public patchOrganisationName(orgId: string, name: string): Observable<responseModel> {
        console.log("[[api]] -> patch organisation name");
        const body = {
            "name": name
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${orgId}/name${this.code}`, body, 'response');
    }

    public patchOrganisationNote(orgId: string, notes: string): Observable<responseModel> {
        console.log("[[api]] -> patch organisation note");
        const body = {
            "notes": notes
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${orgId}/notes${this.code}`, body, 'response');
    }


    public deleteOrganisationUser(org_id: string, user_id: string , ddi_action: 'return' | 'keep' , mode?: 'soft' | 'hard'): Observable<responseModel> {
        console.log("[[api]] -> delete user soft (sysadmin)");
        const body = {
            mode: mode ? mode : "soft",
            ddi_action: ddi_action
        }
        return this.httpProxy.delete(this.ApiBaseUrl + `/organisations/${org_id}/users/${user_id}${this.code}`, 'response', true, body);
    }

    public patchRemoveUserRequest(orgId: string, userId: string, requestedby: string, remove?: boolean): Observable<responseModel> {
        console.log("[[api]] -> patch organisation removal user request");
        const body = {
            removal_requested: remove != null ? remove : true,
            requested_by_user_id: requestedby
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${orgId}/users/${userId}/removal_requested${this.code}`, body, 'response');
    }

    public organisationProductSettings(orgId: string, pipxtEnabled: boolean, pipmobileEnabled: boolean, esimEnabled: boolean): Observable<responseModel> {
        const body = {
            "is_pipxt_enabled": pipxtEnabled,
            "is_pipmobile_enabled": pipmobileEnabled,
            "is_esim_enabled": esimEnabled
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${orgId}/products${this.code}`, body, 'response');
    }

    public deleteOrganisationUserTrial(org_id: string, user_id: string ): Observable<responseModel> {
        console.log("[[api]] -> delete user in trial");
        return this.httpProxy.delete(this.ApiBaseUrl + `/organisations/${org_id}/users/${user_id}/trial${this.code}`, 'response', true);
    }

    //not in use
    // public getOrganisationByTrialEnd(trialEndString: string): Observable<responseModel> {
    //     return this.httpProxy.get(this.ApiBaseUrl + `/organisations/trial_end_date/${trialEndString}${this.code}`, 'response', true);
    // }

    // public getOrganisationTrialEndsToday(): Observable<responseModel> {
    //     const today = new Date();
    //     const trialEndString = new FormatDate().transform(today);
    //     return this.httpProxy.get(this.ApiBaseUrl + `/organisations/trial_end_date/${trialEndString}${this.code}`, 'response', true);
    // }



    //===========BELOW THIS LINE ARE ALL TESTED FOR API2.0=======================

    public putOrganisationDetails(organisation_id: string, name: string, contact_phone_number: string, metadata?: OrgMetaData) {
        let body = {};

        if (!metadata) {
            body = {
                "name": name.trim(),
                "contact_phone_number": contact_phone_number,
                "type": "business",
            }
        }
        if (metadata) {
            const modified_at: Date = new Date();
            metadata.modified_at = modified_at.toISOString();
            metadata.version = environment.metaVersion;
            body = {
                "name": name.trim(),
                "contact_phone_number": contact_phone_number,
                "type": "business",
                "metadata": escape(JSON.stringify(metadata))
            }
        }
        return this.httpProxy.put(this.ApiBaseUrl + `/organisations/${organisation_id}${this.code}`, body, 'response', true);
    }






    public patchMigratePBX(organisation_id: string, new_pbx_id: string,  service_plan: string  ): Observable<responseModel> {
        console.log("[[api]] -> patch migrate organisation OBX");
        const body = {
            "new_pbx_id": new_pbx_id,
            "service_plan": service_plan
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${organisation_id}/migratePbx${this.code}`, body, 'response');
    }


    //Version2 ========================================================================================================

    public getOrganisation_V2(queryParams?: QueryParams): Observable<HttpResponse<AdvancedSearchResultsOrg>> {
        // OrganisationTableModel
        console.log("[[api]] -> get organisation search query V2", queryParams);

        const paginationString = this.portalApiV2HelperService.getPaginationString(queryParams);
        const sortString = this.portalApiV2HelperService.getSortString(queryParams);
        const _searchString = queryParams?.searchString ? queryParams.searchString : '';
        console.log( `/v2/organisations/search${this.code}&rest=${paginationString}${sortString}${_searchString}`);
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/organisations/search${this.code}&rest=${paginationString}${sortString}${_searchString}`, 'response', true);
    }

    public organisationCallBackRequest(contact_number: string, product: string, id: string): Observable<HttpResponse<any>> {
        const body = {
            contact_number: contact_number,
            product: product
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/organisations/${id}/callback_requests${this.code}`, body, 'response', true);

    }
}
