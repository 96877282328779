//core
import { Component, Inject, NgZone, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subscription, Observable, throwError, Subject } from 'rxjs';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray, MaxLengthValidator } from '@angular/forms';


@Component({
    templateUrl: './log-in-modal.component.html',
    styleUrls: ['./log-in-modal.component.scss'],
    animations: [
    ]
})
export class LogInModal {

    isDev: boolean;


    public pageStatus = {
        isLoading: true,
        isSubmitting: false,
        note: {
            isSaving: false,
            isSaved: false,
            isSaveError: false,
        }
    }

    constructor(
        private dialogRef: MatDialogRef<LogInModal>,
        private fb: FormBuilder,
        private http: HttpClient,
        private ref: ChangeDetectorRef,
        private _fb: FormBuilder,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA)
        public data: {}, //contains data injected into modal.
    ) {
        this.isDev = isDevMode();

        dialogRef.disableClose = true;
        dialogRef.backdropClick().subscribe(() => {
            this.closeModal()
        })

    }


    ngOnInit() {

    }



    setFormValues() {

    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }



    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    closeModal() {
        this.dialogRef.close();
    }
    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }
    onDestory() {

    }
}
