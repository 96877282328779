<div class="full-page-container">
    <div class="fx-col fx-justify-center fx-align-center fx-gap-1" style="width: 100%;">
        <!-- 
        <div class="esim-usage-title-container">
            DATA REMAINING
        </div>

        <div class="esim-usage-state">{{esim?.status}}</div>
        <app-svg-simple-pie-chart [maxValue]="maxData" [chartValue]="remainingData" [isEsimUsage]="true"
            [esim_expires_at]="esim?.status === 'ACTIVE' ? esim?.expires_at : null"></app-svg-simple-pie-chart> -->

        <ng-container *ngIf="isLoading;else showDetails">
            ..loading
        </ng-container>


        <ng-template #showDetails>

            <ng-container *ngIf="esimNotFound">
                eSIM was not found
            </ng-container>


            <ng-container *ngIf="esim && esim?.status === 'NOT_ACTIVE'">

                <ng-container *ngIf="esim?.euicc_profile_state === 'INSTALLED' || esim?.euicc_profile_state === 'ENABLED' || esim?.euicc_profile_state === 'DISABLED'; else installation ">
                    <div class="esim-usage-title-container">
                    READY FOR ACTIVATION
                    </div>
                    <div class="esim-usage-state" style="color: grey;">Installed</div>
                </ng-container>

                <ng-template #installation>
                    <div class="esim-usage-title-container">
                    READY FOR INSTALLATION
                    </div>
                <!-- <div class="esim-usage-state" style="color: grey;"></div> -->
                </ng-template>
                
         

                <app-svg-simple-pie-chart [maxValue]="maxData" [chartValue]="remainingData" [isEsimUsage]="true"
                    [esim_expires_at]="esim?.status === 'ACTIVE' ? esim?.expires_at : null"></app-svg-simple-pie-chart>

            </ng-container>

            <ng-container *ngIf="esim && esim?.status === 'ACTIVE'">
               
                <div class="esim-usage-title-container">
                    DATA REMAINING
                </div>
                <div class="esim-usage-state" style="color: var(--c-accept);">Active</div>
                
                <app-svg-simple-pie-chart [maxValue]="maxData" [chartValue]="remainingData" [isEsimUsage]="true"
                    [esim_expires_at]="esim?.status === 'ACTIVE' ? esim?.expires_at : null"></app-svg-simple-pie-chart>

            </ng-container>

            <ng-container *ngIf="esim && esim?.status === 'TERMINATED'">
                <div class="esim-usage-title-container" style="color: var(--c-error);">
                    EXPIRED
                </div>
                <div class="esim-usage-state"></div>
                
                <app-svg-simple-pie-chart [maxValue]="maxData" [chartValue]="0" [isEsimUsage]="true"
                    [esim_expires_at]="esim?.status === 'ACTIVE' ? esim?.expires_at : null"></app-svg-simple-pie-chart>
            </ng-container>

        </ng-template>


        <div class="destination-container fx-row fx-gap-1 fx-align-center" *ngIf="!isLoading">

            <div class="flag-container fx-col fx-align-center ">
                <img class="country-flag md"
                    [src]="'https://pipcallportalstorage.blob.core.windows.net/flags-of-the-world/svg/'+ getFlag(esim) +'.svg'"
                    [alt]="'Flag of ' + esim?.license_name"
                    (error)="($event.target.src = 'https://pipcallportalstorage.blob.core.windows.net/flags-of-the-world/svg/missing.svg')" />
            </div>

            <div class="destination-details-container fx-col">
                <div class="country-name">{{esim?.license_name}}</div>
                <div class="plan-name">{{esim?.contract_name}}</div>
            </div>

        </div>
        <div class="help-container" *ngIf="!isLoading && esim && esim?.status === 'NOT_ACTIVE' &&(esim?.euicc_profile_state === 'INSTALLED' || esim?.euicc_profile_state === 'ENABLED' || esim?.euicc_profile_state === 'DISABLED')">Plan will activate when in a destination country. Ensure the eSIM is enabled and set as your primary Data SIM.</div>

        <div class="supported-destinations fx-col" *ngIf="esim?.is_bundle && !isLoading" style="margin-top: 1rem;">
            <div style="font-weight: 700;">Supported destinations:</div>
            <div>
                <span *ngFor="let country of esim?.countries; let i = index">{{country?.name}}, &nbsp;</span>
            </div>
        </div>

    </div>

</div>


