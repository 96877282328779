import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { SharedDataServiceModule } from '@app/services/shared-data.service/shared-data.service.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';
//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { UserService } from '@app/services/pipcall/user.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { SignupService } from '@app/services/pipcall/signup.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { NumberService } from '@app/services/pipcall/number.service';

//component
import { UserProfileTabSystemComponent } from './user-profile-system.component'


import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';

// modules
import { modalServiceModule } from '@app/services/modal-service/modal-service.module';
import { MdePopoverModule } from '@material-extended/mde';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
import { OrgPipnumberSelectModule } from '@app/components/form-field/ff-org-pipnumber-select/ff-org-pipnumber-select.module';
import { AreacodeListSelectModule } from '@app/components/form-field/ff-areacode-list-select/ff-areacode-list-select.module';


//bottomSheet
import { DDIBottomSheetModule } from '@app/components/bottom-sheet-modal/ddi-bsheet-modal/ddi-bsheet-modal.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        MdePopoverModule,
        GravatarDirectiveModule,
        OrgPipnumberSelectModule,
        AreacodeListSelectModule,
        modalServiceModule,
        SharedDataServiceModule,
        DDIBottomSheetModule,
        UniversalComponentsModule
    ],
    declarations: [UserProfileTabSystemComponent],
    exports: [UserProfileTabSystemComponent],
    providers: [AppInsightService, UserService, NormalizeNumberService, SignupService, PhonenumberValidator, LicenseDataService, NumberService],
    entryComponents: []
})
export class UserProfileTabSystemModule { }
