//essentials
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//components
import { SignupRequestComponent} from './signup-request/signup.component';
import { VerifySignupComponent } from './verify-signup/verify-signup.component'

// import {PageNotFoundComponent} from '../page-not-found/page-not-found.component';
//Guard
// import { AuthGuardService as AuthGuard } from '@app/services/auth-service/auth-guard-service';
// import {AdminGuard, NumberAdminGuard, CustomerAdminGuard} from './admin.guard';

const routes: Routes = [
    {
        path: '',
        component: SignupRequestComponent,
        canActivate: [],
        data: {
            breadcrumb: 'Signup'
        }
    },
    {
        path: ':id/verify',
        component: VerifySignupComponent,
        canActivate: [],
        data: {
            breadcrumb: 'Verify'
        }
    },
];



export const SignupRouting: ModuleWithProviders<RouterModule>  = RouterModule.forChild(routes);


