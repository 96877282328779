import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material/material.module';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//requirements
import { CommonModule } from '@angular/common';

import { CurrencyPipe } from '@angular/common'
//services

import { PaymentsService } from '@app/services/pipcall/payments.service';
// import { NgxStripeModule } from 'ngx-stripe';
import { StripePaymentComponent} from './stripe-payment.component';
import { AppInsightService } from '@app/services/helpers/app-insights.service';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule
    ],
    declarations: [StripePaymentComponent],
    exports: [StripePaymentComponent],
    providers: [HttpProxy, PaymentsService, CurrencyPipe, AppInsightService],
    entryComponents: []
})
export class StripePaymentModalModule { }
