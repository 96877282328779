
//https://faq.whatsapp.com
//https://help.twitter.com/en
//https://www.searchenginejournal.com/best-faq-page-examples/267709/#close
import { Component, Input, OnInit, Output , EventEmitter, ChangeDetectorRef, ElementRef, ViewChild} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {  MatSnackBar } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Subject, Observable, Subscription } from "rxjs";
import { map, startWith, takeUntil, tap, first, finalize, distinctUntilChanged } from "rxjs/operators";
import { SidenavRightService } from '@app/template/sidenav-right/sidenav-right.service'
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { slideInRightOnEnterAnimation, bounceInUpOnEnterAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, fadeOutAnimation, collapseAnimation } from 'angular-animations';
import { HelpCenterContentService, FAQS } from '@app/services/help-center-content.service';
import { Article} from '@app/services/pipcall/content.service';
import { COMMA, E, ENTER, I, M} from '@angular/cdk/keycodes';
import { Editor, toDoc , toHTML, Toolbar} from 'ngx-editor';
import { MatChipInputEvent} from '@angular/material/chips';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { ContentService  } from '@app/services/pipcall/content.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { UserSessionService } from '@app/services/user-session.service';
// eslint-disable-next-line no-var
declare var require: any;
const appVersion = require('../../../../package.json').version;


@Component({
    selector: 'hc-edit-panel',
    templateUrl: './hc-edit-panel.component.html',
    styleUrls: ['./hc-edit-panel.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ]),
        slideInRightOnEnterAnimation({ duration: 200 }),
        bounceInUpOnEnterAnimation(),
        fadeOutOnLeaveAnimation({ duration: 800 }),
        fadeInOnEnterAnimation({ duration: 400 }),
        fadeOutAnimation(),
        collapseAnimation()
    ]

})

export class HelpCenterEditPanelComponent implements OnInit {

    @Input() mode: 'new' | 'edit' | 'view';
    @Input() article: Article;
    @Input() faqList: FAQS; //contains all FAQs and categories
    @Input() toolbarLocation: 'top' | 'bottom'; //defaults to bottom if no value
    @Output() cancel = new EventEmitter();


    public pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isChanged: false,
        submitAttempt: 0
    }
    //wysiwigEditor
    editor: Editor;
    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline'],
        ['code', 'blockquote'],
        ['bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        ['text_color', 'background_color']
    ];
    //   ['align_left', 'align_center', 'align_right', 'align_justify'],


    //tags
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    tags: String[] = [ ];

    //links
    contextListFormControl: string[] = [ ];

    public articleForm: FormGroup;
    filteredCategories: Observable<string[]>; //filtered to categories searched on. for autocomplete


    constructor(
        private router: Router,
        private appInsightService: AppInsightService,
        public snackBar: MatSnackBar,
        private helpCenterContentService: HelpCenterContentService,
        private _formBuilder: FormBuilder,
        private contentService: ContentService,
        private ref: ChangeDetectorRef,
        private modalService: ModalService,
        private userSessionService: UserSessionService
    ) {
        this.articleForm = this._formBuilder.group({
            title: ['', Validators.required],
            description: ['', Validators.required],
            tags: [''],
            context_links: [''],
            category: ['', Validators.required],
            is_active: [true, Validators.required],
            min_portal_version: [appVersion, Validators.required],
            updated_by: ['', Validators.required],
            content: ['', Validators.required],
            sysadmin_content: [''],
            PartitionKey: ['', Validators.required],
            RowKey: [null, Validators.required]
        });

    }


    ngOnInit() {
        this.editor = new Editor();
        //only set Initial values if we are in edit mode
        if (this.mode === 'edit' && this.article) {
            this.setInitialFormValues(this.article);
        }

        if (this.mode === 'new') {
            this.article = null;
            this.setInitialFormValues();
        }

        //get name of current user for updated by

        this.userSessionService.userProfileAsObservable.first().take(1).subscribe(userProfile => {
            const name =  userProfile.first_name + ' ' + userProfile.last_name;
            this.setFormValue(this.articleForm, 'updated_by', name);
        }, (err) => {
            console.log("ERROR GETTING USER PROFILE", err);
        });

        this.filteredCategories = this.articleForm.controls.category.valueChanges.pipe(
            startWith(''),
            map(value => this._filterCategory(value))
        );
    }


    setInitialFormValues(article?: Article) {
        this.setFormValue(this.articleForm, 'min_portal_version', appVersion);
        this.setFormValue(this.articleForm, 'is_active', true);
        this.setFormValue(this.articleForm, 'PartitionKey', 'faq_content_en');

        //context_links populate
        this.contextListFormControl = this.article?.context_links ?  this.article?.context_links?.split(',') : [];

        if (this.mode === 'new') {
            //set rowkey if new article
            const key = this.faqList.lastRowKey + 1;
            this.articleForm.controls.RowKey.setValue(key.toString());
        }

        if (article) {
            //only set these values if article exists
            if (this.article.tags !== ''  && this.article?.tags !== null && this.article?.tags !== undefined) {this.tags = this.article.tags.split(','); }

            // if (this.article?.context_links !== '' && this.article?.context_links !== null && this.article?.context_links !== undefined) {this.context_links = this.article?.context_links.split(','); }

            //set existing article values for Edit form
            const values = ['title', 'description', 'category', 'tags', 'is_active', 'min_portal_version', 'RowKey', 'PartitionKey', 'sysadmin_content', 'content', 'context_links'];

            //set new updated by
            values.forEach(param => {
                this.articleForm.controls[param].setValue(article[param]);
            });
        }

        this.articleForm.markAsPristine();

    }

    setValue(parameter: string, form: FormGroup, article: Article) {
        // console.log("SET PARAMER", parameter, " with value: ", article[parameter])
        form.controls[parameter].setValue(article[parameter]);
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    private _filterCategory(value: string) {
        //filter to searched categories
        if (!this.faqList?.categories) { return null};
        const filterValue = value.toLowerCase();
        return this.faqList?.categories.filter(cat => cat.toLowerCase().indexOf(filterValue) === 0);
    }


    addTag(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add
        if (value) {
            this.tags.push(value.trim());
            this.articleForm.controls.tags.setValue(this.tags.toString());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    removeTag(tag: String): void {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
            this.articleForm.controls.tags.setValue(this.tags.toString());
        }
    }



    resetForm() {
        this.articleForm.reset();
        this.ngOnInit();
    }

    saveEdit() {
        this.pageStatus.submitAttempt ++;

        if (this.pageStatus.submitAttempt > 3) {
            this.pageStatus.submitAttempt = 0;
            return this.openSnackBar("sorry something went wrong", "dismiss")
        }

        this.articleForm.controls.category.setValue(this.articleForm.value.category.toLowerCase()); //make sure category is lowercase

        console.log("save form", this.articleForm.value);

        if (!this.articleForm.valid) {return this.openSnackBar('Form is not valid', 'dismiss'); }

        if (this.mode === 'new') {
            //HANDLE NEW ARTICLE

            this.pageStatus.isSubmitting = true;
            let _rowKey = this.articleForm.value.RowKey;
            this.contentService.postNewFaqArticle(this.articleForm.value)
                .subscribe((resp) => {
                    this.pageStatus.submitAttempt = 0;
                    this.pageStatus.isChanged = true;
                    setTimeout(() => {
                        this.openSnackBar("saved!", "ok")
                        this.pageStatus.isSubmitting = false;
                        this.articleForm.markAsPristine();
                        this.articleForm.markAsUntouched();
                        this.back();
                        this.ref.markForCheck();
                    }, 1800);
                },
                (err) => {

                    //handle if new , increment rowKey and try again. account for azure table storage rowkey
                    if (err.status === 409) {
                        _rowKey = parseInt(_rowKey) + 1;
                        this.articleForm.controls.RowKey.setValue(_rowKey.toString());
                        return this.saveEdit();
                    } else {
                        this.openSnackBar("Sorry, something went wrong", "dismiss")
                    }
                })
        } else if (this.mode === 'edit') {

            this.pageStatus.isSubmitting = true;

            //HANDLE EDIT ARTICLE
            this.contentService.putFaqArticleUpdate(this.articleForm.value)
                .subscribe((resp) => {
                    this.pageStatus.submitAttempt = 0;
                    this.pageStatus.isChanged = true;

                    setTimeout(() => {
                        this.openSnackBar("saved!", "ok")
                        this.pageStatus.isSubmitting = false;
                        this.articleForm.markAsPristine();
                        this.articleForm.markAsUntouched();
                        this.back();
                        this.ref.markForCheck();
                    }, 1800);
                },
                (err) => {
                    this.pageStatus.submitAttempt = 0;
                    this.openSnackBar("Sorry, something went wrong", "dismiss")
                })
        }

    }

    Debug() {
        console.log("DEBUG", this.articleForm.value.context_links);
        console.log("contextListFormControl", this.contextListFormControl);

    }

    linkSelected(event) {
        this.contextListFormControl = event.value;
        this.articleForm.controls.context_links.setValue(event.value.toString());
    }
    back() {
        //if an edit is in progress, confirm with user

        //if EDit mode, back to article
        //if New mode, cancel goes back to main menu

        console.log("<----------------BACK FIRED", this.articleForm)

        if (this.articleForm.touched) {
            const data: ModalConfirmData = {
                title: 'Are you sure?',
                content: 'You will loose all changes',
                confirmButtonLabel: "Confirm",
                closeButtonLabel: "Cancel",
                choice: true,
                disableClose: false
            }

            this.modalService.openConfirmModal(data, (answer: boolean) => {
                if (answer) {
                    this.cancel.emit(this.pageStatus?.isChanged);
                }
            });
        } else {
            this.cancel.emit(this.pageStatus?.isChanged);
        }

    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    ngOnDestroy() {
        //clean up
        this.editor.destroy();
    }
}


