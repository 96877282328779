import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { DdiAllocationRequest, DdiProvisioningRequest, PiPxtUpdate, DdiUpdate, SipProvisioningRequest } from '../../models/ddi.model';
import { responseModel } from '../../models/response.model';
import { HttpHeaders } from '@angular/common/http';
import { PortalApiV2HelperService, QueryParams } from '@app/services/helpers/portal-api-v2-helper';
import { max } from 'moment';
export class AreaCode {
    code: string;
    name: string;
    id: string;
}

// export class NumberTableModel {
//     number: string;
//     pbx_platform_id: string;
//     organisation_id: string;
//     provider: string;
//     pip_variant: string;
//     state: string;
//     is_reserved: boolean;
//     is_blocked: boolean;
//     is_sms_enabled: boolean;
//     sip_extension_number: string;
//     sip_extension_cli: string;
//     sip_user_name: string;
//     sip_host_name: string;
//     sip_voicemail_number: string;
//     pbx_id: string
//     pbx_tenant_id: string
//     pbx_tenant_code: string;
//     is_pipxt_extensionless: boolean;
//     notes: string;
//     event_source: string;
//     event_type: string;
//     event_code: string;
//     event_at: string;
//     event_data: string;
//     dnd: any;
//     label: string;
//     cli_override: string;
//     no_answer: string;
//     user_id: string;
//     user_first_name: string;
//     user_last_name: string;
//     user_full_name: string;
//     user_email: string;
//     organisation_name: string;
//     organisation_state: string;
//     cli_override_request_id: string;
//     invitation_id: string;
//     invitation_email: string;
//     id: string;
//     created_at: Date;
//     created_by: string;
//     modified_at: Date;
//     modified_by: string;
// }

@Injectable()
export class NumberService {

    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`;

    public options;

    constructor(private httpProxy: HttpProxy, private portalApiV2HelperService: PortalApiV2HelperService) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }


    public provisionDdi(ddiAllocationRequest: DdiProvisioningRequest): Observable<string> {
        return this.httpProxy.post(this.ApiBaseUrl + '/ddis' + this.code, ddiAllocationRequest, 'body', true)
    }

    public provisionSip(sipProvisioningRequest: SipProvisioningRequest): Observable<string> {
        return this.httpProxy.post(this.ApiBaseUrl + '/ddis/extensions' + this.code, sipProvisioningRequest, 'body', true)
    }



    public getAreaCodes(): Observable<responseModel> {
        return this.httpProxy.get(this.ApiBaseUrl + '/areacodes' + this.code, 'response', true);
    }

    public getDdiById(number: string): Observable<responseModel> {
        return this.httpProxy.get(this.ApiBaseUrl + `/ddis/${number}` + this.code, 'response', true);
    }

    // public postDdiQuery(searchParams: DdiListRequest): Observable<responseModel> {
    //     return this.httpProxy.post(this.ApiBaseUrl + `/ddis/query${this.code}`, searchParams, 'response', true);
    // }

    public deleteSingleDDi(ddi: string): Observable<responseModel> {
        return this.httpProxy.delete(this.ApiBaseUrl + `/ddis/${ddi}${this.code}`, 'response', true);
    }


    //unmapp a ddi from the user it is assigned to. Removes DDI mapping entry.
    public deallocateDdi(number: string): Observable<responseModel> {
        return this.httpProxy.delete(this.ApiBaseUrl + `/ddis/${number}/mappings` + this.code, 'response', true);
    }

    public deallocateDdiFromOrganisation(number: string, organisation_id: string): Observable<responseModel> {
        return this.httpProxy.delete(this.ApiBaseUrl + `/organisations/${organisation_id}/ddis/${number}/allocations` + this.code, 'response', true);
    }

    public allocateDdi(number: string, user_id: string, organisation_id: string, name: string): Observable<responseModel> {
    //allocate ddi to a user. Must belong to the organisation already.
        const body = {
            "number": number,
            "organisation_id": organisation_id,
            "user_id": user_id,
            "name": name
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/ddis/mappings` + this.code, body, 'response', true);
    }

    public allocateDdiByAreacode(areacode: string, user_id: string, organisation_id: string, name: string): Observable<responseModel> {
    //allocate ddi to a user. Must belong to the organisation already.
        const body = {
            "allocation_option": areacode,
            "organisation_id": organisation_id,
            "user_id": user_id,
            "name": name
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/ddis/mappings` + this.code, body, 'response', true);
    }


    public putDdiUpdate(number: string, ddiupdate: DdiUpdate): Observable<responseModel> {
        return this.httpProxy.put(this.ApiBaseUrl + `/ddis/${number}` + this.code, ddiupdate, 'response', true);
    }

    // public patchPipxtUpdateSysAdmin(number: string, pipxtUpdate: PiPxtUpdate): Observable<responseModel> {
    //     return this.httpProxy.put(this.ApiBaseUrl + `/ddis/sipdetails/${number}/sysadmin` + this.code, pipxtUpdate, 'response', true);
    // }

    ///this is the isAdmin change.
    public patchPipxtUpdate(number: string, pipxtUpdate: PiPxtUpdate): Observable<responseModel> {
        return this.httpProxy.put(this.ApiBaseUrl + `/ddis/sipdetails/${number}/sysadmin` + this.code, pipxtUpdate, 'response', true);
        // return this.httpProxy.put(this.ApiBaseUrl + `/ddis/sipdetails/${number}` + this.code, pipxtUpdate, 'response', true);
    }

    public getDdiCapacity(): Observable<responseModel> {
        return this.httpProxy.get(this.ApiBaseUrl + '/ddis/areacodes/capacity' + this.code, 'response', true);
    }

    public AllocateDdiToOrganisation(organisation_id: string, number: string, force?: boolean): Observable<responseModel> {

        const _force = force ? force : false;
        const body = {
            "organisation_id": organisation_id,
            "force": _force
        }

        return this.httpProxy.patch(this.ApiBaseUrl + `/ddis/${number}/allocations${this.code}`, body, 'response');
    }


    public propagateNumber(identifiers: string): Observable<responseModel> {

        const body = {
            "identifiers": identifiers
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/tasks/propagation/ddis${this.code}`, body, 'response', true);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static getCountryCodes(): Array<AreaCode> {
    //this is a backup to api.
        const areaCode: Array<AreaCode> = [
            {
                "code": "07",
                "name": "UK Mobile",
                "id": "00447"
            },
            {
                "code": "0113",
                "name": "Leeds",
                "id": "0044113"
            },
            {
                "code": "0121",
                "name": "Birmingham",
                "id": "0044121"
            },
            {
                "code": "0131",
                "name": "Edinburgh",
                "id": "0044131"
            },
            {
                "code": "0141",
                "name": "Glasgow",
                "id": "0044141"
            },
            {
                "code": "0151",
                "name": "Liverpool",
                "id": "0044151"
            },
            {
                "code": "0161",
                "name": "Manchester",
                "id": "0044161"
            },
            {
                "code": "0191",
                "name": "Newcastle",
                "id": "0044191"
            },
            {
                "code": "02897",
                "name": "Belfast",
                "id": "00442897"
            },
            {
                "code": "029",
                "name": "Cardiff",
                "id": "004429"
            },
        ];
        return areaCode;
    }

    public patchVariant(number: string, pip_variant: string): Observable<responseModel> {
        console.log("[[api]] -> Patch convert pip variant to", pip_variant);
        const body = {
            "pip_variant": pip_variant
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/ddis/${number}/pipvariant${this.code}`, body, 'response');
    }
    //Version2 ========================================================================================================


    public convertToExtension(number: string, pbx_id: string, pbx_tenant_id: string, organisation_id: string, service_plan: string  ): Observable<responseModel> {
        console.log("[[api]] -> Post convert to mobile+ extension", number);
        const body = {
            "pbx_id": pbx_id,
            "pbx_tenant_id": pbx_tenant_id,
            "number": number,
            "organisation_id": organisation_id,
            "service_plan": service_plan
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/v2/ddi/convert${this.code}`, body, 'response', true);
    }

    public getDdiById_v2(service_id: string): Observable<responseModel> {
        console.log("[[api]] -> get number by ID V2", service_id);
        const query =  `$page=1&$pageSize=1&number[contains]=${service_id}`
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/ddi/search${this.code}&rest=${query}`, 'response', true);
    }


    public getOrganisationPBXhost(organisation_id: string): Observable<responseModel> {
        //Return PBX host of the first extension in the organisation
        console.log("[[api]] -> get for pbx host for organisation");
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/ddi/search${this.code}&rest=$page=1&$pageSize=1&$sort_by=created_at&organisation_id=${organisation_id}&pip_variant[contains]=pipxt`, 'response', true);
    }

    public getNumbers_V2(queryParams?: QueryParams): Observable<responseModel> {
        console.log("[[api]] -> get number search query V2", queryParams);
        const paginationString = this.portalApiV2HelperService.getPaginationString(queryParams);
        const sortString = this.portalApiV2HelperService.getSortString(queryParams);
        const _searchString = queryParams?.searchString ? queryParams.searchString : '';
        console.log( `/v2/ddi/search${this.code}&rest=${paginationString}${sortString}${_searchString}`);
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/ddi/search${this.code}&rest=${paginationString}${sortString}${_searchString}`, 'response', true);
    }

    public getDdiAvailableToConvertToExt(maxCount?: string): Observable<responseModel> {
        maxCount = maxCount ? maxCount : '40';
        //const devCondition = '&pbx_platform_id[contains]=DV01-UK44'; // change this before LIVE
        const query =  `$page=1&$pageSize=${maxCount}&number[startswith]=00447&pip_variant=pipmobile&provider[contains]=gamma&organisation_id[isnull]=true&user_id[isnull]=true&$sort_by=number`
        console.log("[[api]] -> get numbers avaiable for convert to ext", query);
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/ddi/search${this.code}&rest=${query}`, 'response', true);
    }

    public postMockQuery(pass: boolean): Observable<responseModel> {
        const resp: responseModel = {
            body: {},
            status: 200,
            ok: true,
            statusText: 'test',
            type: 1,
            url: null
        }

        if (!pass) {
            resp.status = 401;
            resp.ok = false;
            resp.statusText = 'failed';
        }
        return Observable.of(resp);
    }
}


