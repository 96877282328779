import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { UserService } from '@app/services/pipcall/user.service';
import { PaymentsService } from '@app/services/pipcall/payments.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { SignupService } from '@app/services/pipcall/signup.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';

//component
import { UserProfileTabOverviewComponent } from './user-profile-overview.component'


import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';

// modules
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
import { modalServiceModule } from '@app/services/modal-service/modal-service.module';
import { AdminUpgradeToPipuserModulelModule } from '@app/components/modals/admin-upgrade-to-pipuser-modal/admin-upgrade-to-pipuser-modal.module';
import { AdminExtensionInvitationModule } from '@app/components/modals/admin-extension-invitation-modal/admin-extension-invitation-modal.module';
import { UpdateUserProfilePictureModalModule} from '@app/components/modals/update-userprofile-picture-modal/update-userprofile-picture-modal.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        GravatarDirectiveModule,
        modalServiceModule,
        AdminUpgradeToPipuserModulelModule,
        UpdateUserProfilePictureModalModule,
        AdminExtensionInvitationModule,
        UniversalComponentsModule
    ],
    declarations: [UserProfileTabOverviewComponent],
    exports: [UserProfileTabOverviewComponent],
    providers: [AppInsightService, OrganisationService, UserService, NormalizeNumberService, SignupService, PhonenumberValidator, PaymentsService, LicenseDataService],
    entryComponents: []
})
export class UserProfileTabOverviewModule { }
