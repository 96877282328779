import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@app/material/material-essentials.module';
import { EsimOpenService, EsimUsageModel } from '@app/services/pipcall/open/esim-open.service';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { GbToBytesPipe } from '@app/pipes/bytesConvert.pipe';
import { EsimTelnaService } from '@app/services/pipcall/esim_telna.service';
import { Meta } from '@angular/platform-browser';

@Component({
    templateUrl: './esim-usage.component.html',
    styleUrls: ['./esim-usage.component.scss']
})
export class EsimUsageComponent implements OnInit {

    esim: EsimUsageModel;
    esimNotFound = false;
    isLoading = false;

    maxData: number = null;
    remainingData: number = null;
    timeRemaining = '';



    constructor(
        private snackBar: MatSnackBar,
        private esimOpenService: EsimOpenService,
        private activatedRoute: ActivatedRoute,
        private gBtoBytePipe: GbToBytesPipe,
        private esimTelnaService: EsimTelnaService,
        private meta: Meta,
        private router: Router
    ) {

    }

    ngOnInit() {


        const id = this.activatedRoute.snapshot.paramMap.get('id');

        this.getEsimUsage(id);

        this.router.events.subscribe(() => {
            // Dynamically change the icon for specific routes
            // this.updateIcon(id);
        });

    }

    getEsimUsage(id: string): void {
        this.isLoading = true;

        this.esimOpenService.getEsimUsage(id)
            .pipe(take(1)).subscribe(
                (response) => {
                    console.log(response);
                    this.esim = response?.body;
                    this.maxData = this.gBtoBytePipe.transform(this.esim?.data_allowance)
                    this.remainingData = this.esim?.data_usage_remaining;
                    this.isLoading = false;
                },
                (error) => {
                    console.log(error);
                    this.esim = null;
                    this.esimNotFound = true;
                    this.isLoading = false;
                }
            )
    }



    getFlag(esim: EsimUsageModel) {
        if (esim?.is_bundle) {
            return this.esimTelnaService.getRegionFlag(esim?.license_name);
        } else {
            return esim?.countries?.[0]?.code;
        }
    }

    // updateIcon(route_id: string) {
    //     this.meta.updateTag({
    //         name: 'apple-touch-icon',
    //         content: 'assets/favicons/esim-usage-icon-120x120.png',
    //     });
    //     this.meta.updateTag({
    //         rel: 'icon',
    //         href: 'assets/favicons/esim-usage-icon-120x120.png',
    //     });
    // }


    openSnackBar(message: string, action: string, duration?: number): void {
        const dur = duration ? duration : 3000;
        this.snackBar.open(message, action, {
            duration: dur
        })
    }

}
