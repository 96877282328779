<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>
  <div class="pip-modal-header">
    <span class="pip-modal-title">Save Query </span>
  </div>

  <div class="pip-modal-body" class="pip-modal-body pip-scroll-shadow">

    <div class="query-preview">
      <div *ngFor="let item of data.query">
        <span *ngIf="item.isActive">[x]</span><span *ngIf="!item.isActive">[&nbsp;&nbsp;]</span>
        {{item?.col}} [{{item?.operator}}] {{item?.value}}
      </div>
    </div>

    <div class="fx-col fx-justify-start" >

      <form [formGroup]="queryForm" style="font-size: 14px; width: 260px;" class="fx-col fx-justify-start">

        <mat-form-field  appearance="outline" class="custom-form-field-v3"
          [ngClass]="{'readonly-block': isDisabled}" >
          <input style="font-weight: 600;" maxlength="34" matInput placeholder="Title" formControlName="title">
          <mat-hint>Max 34 characters</mat-hint>
        </mat-form-field>


        <mat-form-field appearance="outline" class="custom-form-field-v3" [ngClass]="{'readonly-block': isDisabled}" >
          <textarea formControlName="description" maxlength="82" matInput placeholder="Description (optional)"></textarea>
          <mat-hint>Max 82 characters</mat-hint>
        </mat-form-field>


        <div style="width: 100%; text-align: right;">
          <mat-checkbox matTooltip="Share this query with entire team" labelPosition="before" formControlName="is_shared">
            Share with team
          </mat-checkbox>

        </div>

        <div style="width: 100%; text-align: right;">
          <mat-checkbox class="protected-checkbox" [disabled]="!queryForm.controls.is_shared.value === true" matTooltip="Only the creator can delete this query" labelPosition="before" formControlName="is_protected">
            Only owner can delete
          </mat-checkbox>

        </div>

        <button class="save-button" mat-flat-button color="accent" (click)="saveQuery(queryForm)" [disabled]="!queryForm.valid">Save</button>

      </form>

    </div>

  </div>



  <div class="pip-modal-footer">

  </div>
</div>