import { CommonModule } from '@angular/common';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { BrowserModule } from '@angular/platform-browser';
// import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconRegistry } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
// import { Routes, RouterModule } from '@angular/router';

import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { TemplateModule } from './template/template.module'; //template module
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomPipesModule } from '../app/pipes/pipes.module'
import { DemoModule } from './pages/demo/demo.module';
import { CardContainerTemplateModule } from './pages/demo/card-container-template/card-container-template.module';

// import { AdvSearchModule } from '@app/pages/adv-search/adv-search.module';
import 'hammerjs';
import { EsimUsageModule } from '@app/pages/esim-usage/esim-usage.module';
import { PortalApiV2HelperService } from '@app/services/helpers/portal-api-v2-helper';
import { SharedModule } from './services/shared-services.module';
import { AuthGuardService } from './services/auth-service/auth-guard-service';
// import { LoginModule } from './pages/login_orig/login.module';
import { VerifyModule } from './pages/verify/verify.module';
import { ProfileModule } from './pages/profile/profile.module';
import { PageNotFoundGlobalModule } from './pages/page-not-found/page-not-found.module';
import { RootModalMessageModule } from './root-modal-message/root-modal-message.module';
import { SharedDataServiceModule } from '@app/services/shared-data.service/shared-data.service.module';
//add as entry components for modal to work
import { ConfirmComponent } from './services/modal-service/confirm/confirm.component';
import { AlertComponent } from './services/modal-service/alert/alert.component';
import { ModalService } from './services/modal-service/modal.service'

//services
import { AdminGuard } from '@app/services/auth-service/admin.guard';
import { UserService } from './services/pipcall/user.service';
import { OrganisationService } from './services/pipcall/organisation.service';
import { NormalizeNumberService } from './services/helpers/normalize-number.service';
import { NumberService } from './services/pipcall/number.service';
import { AppInsightService } from './services/helpers/app-insights.service';
import { AppTitleService } from './services/helpers/update-title.service';
import { CookieService } from 'ngx-cookie-service';
import { VersionCheckService} from '@app/services/version-check.service';
import { BlacklistService } from '@app/services/helpers/domain-blacklist.service';



//shared components
import { PageNotFoundModule } from './components/page-not-found/page-not-found.module';
import { RedirectComponent } from './pages/redirect/redirect.component';
//for loader listening on all requests
import { LoadingInterceptor } from './services/httpinterceptor'
import { TestService } from './services/test.service';

//create modal components
import { CreateOrganisationModalModule } from './components/modals/create-organisation-modal/create-organisation-modal.module';
import { CreateDdiModalModule } from './components/modals/create-ddi-modal/create-ddi-modal.module';
import { ExpoSignupModule } from './pages/expo-signup/expo-signup.module';
import { ExpoDashboardModule } from './pages/expo-dashboard/expo-dashboard.module';

import { FormBasicControlsModule} from '@app/components/toolbar/form-basic-controls/form-basic-controls.module';

//recaptcha
import { environment } from '../environments/environment';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha'

//directives & helpers


//pipes
import { TitleCasePipe } from '@angular/common';
import { SideNavRightModule } from '@app/template/sidenav-right/sidenav-right.module';

import { NgxStripeModule } from 'ngx-stripe';
import { SidenavRightService } from './template/sidenav-right/sidenav-right.service'

import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandler } from '@app/global-error-handler';
// import { NgxMasonryModule } from 'ngx-masonry';


import { LoginV2Module } from '@app/pages/loginv2/login.module';
import { PiPmobileSignupModule } from '@app/pages/pipmobile-signup/pipmobile-signup.module';
import { SignupModule } from '@app/pages/signup/signup.module';
import { HomeModule } from '@app/pages/home/home.module';
import { SetpasswordModule } from '@app/pages/setpassword/setpassword.module';
import { SetpasswordV2Module } from '@app/pages/setpasswordv2/setpassword.module';


@NgModule({
    declarations: [
        AppComponent,
        ConfirmComponent,
        AlertComponent,
        RedirectComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        BrowserModule,
        BrowserAnimationsModule,
        TemplateModule,
        AppRoutingModule,
        HttpClientModule,
        LoadingInterceptor,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        CustomPipesModule,
        VerifyModule,
        CreateOrganisationModalModule,
        CreateDdiModalModule,
        ReactiveFormsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        PageNotFoundModule,
        ProfileModule,
        PageNotFoundGlobalModule,
        SideNavRightModule,
        NgxStripeModule.forRoot(environment.stripeKey),
        SharedDataServiceModule.forRoot(),
        RootModalMessageModule,
        FormBasicControlsModule,
        SignupModule,
        LoginV2Module,
        HomeModule,
        SetpasswordModule,
        SetpasswordV2Module,
        ExpoSignupModule,
        ExpoDashboardModule,
        PiPmobileSignupModule,
        CardContainerTemplateModule,
        DemoModule,
        EsimUsageModule
    ],
    entryComponents: [ConfirmComponent, AlertComponent, UserService],
    providers: [
        AdminGuard,
        TitleCasePipe,
        UserService,
        AuthGuardService,
        ModalService,
        TestService,
        NormalizeNumberService,
        OrganisationService,
        NumberService,
        AppInsightService,
        AppTitleService,
        CookieService,
        VersionCheckService,
        PortalApiV2HelperService,
        SidenavRightService,
        Title,
        BlacklistService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptchaSiteKey,
            } as RecaptchaSettings,
        }],
    bootstrap: [AppComponent]
})


export class AppModule {

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {


        this.matIconRegistry.addSvgIcon(
            'pip_white_icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/pip_white_icon.svg')
        );


        //Add custom icon //https://materialdesignicons.com/
        this.matIconRegistry.addSvgIcon(
            'currency-gbp',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/currency-gbp.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'settings-outline',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/outline-settings-24px.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'home-outline',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/outline-home-24px.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'lock-outline',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/outline-lock-24px.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'dnd_onnoff',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/dnd_onnoff.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'clioveride',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/clioveride.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'custom_no_intl',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/no-intl.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'custom_no_sms',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/no-sms.svg')
        );

        //older ones above this line

        // new SVG ICONS
        //




        this.matIconRegistry.addSvgIcon(
            'dashboard',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/dashboard.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'team',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/team.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'build',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/build.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'teams',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/teams.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'billing',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/billing.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'key',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/key.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'usage',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/usage.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'usage-search',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/usage-search.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'numbers',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/numbers.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'connect',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/connect.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'pip-xt',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/connect.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'user',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/user.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'report',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/nav/report.svg')
        );

        //ui
        this.matIconRegistry.addSvgIcon(
            'clear_filled',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/clear_filled.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'propagate',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/propagate.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'three-dots-vertical',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/three-dots-vertical.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'three-dots',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/three-dots.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'more_vert',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/more_vert.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'more_horiz',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/more_horiz.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'send-mail',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/send-mail.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'paper-plane-sent',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/paper-plane-sent.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'filter_list',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/filter_list.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'filter_alt',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/filter_alt.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'view_column',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/view_column.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'email',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/email.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'email_v2',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/email_v2.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'email_alt',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/ui/email_alt.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'copy',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/copy.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'home',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/home.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'login',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/login.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'view',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/view.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'view_off',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/view_off.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'search',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/search.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'logout',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/logout.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'logout-2',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/logout-2.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'lock',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/lock.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'trash',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/trash.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'restore',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/trash-restore.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'delete',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/delete.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'refresh',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/refresh.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'download',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/download.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'upload',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/upload.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'loading',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/loading.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'compose',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/compose.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'settings_alt',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/settings.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'warning',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/warning.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'more-horiz',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/more-horiz.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'more-vert',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/more-vert.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'more-horiz-circle',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/more-horiz-circle.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'no-access',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/no-access.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'clear',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/clear.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'sync',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/ui/sync_alt.svg')
        );


        //calling
        this.matIconRegistry.addSvgIcon(
            'phone_add',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/calling/phone_add.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'phone_mobile_plus',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/calling/phone_mobile_plus.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'outbound_',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/calling/outbound.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'outbound',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/calling/outbound_2.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'inbound_',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/calling/inbound.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'inbound',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/calling/inbound_2.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'phone',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/calling/phone.svg')
        );

        //misc

        this.matIconRegistry.addSvgIcon(
            'qr_code',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/qr_code.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'wifi',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/wifi.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'flight_takeoff',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/flight_takeoff.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'travel',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/travel.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'change',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/change.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'launch',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/launch.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'note',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/note.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'arrow_upward',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/arrow_upward.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'impersonate',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/impersonate.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'manage_accounts',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/manage_accounts.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'dynamic_form',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/dynamic_form.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'date',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/date.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'invoice_check',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/invoice_check.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'invoice_warn',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/invoice_warn.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'invoice',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/invoice.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'link',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/link.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'android',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/android_logo.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'apple',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/apple_logo.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unlink',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/unlink.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'open_link',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/open_link.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'article',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/article.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'block',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/block.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'credit_card',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/credit_card.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'warning',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/warning.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'admin_settings',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/admin_settings.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'voice_network',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/voice_network.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'ring_group',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/ring_group.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'styles',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/styles.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'cloud',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/cloud.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'edit_line',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/edit_line.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'badge',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/badge.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'person_remove',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/person_remove.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'disconnect',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/disconnect.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'device',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/device.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'pip-mobile',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/pip-mobile.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'circle',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/circle.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'circle-filled',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/circle-filled.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'add',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/add.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'circle-checked',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/circle-checked.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'edit',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/edit.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'edit_note',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/edit_note.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'undo',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/undo.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'down_arrow',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/down_arrow.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'up_arrow',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/up_arrow.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'upgrade',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/upgrade.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'info',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/info.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'priority_high',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/priority_high.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'help',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/help.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'get_app',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/get_app.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'remove',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/remove.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'remove_circle_outline',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/remove_circle_outline.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'swap',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/swap.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'calender',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/misc/calender.svg')
        );

        //app
        this.matIconRegistry.addSvgIcon(
            'message',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/app/message.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'menu',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/app/menu.svg')
        );


        //general

        this.matIconRegistry.addSvgIcon(
            'left_arrow',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/left_arrow.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'right_arrow',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/right_arrow.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'shopping_cart',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/shopping_cart.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'settings',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/setting.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'check_circle_empty',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/check_circle_empty.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'check_circle_fill',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/check_circle_fill.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'save',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/save.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'save_as',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/save_as.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'business',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/business.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'checkout',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/checkout.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'image',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/image.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'open_in_new',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/open_in_new.svg')
        );

        // this.matIconRegistry.addSvgIcon(
        //     'copy',
        //     this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/copy.svg')
        // );

        this.matIconRegistry.addSvgIcon(
            'check_circle',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/check_circle.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'check',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/check.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'global',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/general/global.svg')
        );

        //animated
        this.matIconRegistry.addSvgIcon(
            'loading',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/animated/loadingbar.svg')
        );

        //custom
        this.matIconRegistry.addSvgIcon(
            'no-international',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/custom/no-international.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'no-sms',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/svg/custom/no-sms.svg')
        );


    }
}
