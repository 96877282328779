declare let require: any
const appVersion = require('../../package.json').version;


export const environment = {
    production: false,
    localhost: 'http://localhost:4200',
    ApiBaseUrl: 'https://voxspan-dv02-portal-api-01-i1.azurewebsites.net/api',
    apicode: '8DetrepsI8ZDW1rYC5oHJ30h4j1eLJfie67f1inheYC_AzFukuNFdg==',
    auth0_domain: 'pipcall-dv01.eu.auth0.com',
    auth0_clientID: '18ZUVlse1QhY3TbSzTH2LCkhjaGbHbNU',
    auth0_audience: 'https://portal.pipcall.com/api',
    appversion: appVersion,
    recaptchaSiteKey: '6Ldk8pwUAAAAACdwSU6XEivoRSRcbuY8oemTGyPc',
    cryptokey: '4873f52057sncye3uj',
    app_insights_instrumentationkey: '69bec0c2-c3cc-45ce-9ec2-40d64038f8cb',
    stripeKey: 'pk_test_6NemJK26s4JjuDlB0DtrqHOT',
    versionCheck: false,
    showConsoleLogs: true,
    faviconUrl: 'assets/favicon-dev.ico',
    trialExtensionsStore: 'EA4B8E7F-4A3C-EE11-A3F1-6045BD111F9B',
    copyrightMessage: '© 2024 PiPcall Limited, all rights reserved. Registered in England No. 4994562.',
    metaVersion: '1.1',
    esim_qr_code_storage_base_url: 'https://voxspandv02storage.blob.core.windows.net/telna-esim-qr-codes/'
};

// app_insights_instrumentationkey:'96fa31f7-08b3-4ce9-a86f-da1f5cbea6ee', //what is this one?

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses 'environment.ts', but if you do
// 'ng build --env=prod' then 'environment.prod.ts' will be used instead.
// The list of which env maps to which file can be found in 'angular.json'.
// ApiBaseUrl: 'https://voxspan-dv02-portal-admin-api.azurewebsites.net/api',
// ApiV2BaseUrl: 'https://voxspan-dv02-portal-api-01-i1.azurewebsites.net/api',
