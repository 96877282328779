import { Component, OnInit, Input, HostListener, EventEmitter, SimpleChanges, Output, isDevMode, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth-service/auth.service';
import { FormControl } from '@angular/forms';
import { Observable, Subscription, from } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { OrganisationModel , OrgMetaData} from '../../models/organisation.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { UserProfile } from '@app/models/user-profile.model';
import { DownloadLinksModalComponent } from '@app/components/modals/download-links-modal/download-links-modal.component';
import { MAT_DIALOG_DATA, MatDialog } from '@app/material/material-essentials.module';
import { PricingModalComponent} from '@app/components/modals/pricing-modal/pricing-modal.component';
import { SidenavRightService } from '@app/template/sidenav-right/sidenav-right.service';
import { HelpCenterRightMenuComponent } from '@app/components/right-menu-overlay/help-center-right-menu/help-center-right-menu.component';
import { escape, unescape} from 'safe-string-literal';

//services
import { UserSessionService, UserIdentification} from '@app/services/user-session.service';
import { OrgSessionService } from '@app/services/organisation-session.service';


export interface IBreadCrumb {
    label: string;
    url: string;
}

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.9s ease-out', style({ opacity: '1' })),
            ]),
        ])
    ]
})
export class ToolbarComponent implements OnInit {


    @Input() isloading: boolean;
    @Input() viewAs: string;
    @Output() navToggle = new EventEmitter<boolean>();

    // @Output() navToggle = new EventEmitter<boolean>();
    // @Output() navOpen = new EventEmitter<boolean>();
    // @Output() navClose = new EventEmitter<boolean>();

    // myProfile: any = '';
    public userProfile: UserProfile;
    public userIdentification: UserIdentification

    public model: any;
    public isDev: boolean;
    public loading: boolean;
    public breadcrumbs: IBreadCrumb[]
    public impersonateMode: boolean;
    public organisationProfile: OrganisationModel;
    public isSysAdmin: boolean;
    public isUserloggedIn: boolean;
    public searchInput: string;
    private _authSubscription: Subscription

    constructor(
        public dialog: MatDialog,
        private router: Router,
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private cookieService: CookieService,
        private sidenavRightService: SidenavRightService,
        private userSessionService: UserSessionService,
        private injector: Injector,
        private orgSessionService: OrgSessionService

    ) {
        console.log('toolbar constructor');
        this.isDev = isDevMode();
        this.organisationProfile = new OrganisationModel();
        this.isSysAdmin = this.authService.is_system_admin();
        this.userProfile = new UserProfile();

    }



    ngOnInit() {
        //this is to fix some issues with loading this service in the toolbar. Ensures lazy loading of the service
        // const orgSessionService = this.injector.get(OrgSessionService);

        console.log('ToolbarComponent initialized');
        this.isUserloggedIn = false;

        this.userSessionService.userProfileAsObservable
            .subscribe((userProfile) => {
                console.log('toolbar user profile', userProfile);
                this.userProfile = userProfile;
                if (this.authService.isAuthenticated()) {
                    this.isUserloggedIn = true;
                }
            });


        this.orgSessionService.organisationProfileAsObservable
            .subscribe((organisationProfile) => {
                this.organisationProfile = organisationProfile;
            });

        this._authSubscription = this.authService.userIdentificationAsObservable
            .subscribe((userIdentification) => {
                console.log('toolbar user identification', userIdentification);
                this.userIdentification = userIdentification;
            });


        if (sessionStorage.getItem('impersonateUser')) {
            setTimeout(() => {
                this.impersonateMode = true;
                console.log('SET IMPERSONATE MODE TO TRUE');
            }, 2000);
        }
    }

    ngAfterViewInit() {
        console.log('ToolbarComponent view initialized');
    }

    ngOnChanges(changes: SimpleChanges) {
        changes['isloading'] ? this.loading = changes['isloading'].currentValue : null;
    }

    openHelpCenter() {

        const url = this.router.url;
        console.log("////URL in toolbar", url);
        const data = { viewAs: this.viewAs, url: url };

        this.sidenavRightService.setContextData(data);
        this.sidenavRightService.setComponetPortal(HelpCenterRightMenuComponent);

        // this.sidenavRightService.dataChangedAsObservable.subscribe((resp) => {
        //     resp ? do something : null;
        // });
        //}
    }

    search(searchTerm: string) {
        this.clearSearch();
        this.router.navigateByUrl('/admin/find?search=' + searchTerm);
    }

    clearSearch() {
        this.searchInput = '';
    }

    openPage(url: string) {
    //when link clicked
        this.router.navigateByUrl(url);
    }

    toggleNavBar() {
        this.navToggle.emit(true);
    }

    signOut() {
        this.authService.logout();
    }

    exitImpersonate() {
        window.close();
    }

    navigateToURL(url: string, openInNew?: boolean) {

        const openIn = openInNew ? "_blank" : "_self";
        window.open(url, openIn);
    }

    openDownloadLink() {
        const dialogRef = this.dialog.open(DownloadLinksModalComponent, {
            panelClass: 'pipcall-modal-container',
            width: '420px',
        });

    }
    openPricingModal() {
        const dialogRef = this.dialog.open(PricingModalComponent, {
            panelClass: 'pipcall-modal-container',
            width: '660px',
        });
    }
    goHome() {
        if (this.viewAs === 'sysAdmin') {
            this.router.navigateByUrl('/admin');
        } else {
            this.router.navigateByUrl('/organisation');
        }
    }

    impersonateOrgAdmin() {
        const user_id =  this.organisationProfile?.owner_user_id;
        this.userSessionService.impersonateUser(user_id);
    }

    impersonateMyself() {
        this.userSessionService.impersonateUser(this.userProfile?.id);
    }

    //ondestory
    ngOnDestroy() {
        if (this._authSubscription) {
            this._authSubscription.unsubscribe();
        }
    }
}


