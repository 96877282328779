import { Component, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, isDevMode, Inject, OnDestroy, Injector, ApplicationRef, ComponentFactoryResolver } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { DOCUMENT } from '@angular/common';

import { environment } from '../environments/environment';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
//loader interceptor
import { HttpClient } from '@angular/common/http';
import { LoaderService } from './services/httpinterceptor';
import { AuthService } from './services/auth-service/auth.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppInsightService } from './services/helpers/app-insights.service';
import { Subscription } from 'rxjs';

//rightsideNav
import { SidenavRightService } from "@app/template/sidenav-right/sidenav-right.service";
import { Subject } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import { MatDialog, MatDialogRef } from '@app/material/material-essentials.module';
import { GoToPaymentPromptModal } from './root-modal-message/root-modal-message.component';
import { SidenavComponent } from './template/sidenav/sidenav.component';
import { VersionCheckService } from '@app/services/version-check.service';
import {
    BreakpointObserver,
    Breakpoints,
    BreakpointState,
} from '@angular/cdk/layout';

import { UserSessionService } from './services/user-session.service';
import { OrgSessionService } from './services/organisation-session.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
        trigger('slideContentMargin', [
            state('expanded', style({
                marginLeft: '240px'
            })),
            state('minimized', style({
                marginLeft: '50px'
            })),
            transition('* => *', animate('200ms ease-in-out'))
        ])
    ]
})
export class AppComponent implements AfterViewInit {


    favIcon: HTMLLinkElement = document.querySelector('#appIcon');

    _destroy$ = new Subject<void>();
    @ViewChild(SidenavComponent) public sidenavComponent: SidenavComponent;
    @ViewChild('appDrawer') appDrawer: any;

    r_opened = false;

    public isLoading = true;
    public routeData;
    public isCustomLayout = true;
    public isAnimatedBackdrop1 = true;
    public layoutCheckReturned = false;
    public eventsObs;
    public sidebarObs;
    public activeRoute = '';
    public data: any;
    public viewAs: string;
    public isSysAdmin: boolean;
    public displayCustomLogo = false;
    public customLogoUrl: string
    public _subsciption: Subscription;
    public disableCloseRightnav = false;

    public _subsciption2: Subscription; //unsubscribe on destroy
    public _subsciption3: Subscription;

    public isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset)
        .pipe(map((result: BreakpointState) => result.matches));


    constructor(
        private appInsightService: AppInsightService,
        private changeDetectorRef: ChangeDetectorRef,
        public loaderService: LoaderService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private authService: AuthService,
        private sidenavRightService: SidenavRightService,
        private versionCheckService: VersionCheckService,
        private breakpointObserver: BreakpointObserver,
        private userSessionService: UserSessionService,
        private orgSessionService: OrgSessionService
    ) {

        //clear all console logs if not in dev mode.
        // if (!isDevMode()) {
        //   console.log = (...args) => { }
        // }


        this.isSysAdmin = this.authService.is_system_admin();
        // this.viewAs = this.viewAs ? this.viewAs : this.isSysAdmin ? 'sysAdmin' : 'user';
        // this.buildLayout();

        this.setFavicon();
    }

    ngOnInit() {
    // environment.versionCheckURL
        this.buildLayout();
        // version check ?
        if (environment.production && environment.versionCheck === true) {
            console.log("--initiate version check--")
            this.versionCheckService.initVersionCheck('/assets/version.json');
        }

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const currentRoute = this.getCurrentRoute();
                this.viewAs =  currentRoute?.data['role'] || 'user';
                this.changeDetectorRef.detectChanges();
            }
        });

        // this.authService.handleAuthentication();
        this.appInsightsSetContenxt();
        this.setSidebarContents();

    }

    private getCurrentRoute() {
        let route = this.route.firstChild;
        while (route?.firstChild) {
            route = route.firstChild;
        }
        return route?.snapshot;
    }


    setFavicon() {
        this.favIcon.href =  environment?.faviconUrl;

        // if (environment.production) {return; } else {
        //     this.favIcon.href =  environment.faviconUrl;
        // }
    }

    appInsightsSetContenxt() {
        console.log("--set appinsights context--")
        const userId = this.authService.getUserId();
        const replacetxt = this.isSysAdmin ? 'admin_' : '';
        userId ? this.appInsightService.logUserContext(userId.replace('auth0|', replacetxt)) : null;
        this.setAppInsights();
    // this.appInsightService.logEvent('Message', { 'message': 'Application Loaded' });
    }

    private setAppInsights() {
        try {
            const node = document.createElement('script');
            node.innerHTML = 'var appInsights=window.appInsights||function(config){function i(config){t[config]=function(){var i=arguments;t.queue.push(function(){t[config].apply(t,i)})}}var t={config:config},u=document,e=window,o="script",s="AuthenticatedUserContext",h="start",c="stop",l="Track",a=l+"Event",v=l+"Page",y=u.createElement(o),r,f;y.src=config.url||"https://az416426.vo.msecnd.net/scripts/a/ai.0.js";u.getElementsByTagName(o)[0].parentNode.appendChild(y);try{t.cookie=u.cookie}catch(p){}for(t.queue=[],t.version="1.0",r=["Event","Exception","Metric","PageView","Trace","Dependency"];r.length;)i("track"+r.pop());return i("set"+s),i("clear"+s),i(h+a),i(c+a),i(h+v),i(c+v),i("flush"),config.disableExceptionTracking||(r="onerror",i("_"+r),f=e[r],e[r]=function(config,i,u,e,o){var s=f&&f(config,i,u,e,o);return s!==!0&&t["_"+r](config,i,u,e,o),s}),t}({ instrumentationKey: "' + environment.app_insights_instrumentationkey + '"  }); window.appInsights = appInsights;appInsights.trackPageView();';
            node.type = 'text/javascript';
            document.getElementsByTagName('head')[0].appendChild(node);
        } catch {
        }
    }

    setSidebarContents() {
        console.log("--set sidebar contents--")
        this._subsciption2 = this.sidenavRightService.getOpenState().subscribe((openedState: boolean) => { this.r_opened = openedState; });
        this._subsciption3 = this.sidenavRightService.getDisableState().subscribe((disableState: boolean) => { this.disableCloseRightnav = disableState; });
    }

    ngAfterViewInit() {
        this.loaderService.isLoading.subscribe((resp) => {
            this.isLoading = resp;
            this.changeDetectorRef.detectChanges(); //runs change detection manually, triggers change value
        });

    }


    buildLayout() {
    //read route data to build side bar. custom layout handled differently
        this.sidebarObs = this.router.events.subscribe((data) => {
            if (data instanceof RoutesRecognized) {
                // console.log("[app component] buildLayout - recognized route", data.state.root);
                this.routeData = data.state.root?.firstChild?.data;
                this.isCustomLayout = this.routeData['customLayout'];
                this.isAnimatedBackdrop1 = this.routeData['isAnimatedBackdrop1'];
                // console.log("[app component].isCustomLayout?", this.isCustomLayout);
                this.layoutCheckReturned = true;
            }
        });
    }

    closeSideNav() {
        if (this.appDrawer._mode === 'over') {
            this.appDrawer.close();
        }
    }

    componentAdded($event) {
        console.log("Loaded component:", $event);
    }



    routerActivate(event) {
        console.log("Activate:", event);
    }

    routerDeactivate(event) {
        console.log("DEACTIVATE:", event);
    }

    ngOnDestroy() {
        this.sidebarObs ? this.sidebarObs.unsubscribe() : null;
        this._subsciption ? this._subsciption.unsubscribe() : null;
        this._subsciption2 ? this._subsciption2.unsubscribe() : '';
        this._subsciption3 ? this._subsciption3.unsubscribe() : '';
        //RHnav
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }


    openGoToPaymentModal() {
        const dialogRef = this.dialog.open(GoToPaymentPromptModal, {
            hasBackdrop: false,
            panelClass: 'purple-background',
            position: {
                bottom: '20px',
                right: '20px'
            },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.sidenavComponent.openFirstPayment();
                //do something
            }
        });

    }

}
