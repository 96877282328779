import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { responseModel } from '@app/models/response.model';
import { HttpHeaders } from '@angular/common/http';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { Subject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
export class Response {
    bool: boolean;
}


//legacy and pipmobile
export class SignupForm {
    first_name: string;
    last_name: string;
    email: string;
    recaptcha: string;
    contact_phone_number: string;
    pip_variant?: string;
}

export class SignupRequest {
    email: string;
    recaptcha: string;
    product: string[];
    source?: string;
    location?: string;
}
export class SignupResponse {
    organisation_signup_request_id: string;
    organisation_id: string;
    is_valid_code: boolean;
    is_code_expired: boolean;
    access_token: string;
    refresh_token: string;
    id_token: string;
    expires_in: number;
    scope: string;
}

@Injectable()
export class SignupService {

    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`;

    constructor(
        private httpProxy: HttpProxy,
        private normalizeNumberService: NormalizeNumberService
    ) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }

    //NEW SIGNUIP
    public postSignupRequest(signupForm: SignupRequest): Observable<HttpResponse<any>> {
        const body = {
            email: signupForm.email,
            recaptcha: signupForm.recaptcha,
            product: signupForm.product,
            source: signupForm.source,
            location: signupForm.location
        }
        console.log('BODY SENT TO API', body);

        return this.httpProxy.post(this.ApiBaseUrl + `/signup_requests` + this.code, body, 'response', false);
    }

    //NEW SIGNUP
    public postSignupVerifyCode(id: string, code: string): Observable<HttpResponse<SignupResponse>> {
        //obsfucate the password here
        const body = {
            code: code,
        }

        return this.httpProxy.post(this.ApiBaseUrl + `/signup_requests/${id}/verify` + this.code, body, 'response', false);
    }

    public getSignupRequest(id: string): Observable<HttpResponse<any>> {
        return this.httpProxy.get(this.ApiBaseUrl + `/signup_requests/${id}` + this.code, 'response', false);
    }


    public postNewSignup(signupForm: SignupForm): Observable<responseModel> {
        const body = {
            first_name: signupForm.first_name,
            last_name: signupForm.last_name,
            email: signupForm.email,
            recaptcha: signupForm.recaptcha,
            contact_phone_number: signupForm.contact_phone_number,
            pip_variant: signupForm.pip_variant,
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/organisation_signups` + this.code, body, 'response', true);
    }

    //pipmobile
    public postSignupVerificationCode(id: string, code: string, password: string, organisation_name?: string): Observable<responseModel> {
        //obsfucate the password here
        const body = {
            code: code,
            password: password,
            organisation_name: organisation_name
        }

        return this.httpProxy.post(this.ApiBaseUrl + `/organisation_signups/${id}/validcode` + this.code, body, 'response', true);
    }

    //pipxt
    public postSignupVerificationCodeWithExtensions(id: string, code: string, password: string): Observable<responseModel> {
        //obsfucate the password here
        const body = {
            code: code,
            password: password
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/organisation_signups/${id}/withextensions/validcode` + this.code, body, 'response', true);
    }

    // checkEmailNotTaken(email: string): Observable<responseModel> {
    //     const body = {
    //         'email': email
    //     }
    //     return this.httpProxy.post(this.ApiBaseUrl + '/users/email/existing' + this.code, body, 'response', false);
    // }

    checkIfEmailTaken(email: string): Observable<responseModel> {
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/users/email/existing/${email}` + this.code, 'response', true);
    }

    checkUserWithSMSIdentityExists(email: string): Observable<responseModel> {
        return this.httpProxy.get(this.ApiBaseUrl + `/v2/users/smsidentity/existing/${email}` + this.code, 'response', true);
    }

    checkMobileNotTaken(mobile: string, region: string): Observable<Response> {
        const normalizedmobile = this.normalizeNumberService.normaliseNumberWith00(mobile, region);
        const body = {
            'mobile': normalizedmobile
        }
        console.log("normalized mobile", normalizedmobile);
        return this.httpProxy.post(this.ApiBaseUrl + '/users/mobile/existing' + this.code, body, 'body', false);
    }

    checkMobileNotTakenv2(mobile: string): Observable<Response> {
        //accept as 0044 format
        //if starts with +, replace + with 00
        if (mobile.startsWith('+')) {
            mobile = mobile.replace('+', '00');
        }
        const body = {
            'mobile': mobile
        }
        return this.httpProxy.post(this.ApiBaseUrl + '/users/mobile/existing' + this.code, body, 'body', false);
    }

    checkBusinessNumberNotMobile(businessnumber: string, mobile: string, region: string): Observable<boolean> {
        //return true for invalidvalid

        const normalizedmobile = this.normalizeNumberService.normaliseNumberWith00(mobile, region);
        const normalizedbusinessnumber = this.normalizeNumberService.normaliseNumberWith00(businessnumber, region);
        const result = new Subject<boolean>();
        if (normalizedmobile === normalizedbusinessnumber) {
            return Observable.of(true);
        } else {
            return Observable.of(false);
        }
    }
}
