import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule } from '@app/pipes/pipes.module';

//bottomsheets
import { UserProfileBottomSheetModule} from '@app/components/bottom-sheet-modal/userprofile-bsheet-modal/userprofile-bsheet-modal.module';
import { OrganisationProfileBottomSheetModule} from '@app/components/bottom-sheet-modal/organisationprofile-bsheet-modal/organisationprofile-bsheet-modal.module';
import { DDIBottomSheetModule } from '@app/components/bottom-sheet-modal/ddi-bsheet-modal/ddi-bsheet-modal.module';
import { LicenceBottomSheetModule } from '@app/components/bottom-sheet-modal/licence-bsheet-modal/licence-bsheet-modal.module';
import { InvoiceBottomSheetModule } from '@app/components/bottom-sheet-modal/invoice-bsheet-modal/invoice-bsheet-modal.module';


//cto components
import { OrgClickComponent } from './cto-organisation/cto-organisation.component';
import { UserClickComponent } from './cto-user/cto-user.component';
import { NumberClickComponent } from './cto-number/cto-number.component';
import { ExtensionClickComponent} from './cto-extension/cto-extension.component';
import { InvitationClickComponent } from './cto-invitation/cto-invitation.component';
import { LicenceClickComponent } from './cto-licence/cto-licence.component';
import { InvoiceClickComponent } from './cto-invoice/cto-invoice.component';

//import shared module
import { SharedModule } from '@app/services/shared-services.module';

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GravatarDirectiveModule,
        CustomPipesModule,
        UserProfileBottomSheetModule,
        OrganisationProfileBottomSheetModule,
        DDIBottomSheetModule,
        LicenceBottomSheetModule,
        InvoiceBottomSheetModule,
        SharedModule
    ],
    declarations: [OrgClickComponent, UserClickComponent, NumberClickComponent, ExtensionClickComponent, InvitationClickComponent, LicenceClickComponent, InvoiceClickComponent],
    exports: [OrgClickComponent, UserClickComponent, NumberClickComponent, ExtensionClickComponent, InvitationClickComponent, LicenceClickComponent, InvoiceClickComponent],
    providers: [HttpProxy],
    entryComponents: []
})
export class ClickToOpentModule { }
