import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenseDataService} from './license-data.service';
import { CookieService } from 'ngx-cookie-service';
import { StaticDataService} from './staticdata.sevice'
import { RouterModule} from '@angular/router';

@NgModule({})
export class SharedDataServiceModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedDataServiceModule,
            providers: [LicenseDataService, CookieService, StaticDataService, RouterModule]
        };
    }
}
